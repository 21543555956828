import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

const computeTotalHours = (timeRegistrations) => {
  if (timeRegistrations.length === 0) return 0;

  let totalHours = 0;

  timeRegistrations.forEach((d) => {
    // 1. add hours
    if (moment(d.endTime, "HH:mm").isBefore(moment(d.startTime, "HH:mm"))) {
      //if overnight
      const now = moment(d.startTime, "HH:mm");
      const then = moment(d.endTime, "HH:mm").add("days", 1);

      const ms = then.diff(now);
      const duration = moment.duration(ms);

      totalHours += Math.floor(duration.asHours()) + (parseInt(moment.utc(ms).format("mm")) / 60) * 1;
    } else {
      totalHours += moment(d.endTime, "HH:mm").diff(moment(d.startTime, "HH:mm"), "hours");

      //2. add minute differencials per registration
      totalHours +=
        (parseInt(moment.utc(moment(d.endTime, "HH:mm").diff(moment(d.startTime, "HH:mm"))).format("mm")) / 60) * 1;
    }
  });

  return totalHours;
};

export default computeTotalHours;
