import React from 'react';
import {
	WrenchIcon,
	EthernetCableIcon,
	BookOpenVariantIcon,
	ClipboardCheckOutlineIcon,
	ClipboardTextOutlineIcon,
	PowerPlugIcon,
	TableSearchIcon,
	CalendarClockIcon,
	CalendarRemoveIcon,
	CalendarQuestionIcon,
	MenuSwapOutlineIcon,
	TableIcon,
	AccountGroupIcon,
	DatabaseTickIcon,
	ClipboardCheckIcon,
	PencilBoxOutlineIcon
} from 'mdi-react';

export default function (mdiString, styles = null) {
	mdiString = mdiString.trim().toLowerCase();

	switch (mdiString) {
		case 'account-group':
			return <AccountGroupIcon style={styles} />;
		case 'database-import':
			return <DatabaseTickIcon style={styles} />;
		case 'table-account':
			return <TableIcon style={styles} />;
		case 'calendar-clock':
			return <CalendarClockIcon style={styles} />;
		case 'calendar-remove':
			return <CalendarRemoveIcon style={styles} />;
		case 'calendar-question':
			return <CalendarQuestionIcon style={styles} />;
		case 'wrench-outline':
			return <WrenchIcon style={styles} />;
		case 'ethernet-cable':
			return <EthernetCableIcon style={styles} />;
		case 'power-plug':
			return <PowerPlugIcon style={styles} />;
		case 'book-open-variant':
			return <BookOpenVariantIcon style={styles} />;
		case 'clipboard-check-outline':
			return <ClipboardCheckOutlineIcon style={styles} />;
		case 'clipboard-text-outline':
			return <ClipboardTextOutlineIcon style={styles} />;
		case 'table-search':
			return <TableSearchIcon style={styles} />;
		case 'menu-swap-outline':
			return <MenuSwapOutlineIcon style={styles} />;
		case 'clipboard-check':
			return <ClipboardCheckIcon style={styles} />;
		case 'pen-outline':
			return <PencilBoxOutlineIcon style={styles} />;
		default:
			return null;
	}
}
