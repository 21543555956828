import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Modal, Row, Statistic } from 'antd';
import { useSelector } from 'react-redux';

const WorkTimeAlertDk = ({
	schedInfo = [],
	time = [],
	absences = [],
	name = 'You',
	foreman = false,
	loading = true
}) => {
	const [showModal, setShowModal] = useState(false);
	const close = () => {
		setShowModal(false);
	};

	const lang = useSelector(s => s.language.language);

	const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	function getCurrentDaysAndPrev(current) {
		var week = new Array();
		current.setDate(current.getDate() - current.getDay() + 1);
		for (var i = 0; i < new Date().getDay(); i++) {
			week.push(days[new Date(current).getDay()]);
			current.setDate(current.getDate() + 1);
		}
		return week;
	}

	const checkIfDone = () => {
		const regHours = time
			.filter(tm => {
				return tm.state === 'OPEN' || tm.state === 'LOCKED_BY_FOREMAN';
			})
			.map(reg => {
				return { [days[new Date(reg.data.date).getDay()]]: reg.totalHours };
			});

		const absencesHours = absences
			.filter(absence => {
				return absence.state === 'OPEN' || absence.state === 'LOCKED_BY_FOREMAN';
			})
			.map(abs => {
				if (abs.ifsData !== null) {
					return { [days[new Date(abs.timeKey.split(' ')[0]).getDay()]]: abs.ifsData[0].WageHours };
				} else {
					return { [days[new Date(abs.timeKey.split(' ')[0]).getDay()]]: 8 };
				}
			});

		const mergeHours = data => {
			const result = {};
			data.forEach(item => {
				for (let [key, value] of Object.entries(item)) {
					if (result[key]) {
						result[key] += value;
					} else {
						result[key] = value;
					}
				}
			});

			return result;
		};

		const totalHours = mergeHours(regHours.concat(absencesHours));
		const notReported = getCurrentDaysAndPrev(new Date()).filter(day => !Object.keys(totalHours).includes(day));
		const needToReport = getCurrentDaysAndPrev(new Date()).filter(day => Object.keys(totalHours).includes(day));

		const daysNeedToComplete = schedInfo
			.filter(sched => {
				return needToReport.includes(sched.Day);
			})
			.map(sched => {
				if (totalHours[sched.Day] < sched.WorkHoursSum) {
					return {
						Day: sched.Day,
						RemainingTime: sched.WorkHoursSum - totalHours[sched.Day],
						SheduleHours: sched.WorkHoursSum,
						ScheduleId: sched.ScheduleId
					};
				} else {
					return false;
				}
			})
			.filter(item => typeof item === 'object');

		const daysNotReported = schedInfo.filter(sched => {
			return notReported.includes(sched.Day);
		});

		return {
			DaysNotReported: daysNotReported,
			DaysNotComplete: daysNeedToComplete
		};
	};

	const notReportedLength = checkIfDone().DaysNotReported.length;
	const notCompleteLength = checkIfDone().DaysNotComplete.length;

	return (
		<>
			{!loading ? (
				<Row justify="center" style={{ margin: '1rem' }}>
					<Col>
						{notCompleteLength + notReportedLength !== 0 ? (
							<Alert
								action={
									<Button onClick={() => setShowModal(true)} size="small" type="text">
										Show all
									</Button>
								}
								description={
									<p>
										{notReportedLength !== 0 &&
											`${name} have missing ${
												notReportedLength > 1 ? 'records' : 'record'
											} for ${notReportedLength} ${notReportedLength > 1 ? 'days' : 'day'}`}
										{notReportedLength !== 0 && notCompleteLength !== 0 ? `, and ` : ''}
										{notCompleteLength !== 0
											? `${foreman === true ? '' : 'You'} need to complete ${notCompleteLength} ${
													notCompleteLength > 1 ? 'days' : 'day'
											  } of ${notCompleteLength > 1 ? 'records. ' : 'record. '}`
											: '. '}
										{foreman === true ? '' : 'Please make sure to submit on time.'}
									</p>
								}
								message="Need to report schedule hours this week."
								type="warning"
								showIcon
							/>
						) : (
							<Alert
								message={`${
									foreman === true
										? // ? `${name} is on time to report!`
										  lang.foremanRegistrationWorkTimeAlertEmployeeIsOnTime.replace(
												'{{placeholder}}',
												name
										  )
										: lang.workTimeAlertAllCaughtUpLabel
								}`}
								showIcon
								type="success"
							/>
						)}
					</Col>
				</Row>
			) : (
				<Row justify="center" style={{ margin: '1rem' }}>
					<Col>
						<p>{lang.workTimeAlertLoadingLabel}</p>
					</Col>
				</Row>
			)}

			<Modal onCancel={close} title="Please complete your report" footer={null} visible={showModal}>
				<Row gutter={[24, 24]}>
					{notCompleteLength !== 0 && (
						<>
							<Col lg={24} sm={24} style={{ textAlign: 'center' }}>
								<p style={{ margin: 0 }}>{`${name} need to complete ${
									foreman === true ? 'the' : 'your'
								} schedule for the following days below:`}</p>
							</Col>
							{checkIfDone().DaysNotComplete.map(info => {
								return (
									<>
										<Col key={info.Day} lg={12} sm={24}>
											<Statistic
												formatter={value => {
													return (
														<>
															<p style={{ fontSize: '8pt' }}>
																Schedule hours: <strong>{value.SheduleHours}</strong>
															</p>
															<p style={{ fontSize: '8pt' }}>
																Remaining Time: <strong>{value.RemainingTime}</strong>
															</p>
														</>
													);
												}}
												title={info.Day}
												value={info}
											/>
										</Col>
									</>
								);
							})}
						</>
					)}

					{notReportedLength !== 0 && (
						<>
							<Col lg={24} md={24} sm={24} style={{ textAlign: 'center' }}>
								<p style={{ margin: 0 }}>{`${name} need to complete ${
									foreman === true ? 'the' : 'your'
								} schedule for the following days below:`}</p>
							</Col>

							{checkIfDone().DaysNotReported.map(info => {
								return (
									<>
										<Col key={`report-${info.Day}`} span={8}>
											<Statistic
												formatter={value => {
													return (
														<>
															<p style={{ fontSize: '8pt' }}>
																Schedule hours: <strong>{value.WorkHoursSum}</strong>
															</p>
														</>
													);
												}}
												title={info.Day}
												value={info}
											/>
										</Col>
									</>
								);
							})}
						</>
					)}
				</Row>
			</Modal>
		</>
	);
};

export default WorkTimeAlertDk;
