import { format, parse } from "date-fns";

/* PARSE DATES */

export function parseDatetime(datetimeString) {
  /**
   * Expects a string-formatted timestamp and returns a human-friendly date
   * --------------
   * @parameter dateTimeString [string] a datetime string in the format of YYYYMMDDHHmm (201812302230)
   * @returns [string] formatted string 201812302230 -> 30/12-2018 22:30
   */

  return format(parse(datetimeString, "yyyyMMddHHmm", 0), "dd/MM-yyyy HH:mm");
}

export function parseDate(dateString) {
  /**
   * Expects a string-formatted timestamp and returns a human-friendly date
   * --------------
   * @parameter dateTimeString [string] a datetime string in the format of YYYYMMDD (20181230)
   * @returns [string] formatted string 20181230 -> 30/12-2018
   */

  return format(parse(dateString, "yyyyMMdd", 0), "dd/MM-yyyy");
}

export function parseTime(timeString) {
  /**
   * Expects a string-formatted timestamp and returns a human-friendly date
   * --------------
   * @parameter dateTimeString [string] a datetime string in the format of YYYYMMDDHHmm (0120)
   * @returns [string] formatted string 0120 -> 1:20
   */

  return format(parse(timeString, "HHmm", 0), "HH:mm");
}

/* GET DATES */

export function getDatetimeString() {
  /**
   * Returns now as a datetimeString (yyyyMMddHHmm)
   * --------------
   * No params
   * @returns [string] yyyyMMddHHmm example: 201801282359
   */

  return format(new Date(), "yyyyMMddHHmm");
}

export function getTimeString() {
  /**
   * Returns now as a timeString (yyyyMMddHHmm)
   * --------------
   * No params
   * @returns [string] yyyyMMddHHmm example: 201801282359
   */

  return format(new Date(), "HHmm");
}
