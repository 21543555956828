import React from "react";
import { durations } from "../config/animations";

import {
  SHOW_CONTEXT_MENU,
  HIDE_CONTEXT_MENU,
  SHOW_DIALOG,
  HIDE_DIALOG,
  CLEAR_DIALOG,
  SHOW_LIGHT_BOX,
  HIDE_LIGHT_BOX,
  SHOW_MODAL_PAGE,
  UPDATE_MODAL_PAGE_CONTENT,
  HIDE_MODAL_PAGE,
  CLEAR_CONTEXT_MENU_ACTIONS,
  CLEAR_MODAL_PAGE,
  TOGGLE_WORK_HOUR_MODAL,
} from "./actionTypes";

// Context menu
export function showContextMenu(payload) {
  return {
    type: SHOW_CONTEXT_MENU,
    payload,
  };
}
export function hideContextMenu() {
  return function (dispatch, getState) {
    dispatch({
      type: HIDE_CONTEXT_MENU,
    });

    setTimeout(
      () =>
        dispatch({
          type: CLEAR_CONTEXT_MENU_ACTIONS,
        }),
      durations.normal
    );
  };
}

/** Creates a new dialog
 * @param {Object} dialog
 * @param {String} dialog.title
 * @param {ReactElement|String|Function} dialog.content
 * @param {ReactElement} dialog.icon
 * @param {String} dialog.primaryActionTitle
 * @param {Function} dialog.primaryAction
 * @param {String} dialog.secondaryActionTitle
 * @param {Function} dialog.secondaryAction
 * @param {Boolean} dialog.disableSecondaryOption
 */
export function showDialog(dialog) {
  return {
    type: SHOW_DIALOG,
    payload: dialog,
  };
}
export function hideDialog() {
  return function (dispatch, getState) {
    dispatch({
      type: HIDE_DIALOG,
    });

    setTimeout(
      () =>
        dispatch({
          type: CLEAR_DIALOG,
        }),
      durations.normal
    );
  };
}

// Lightbox
export function showLightBox(payload) {
  return {
    type: SHOW_LIGHT_BOX,
    payload: payload,
  };
}

export function hideLightBox(payload) {
  return {
    type: HIDE_LIGHT_BOX,
  };
}

// Modal page
export function showModalPage(payload) {
  return {
    type: SHOW_MODAL_PAGE,
    payload: payload,
  };
}

/**
 * @param {Object} payload
 * @param {Boolean} payload.active - Displays a spinner instead of action right
 * @param {String} payload.title - The title that is displayed in the TopBar
 * @param {ReactComponent} payload.content - The content in the ModalPage (For scrollable contant, wrap it in a ScrollView)
 * @param {function} payload.closeCallback - Function which is invoked before closing the modalpage. If the function is specified
 * the modalPage will not close itself. You'll have to manually import the hideModalPage action and call it. This feature is
 * usefull for prompting users before exiting a form with unsaved changes.
 * actionRight: null
 * @param {ReactComponent} payload.actionRight - The component that handles the primary modalPage action
 * `<ActionWrapper onClick={{onClick}}><p>Click Me</p><ActionWrapper/>`
 *
 */
export function updateModalPage(payload) {
  return {
    type: UPDATE_MODAL_PAGE_CONTENT,
    payload: payload,
  };
}
export function hideModalPage() {
  return function (dispatch, getState) {
    dispatch({
      type: HIDE_MODAL_PAGE,
    });
    setTimeout(() => dispatch({ type: CLEAR_MODAL_PAGE }), durations.normal);
  };
}

export function toggleWorkHourModal(payload = true) {
  return {
    type: TOGGLE_WORK_HOUR_MODAL,
    payload: payload,
  };
}
