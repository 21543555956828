import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import { format, subDays } from "date-fns";
import { Alert, Row, Col, Button, Skeleton, Collapse, List, Statistic } from "antd";
import { CheckboxMarkedIcon, TimerSandEmptyIcon } from "mdi-react";
import params from "jquery-param";

import colors from "../../style/colors";

import req from "../../utilities/request-utility";
import Modal from "antd/lib/modal/Modal";

const CorrectionsAlert = ({ pages }) => {
  const [modal, toggleModal] = useState(false);
  const [corrections, setCorrections] = useState([]);
  const CorrectionsProps = pages.pages.find((d) => d.module === "SemcoTimeCorrections") || {};

  const selectedUser = useSelector((s) => s.users.selectedUser);

  useEffect(() => {
    (function async() {
      const toDate = format(new Date(), "yyyyMMdd");
      const fromDate = format(subDays(new Date(), 90), "yyyyMMdd");

      req()(`semcotime/corrections?${params({ externalId: selectedUser, fromDate, toDate })}`)
        .then(({ data }) => {
          let unreadCorrections = [];
          Object.keys(data).map((key) => {
            if (data[key].notifications !== 0) unreadCorrections = [...unreadCorrections, { date: key, ...data[key] }];
          });
          setCorrections(sortBy(unreadCorrections, "date"));
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    })();
  }, [JSON.stringify(corrections)]);

  return (
    <>
      <Row justify="center" style={{ margin: "1rem" }}>
        <Col>
          <Alert
            action={
              <Button onClick={() => toggleModal(!modal)} size="small" type="text">
                Show All
              </Button>
            }
            message={`You have ${CorrectionsProps.notifications} new corrections`}
            type="info"
            showIcon
          />
        </Col>
      </Row>
      <Modal visible={modal} onCancel={() => toggleModal(!modal)} title="Corrections" footer={null} width={"30vw"}>
        {corrections.length === 0 && (
          <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        )}

        {corrections.length !== 0 && (
          <>
            {corrections.map((d, index) => (
              <Collapse key={index}>
                <Collapse.Panel header={d.date} key={d.date}>
                  <List
                    itemLayout="horizontal"
                    dataSource={d.registrations}
                    renderItem={(registration) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            registration.confirmed === "Yes" ? (
                              <CheckboxMarkedIcon style={{ color: colors.green }} />
                            ) : (
                              <TimerSandEmptyIcon style={{ color: colors.grey }} />
                            )
                          }
                          title={registration.reportCodeDescription}
                          description={
                            <>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Statistic
                                    title="Activity"
                                    value={registration.activity}
                                    valueStyle={{ fontSize: "10pt" }}
                                  />
                                </Col>
                                <Col span={12}>
                                  <Statistic
                                    title="Report Code"
                                    value={registration.reportCode}
                                    valueStyle={{ fontSize: "10pt" }}
                                  />
                                </Col>
                                <Col span={24}>
                                  <Statistic title="Note" value={registration.note} valueStyle={{ fontSize: "10pt" }} />
                                </Col>
                              </Row>
                            </>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Collapse.Panel>
              </Collapse>
            ))}
          </>
        )}
      </Modal>
    </>
  );
};

export default CorrectionsAlert;
