/* Ok i couldn't figure out a super smart way to do this 
and since it will never be relevant for more than 24 hours 
i settled for this rather stupid solution */

/**
 * @param {Number} hourValue - A number representing an amount of hours. 0.5 would equal 0:30 or 2 would equal 2:00. 0.25 wo
 * @returns {String} a title describing the hour value
 *
 *
 * @example
 *
 * ```js
 * getQuarterTitleFromHourValue(0.25) -> "0:15"
 * getQuarterTitleFromHourValue(0.50) -> "0:30"
 * getQuarterTitleFromHourValue(0.75) -> "0:45"
 * getQuarterTitleFromHourValue(1.00) -> "1:00"
 * ```
 *
 *
 */
export default function (hourValue) {
  let hour = String(hourValue).split(".")[0];

  let minutes = String(hourValue).split(".")[1];

  if (!minutes) {
    minutes = "00";
    return `${hour}:${minutes}`;
  } else {
    minutes = Math.round(minutes * 0.6);
    minutes = String(minutes).length === 1 ? `${minutes}0` : minutes;
    return `${hour}:${minutes}`;
  }
}
