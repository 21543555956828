import React, { memo, useEffect, useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Col, notification, Radio, Row } from "antd";
import moment from "moment";
import { isEmpty } from "lodash";

import req from "../../utilities/request-utility";

// css
import { css } from "emotion";
import colors from "../../style/colors";
import { CloseIcon, InformationOutlineIcon } from "mdi-react";

// actions
import { hideModalPage } from "../../actions/uiActions";

// ui-components
import ScrollView from "../ui/ScrollView";
import Page from "../ui/Page";
import TextInput from "../ui/TextInput";
import DropDown from "../ui/DropDown";
import DatePicker from "../ui/DatePicker";
import Button from "../ui/Button";
import common from "../../style/common";
import getTotalHours from "../../utilities/get-total-hours";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";

import { useDropdownData } from "./hooks";

const WorkTimeRegistration = ({ addRegistration, registration, updateRegistration }) => {
  const dispatch = useDispatch();

  const lang = useSelector(({ language }) => language.language);

  const getLastUsedFormValues = () => {
    const date = format(new Date(), "yyyy-MM-dd");
    let lastUsedTime = localStorage.getItem("semcotime-last-time-input");

    lastUsedTime = lastUsedTime ? JSON.parse(lastUsedTime) : null;
    let formDataInitialNewValues = {};

    if (lastUsedTime) {
      formDataInitialNewValues = {
        date,
        project: lastUsedTime ? lastUsedTime.project : formData.project,
        subProject: lastUsedTime ? lastUsedTime.subProject : formData.subProject,
        projectActivity: lastUsedTime ? lastUsedTime.activity : formData.projectActivity,
        startTimeHours: lastUsedTime ? lastUsedTime.startTimeHours : formData.startTimeHours,
        startTimeMinutes: lastUsedTime ? lastUsedTime.startTimeMinutes : formData.startTimeMinutes,
        endTimeHours: lastUsedTime ? lastUsedTime.endTimeHours : formData.endTimeHours,
        endTimeMinutes: lastUsedTime ? lastUsedTime.startTimeMinutes : formData.startTimeMinutes,
        dabLocation: lastUsedTime ? lastUsedTime.dabLocation : formData.dabLocation,
        // dabTrade: lastUsedTime ? lastUsedTime.dabTrade : formData.dabTrade,
      };
    }

    console.log("formDataInitialNewValues: ", formDataInitialNewValues);
    return formDataInitialNewValues;
  };

  // Formdata
  const [formData, setFormData] = useState({
    id: undefined,
    project: undefined,
    subProject: undefined,
    projectActivity: undefined,
    activities: [],
    customerDetail: "",
    customerNote: "",
    foreman: undefined,
    date: undefined,
    startTimeHours: "00",
    startTimeMinutes: "00",
    endTimeHours: "00",
    endTimeMinutes: "00",
    hours: "",
    shift: "Day",
    type: "time",
    paidBreak: false,
    unpaidBreak: false,
    transport: false,
    endOfRotation: false,
    changeShift: false,
    heliTime: false,
    standby: false,

    ...(!isEmpty(registration) ? registration.data : getLastUsedFormValues()),
  });

  // Api data
  const [wageCodes, setWageCodes] = useState([]);
  const [foremen, setForemen] = useState([]);
  const [loadingWageCodes, setLoadingWageCodes] = useState(false);

  // Local data
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [isFormReady, setIsFormReady] = useState(true);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [errorLoadingFormData, setErrorLoadingFormData] = useState(false);
  const [totalHours, setTotalHours] = useState(0);
  const [tradeCodesList, setTradeCodesList] = useState([]);

  const {
    dabLocations,
    dabLocationsIsFetching,
    dabTradeCodes,
    dabTradeIsFetching,
    defaultEmployeeValues,
    defaultEmployeeValuesStatus,
    defaultProjectSubProjectAndActivityData,
    defaultProjectSubProjectAndActivityStatus,
    projectData,
    projectActivityData,
    projectStatus,
    projectActivityStatus,
    subProjectData,
    subProjectStatus,
  } = useDropdownData(formData);

  const getDefaultEmployeeTrade = async () => {
    const { data: defaultEmployeeValues } = await req()(`semcotime/projects/default-employee-values/`);
    setFormData({ ...formData, dabTrade: defaultEmployeeValues.employeeTrade });
  };

  const getForemen = () => {
    try {
      setIsFormReady(false);

      req()(`semcotime/foremen/`).then(({ data }) => {
        const assignedIds = data.assigned.map((d) => d.id);

        setForemen([
          ...data.assigned,
          { disabled: true, id: "", name: "------------------" },
          ...data.all.filter((d) => !assignedIds.includes(d.id)),
        ]);
      });

      setIsFormReady(true);
    } catch (err) {
      setErrorLoadingFormData(true);
    }
  };

  const getWageCodes = () => {
    return new Promise((resolve, reject) => {
      req()(`semcotime/wage-codes?wageType=increment`)
        .then((res) => res.data)
        .then((wageCodes) => {
          setWageCodes(wageCodes);
          setLoadingWageCodes(false);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const initializeFormForEdit = async () => {
    try {
      const {
        activities,
        customerDetail,
        customerNote,
        dabLocation,
        date,
        endTimeHours,
        endTimeMinutes,
        foreman,
        heliTime,
        id,
        project,
        projectActivity,
        startTimeHours,
        startTimeMinutes,
        transport,
        subProject,
        unpaidBreak,
        standby,
      } = registration.data;

      getForemen();
      getWageCodes();

      setFormData((formData) => ({
        ...formData,
        activities,
        customerDetail,
        customerNote,
        dabLocation,
        date,
        endTimeHours,
        endTimeMinutes,
        foreman,
        heliTime,
        id,
        project,
        projectActivity,
        startTimeHours,
        startTimeMinutes,
        transport,
        subProject,
        unpaidBreak,
        heliTime,
        standby,
      }));
    } catch (error) {
      setErrorLoadingFormData(true);
    }
  };

  const handleFormChange = (e) => {
    // Handling of date
    if (typeof e.date !== "undefined") return setFormData({ ...formData, date: e.date });

    if (e.persist) e.persist();

    // Handling of rest
    if (e.target) {
      const { checked, name, value } = e.target;

      // If a project is chosen, remove potentially chosen subProjects and projectActivites.
      // Both in the formData object, but also in the api-responses
      if (name === "project") {
        setFormData({ ...formData, project: value, subProject: "", projectActivity: "" });
      } else if (name === "subProject") {
        // The same as above, but only with subProject
        setFormData({ ...formData, subProject: value, projectActivity: "" });
      } else if (name === "break") {
        if (value === "paid") setFormData({ ...formData, [name]: value, paidBreak: true, unpaidBreak: false });
        else setFormData({ ...formData, [name]: value, paidBreak: false, unpaidBreak: true });
      } else if (name === "transport" || name === "heliTime") {
        setFormData({
          ...formData,
          [name]: checked,
          [name === "transport" ? "heliTime" : "transport"]: false,
          standby: false,
        });
      } else if (name === "endOfRotation" || name === "changeShift" || name === "standby") {
        setFormData({ ...formData, [name]: checked });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    }
  };

  const isFormValid = () => {
    // IF PROJECT IS PROVIDED, THEN SUB PROJECT AND ACTIVITY ARE REQUIRED
    if (formData.project) {
      if (!formData.subProject || !formData.projectActivity) {
        notification.error({ duration: 7, message: "FAILED", description: lang.projectSubProjectActivityAreRequired });
        return false;
      }
    }

    //For all cases, the foreman, date, startTimeHours, startTimeMinutes, endTimeHours and endTimeMinutes must be completed.
    if (
      !formData.dabTrade ||
      !formData.foreman ||
      !formData.date ||
      !formData.startTimeHours ||
      !formData.startTimeMinutes ||
      !formData.endTimeHours ||
      !formData.endTimeMinutes ||
      !formData.dabLocation
    ) {
      notification.error({ duration: 7, message: "FAILED", description: lang.errorRegistrationRequiredFields });
      return false;
    }

    return true;
  };

  const handleUpdate = () => {
    // eslint-disable-next-line
    if (isFormValid()) {
      setSubmittingForm(true);

      updateRegistration(formData)
        .then(() => {
          notification.success({ duration: 7, message: "SUCCESS", description: lang.savedRegistrationToastContent });
          dispatch(hideModalPage());
        })
        .catch((err) => {
          let errorMessage = "Unspecified error";
          if (err && err.response && err.response.data) errorMessage = err.response.data.message;

          notification.error({
            duration: 7,
            message: "FAILED",
            description: `${lang.errorSavingRegistrationToastContent}. Fejlkode: ${errorMessage}`,
          });

          setSubmittingForm(false);
        });
    } else {
      notification.error({
        duration: 7,
        message: "FAILED",
        description: "Du skal udfylde projekt, underprojekt, aktivitet, dato, og start- og sluttid",
      });
    }
  };

  const handleSave = () => {
    // eslint-disable-next-line
    if (isFormValid()) {
      setSubmittingForm(true);

      addRegistration({ ...formData, hours: totalHours })
        .then(() => {
          notification.success({ duration: 7, message: "SUCCESS", description: lang.savedRegistrationToastContent });

          dispatch(hideModalPage());

          // Saves last used item in local storage
          localStorage.setItem(
            "semcotime-last-time-input",
            JSON.stringify({
              startTimeHours: formData.startTimeHours,
              startTimeMinutes: formData.startTimeMinutes,
              endTimeHours: formData.endTimeHours,
              endTimeMinutes: formData.endTimeMinutes,
              project: formData.project,
              subProject: formData.subProject,
              activity: formData.projectActivity,
              dabLocation: formData.dabLocation,
              dabTrade: formData.dabTrade,
            })
          );
        })
        .catch((err) => {
          const errorMessage =
            err.response.hasOwnProperty("data") && err.response.data.message ? err.response.data.message : null;

          notification.error({
            duration: 7,
            message: "FAILED",
            description: errorMessage ? errorMessage : lang.errorSavingRegistrationToastContent,
          });
          setSubmittingForm(false);
        });
    }
  };

  const handleTimeInput = (e, type) => {
    // Exception for deleing
    if (e.target.value === "") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const num = parseInt(e.target.value);

    if (
      // If not a number
      isNaN(num) ||
      // If length is more than two digits
      e.target.value.length > 2 ||
      // type is hours and value is not between 0-23
      (type === "hours" && (num < 0 || num > 23)) ||
      // type is minutes and value is not between 0-59
      (type === "minutes" && (num < 0 || num > 59))
    ) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getProjectActivityPlaceholder = () => {
    if (projectActivityStatus === "loading" || projectActivityStatus === "idle") {
      return lang.loading;
    } else if (projectActivityData.length === 0) {
      return lang.noActivities;
    } else {
      return lang.chooseActivity;
    }
  };

  const getSubProjectPlaceholder = () => {
    if (subProjectStatus === "loading" || subProjectStatus === "idle") {
      return lang.loading;
    } else if (subProjectData.length === 0) {
      return "No subprojects found";
    } else {
      return lang.chooseASubproject;
    }
  };

  useEffect(() => {
    // EDIT MODE
    if (updateRegistration) {
      initializeFormForEdit();
      setEditMode(true);
    } else {
      // ADD MODE
      const date = format(new Date(), "yyyy-MM-dd");

      getWageCodes();
      getForemen();

      // Gets last used time-item in local storage (uses whatever the user entered last)
      let lastUsedTime = localStorage.getItem("semcotime-last-time-input");
      lastUsedTime = lastUsedTime ? JSON.parse(lastUsedTime) : null;
      getDefaultEmployeeTrade();

      if (
        typeof defaultProjectSubProjectAndActivityData.projectId !== "undefined" &&
        typeof defaultProjectSubProjectAndActivityData.subProjectId !== "undefined" &&
        typeof defaultProjectSubProjectAndActivityData.activitySequence !== "undefined"
      ) {
        setFormData((formData) => ({
          ...formData,
          date,
          project: lastUsedTime
            ? lastUsedTime.project
              ? lastUsedTime.project
              : defaultProjectSubProjectAndActivityData.projectId
            : formData.project,
          subProject: lastUsedTime
            ? lastUsedTime.subProject
              ? lastUsedTime.subProject
              : defaultProjectSubProjectAndActivityData.subProjectId
            : formData.subProject,
          projectActivity: lastUsedTime
            ? lastUsedTime.activity
              ? lastUsedTime.activity
              : defaultProjectSubProjectAndActivityData.activitySequence
            : formData.projectActivity,
          startTimeHours: lastUsedTime ? lastUsedTime.startTimeHours : formData.startTimeHours,
          startTimeMinutes: lastUsedTime ? lastUsedTime.startTimeMinutes : formData.startTimeMinutes,
          endTimeHours: lastUsedTime ? lastUsedTime.endTimeHours : formData.endTimeHours,
          endTimeMinutes: lastUsedTime ? lastUsedTime.startTimeMinutes : formData.startTimeMinutes,
          dabLocation: lastUsedTime ? lastUsedTime.dabLocation : formData.dabLocation,
          // dabTrade: lastUsedTime ? lastUsedTime.dabTrade : formData.dabTrade,
        }));
      } else {
        setFormData({
          ...formData,
          date,
          project: lastUsedTime ? lastUsedTime.project : formData.project,
          subProject: lastUsedTime ? lastUsedTime.subProject : formData.subProject,
          projectActivity: lastUsedTime ? lastUsedTime.activity : formData.projectActivity,
          startTimeHours: lastUsedTime ? lastUsedTime.startTimeHours : formData.startTimeHours,
          startTimeMinutes: lastUsedTime ? lastUsedTime.startTimeMinutes : formData.startTimeMinutes,
          endTimeHours: lastUsedTime ? lastUsedTime.endTimeHours : formData.endTimeHours,
          endTimeMinutes: lastUsedTime ? lastUsedTime.startTimeMinutes : formData.startTimeMinutes,
        });
      }
    } // eslint-disable-next-line
  }, [defaultProjectSubProjectAndActivityStatus]);

  useEffect(() => {
    const hourTotal = getTotalHours({
      date: formData.date,
      startTimeHours: formData.startTimeHours,
      startTimeMinutes: formData.startTimeMinutes,
      endTimeHours: formData.endTimeHours,
      endTimeMinutes: formData.endTimeMinutes,
    });

    if (hourTotal && hourTotal !== totalHours) {
      if (hourTotal < 5) {
        setFormData({ ...formData, hours: hourTotal, paidBreak: false, unpaidBreak: false });
      } else if (hourTotal >= 5 && hourTotal <= 12) {
        setFormData({ ...formData, hours: hourTotal, paidBreak: false, unpaidBreak: true });
      } else if (hourTotal > 12) {
        setFormData({ ...formData, hours: hourTotal, paidBreak: true, unpaidBreak: false });
      }

      setTotalHours(hourTotal);
    } // eslint-disable-next-line
  }, [
    formData.date,
    formData.startTimeHours,
    formData.startTimeMinutes,
    formData.endTimeHours,
    formData.endTimeMinutes,
    totalHours,
  ]);

  useEffect(() => {
    setTradeCodesList(dabTradeCodes);
  }, [dabTradeCodes.length]);

  useEffect(() => {
    if (formData.dabLocation === "Abroad - Onshore" || formData.dabLocation === "Abroad - Offshore") {
      setTradeCodesList(dabTradeCodes.filter((d) => ["022", "070", "120", "125"].includes(d.DabTradeId)));
      return setFormData((formData) => ({ ...formData }));
    } else {
      setTradeCodesList(dabTradeCodes);
      setFormData((formData) => ({
        ...formData,
        dabTrade: formData.dabTrade,
        transport: formData.dabLocation === "OffShore" ? false : formData.transport,
        heliTime: formData.dabLocation === "OnShore" ? false : formData.heliTime,
      }));
    }
  }, [formData.dabLocation, dabTradeCodes.length]);

  useEffect(() => {
    const { startTimeHours = "", endTimeHours = "", startTimeMinutes = "", endTimeMinutes = "" } = formData;

    setFormData({
      ...formData,
      shift: moment(`${endTimeHours}:${endTimeMinutes}`, "hh:mm").isBefore(
        moment(`${startTimeHours}:${startTimeMinutes}`, "hh:mm")
      )
        ? "Night"
        : "Day",
    });
  }, [formData.startTimeHours, formData.endTimeHours, formData.startTimeMinutes, formData.endTimeMinutes]);

  if (!isFormReady && !errorLoadingFormData) {
    return (
      <Page className={componentStyles()}>
        <InlineSpinner style={{ marginTop: "1rem" }} />
      </Page>
    );
  }

  if (errorLoadingFormData) {
    return (
      <Page className={componentStyles()}>
        <StatusBox
          title="Error loading data"
          content="The system were unable to get data for this record. Please try again later or contact an administrator"
        />
      </Page>
    );
  }

  const { changeShift = false, shift = "Day", paidBreak = false, unpaidBreak = false } = formData;

  return (
    <Page className={componentStyles()}>
      <ScrollView>
        <div className="content-wrapper">
          <h2>{lang.general}</h2>

          {/* Project */}
          <p>{lang.project}</p>
          <DropDown
            loading={projectStatus === "loading"}
            name="project"
            onChange={handleFormChange}
            options={projectData.map((d) => ({ label: `${d.id} - ${d.name}`, value: d.id }))}
            placeholder={lang.chooseProject}
            value={formData.project}
          />

          {/* Sub project (depends on project) */}
          {formData.project && (
            <>
              <p>{lang.subproject}</p>
              <DropDown
                loading={subProjectStatus === "loading"}
                name="subProject"
                onChange={handleFormChange}
                options={subProjectData.map((d) => ({ label: `${d.id} - ${d.description}`, value: d.id }))}
                placeholder={getSubProjectPlaceholder()}
                value={formData.subProject}
              />
            </>
          )}

          {/* Activity (depends on project and subProject) */}
          {formData.project && formData.subProject && (
            <>
              <p>{lang.activity}</p>
              <DropDown
                loading={projectActivityStatus === "loading"}
                name="projectActivity"
                onChange={handleFormChange}
                options={projectActivityData.map((d) => ({ label: `${d.no} - ${d.description}`, value: d.id }))}
                placeholder={getProjectActivityPlaceholder()}
                value={formData.projectActivity}
              />
            </>
          )}
          {/* location */}
          <p>{lang.location}</p>
          <DropDown
            loading={dabLocationsIsFetching}
            name="dabLocation"
            onChange={handleFormChange}
            options={dabLocations ? dabLocations.map((d) => ({ label: d.DabLocationValue, value: d.DabLocationValue })) : []}
            placeholder={lang.chooseADabLocation}
            value={formData.dabLocation}
          />
          <p>{lang.dabTrade}</p>
          <DropDown
            loading={dabTradeIsFetching || defaultEmployeeValuesStatus === "loading"}
            name="dabTrade"
            onChange={handleFormChange}
            options={
              tradeCodesList
                ? tradeCodesList.map((d) => ({ label: `${d.DabTradeId} - ${d.DabTradeDesc}`, value: d.DabTradeId }))
                : []
            }
            placeholder={lang.chooseADabTrade}
            value={formData.dabTrade}
          />
          {/* Customer detail */}
          <p>{lang.customerDetail}</p>
          <TextInput name="customerDetail" value={formData.customerDetail} onChange={handleFormChange} />

          {/* Customer note */}
          <p>{lang.customerNote}</p>
          <TextInput name="customerNote" value={formData.customerNote} onChange={handleFormChange} />

          {/* Foreman */}
          <>
            <p>Foreman *</p>
            <DropDown
              // loading={}
              name="foreman"
              onChange={handleFormChange}
              options={foremen.map((d) => ({ disabled: d.disabled, label: d.name, value: d.id }))}
              placeholder="Choose a foreman"
              value={formData.foreman}
            />
          </>

          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Checkbox
                disabled={formData.dabLocation === "OffShore"}
                checked={formData.transport}
                name="transport"
                onChange={handleFormChange}
              >
                Transport
              </Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox
                disabled={formData.dabLocation === "OnShore"}
                checked={formData.heliTime}
                name="heliTime"
                onChange={handleFormChange}
              >
                Heli Time
              </Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox
                disabled={formData.heliTime || formData.transport}
                checked={formData.standby}
                name="standby"
                onChange={handleFormChange}
              >
                {lang.standby}
              </Checkbox>
            </Col>
            <Col span={6}>
              <Checkbox checked={formData.endOfRotation} name="endOfRotation" onChange={handleFormChange}>
                End of Rotation
              </Checkbox>
            </Col>
          </Row>

          {/* Date */}
          <h2>{lang.dateAndTime}</h2>

          {editMode ? (
            <p className="fake-input">
              Date: <span style={{ color: colors.darkGrey }}>{formData.date}</span>
            </p>
          ) : (
            <>
              <p>{lang.date} *</p>
              <DatePicker
                name="date"
                value={formData.date}
                onDateChange={(date) => {
                  handleFormChange({ date });
                }}
              />
            </>
          )}

          {/* Start time hours */}
          <p>{lang.startTime} *</p>
          <div className="time-input">
            <TextInput
              name="startTimeHours"
              type="number"
              value={formData.startTimeHours}
              placeholder={lang.hours}
              onChange={(e) => handleTimeInput(e, "hours")}
            />

            {/* Start time minutes */}
            <p style={{ padding: "0 0.5rem" }}>{` : `}</p>
            <TextInput
              name="startTimeMinutes"
              type="number"
              value={formData.startTimeMinutes}
              placeholder={lang.minutes}
              onChange={(e) => handleTimeInput(e, "minutes")}
            />
          </div>

          {/* End time hours */}
          <p>{lang.endTime} *</p>
          <div className="time-input">
            <TextInput
              type="number"
              name="endTimeHours"
              value={formData.endTimeHours}
              placeholder={lang.hours}
              onChange={(e) => handleTimeInput(e, "hours")}
            />

            {/* End time minutes */}
            <p style={{ padding: "0 0.5rem" }}>{` : `}</p>
            <TextInput
              type="number"
              name="endTimeMinutes"
              value={formData.endTimeMinutes}
              placeholder={lang.minutes}
              onChange={(e) => handleTimeInput(e, "minutes")}
            />
          </div>

          <div style={{ marginTop: "1rem" }}>
            <p>Break</p>
            <Radio.Group
              disabled={true}
              name="break"
              onChange={handleFormChange}
              size="large"
              value={paidBreak ? "paid" : unpaidBreak ? "unpaid" : ""}
            >
              <Radio value="paid">Paid Break</Radio>
              <Radio value="unpaid">Un Paid Break</Radio>
            </Radio.Group>
          </div>

          <div style={{ marginTop: "1rem" }}>
            <p>Shift</p>
            <Radio.Group name="shift" onChange={handleFormChange} size="large" value={shift}>
              <Radio value="Day">Day</Radio>
              <Radio value="Night">Night</Radio>
            </Radio.Group>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <Checkbox checked={changeShift} name="changeShift" onChange={handleFormChange}>
              Change Shift
            </Checkbox>
          </div>
          {/* Activities (Wage codes) */}
          <h2>{lang.sc_extra}</h2>

          {loadingWageCodes === false && wageCodes.length === 0 && (
            <p className="meta" style={{ color: colors.darkGrey }}>
              <InformationOutlineIcon
                style={{ color: colors.darkGrey, width: "1.25rem", height: "1.25rem", marginBottom: "-4px" }}
              />
              {lang.noAvailableSupplements}
            </p>
          )}

          {loadingWageCodes === false && wageCodes.length === 0 ? null : (
            <>
              <p>{lang.addActivities}</p>
              <div className="flex" style={{ marginBottom: "1rem" }}>
                <DropDown
                  // loading={}
                  name="activity"
                  onChange={(e) => setSelectedActivity(e.target.value)}
                  options={wageCodes
                    .filter((d) => formData.activities.findIndex((el) => el.id === d.id) === -1)
                    .map((d) => ({ label: `${d.name} - ${d.id}`, value: d.id }))}
                  value={selectedActivity}
                />

                <Button
                  style={{ lineHeight: "13px", flexShrink: 0 }}
                  fullWidth={false}
                  onClick={() => {
                    if (selectedActivity) {
                      let activity = wageCodes.filter((x) => x.id === selectedActivity)[0];
                      activity.hours = "";
                      setFormData({ ...formData, activities: [activity, ...formData.activities] });
                    }
                  }}
                >
                  {lang.add}
                </Button>
              </div>
            </>
          )}

          {formData.activities.map((activity, index) => (
            <div className="activity" key={index}>
              <p className="name">
                {activity.name} - {activity.id}
              </p>
              <TextInput
                name={activity.name.toLowerCase().replace(/ /g, "")}
                type="number"
                value={activity.hours}
                placeholder={lang.enterHours}
                onChange={(e) => {
                  activity.hours = e.target.value; // Temporay mutation of map-element (is canceled on next render where change is committed)
                  setFormData({
                    ...formData,
                    activities: [...formData.activities.slice(0, index), activity, ...formData.activities.slice(index + 1)],
                  });
                }}
              />
              <div
                onClick={() => {
                  setFormData({ ...formData, activities: formData.activities.filter((x) => activity.id !== x.id) });
                }}
              >
                <CloseIcon />
              </div>
            </div>
          ))}

          <div className="seperator"></div>

          <Button active={submittingForm} style={{ marginTop: "2rem" }} onClick={editMode ? handleUpdate : handleSave}>
            {lang.save}
          </Button>

          <p className="note">* {lang.mandatoryField}</p>
        </div>
      </ScrollView>
    </Page>
  );
};

const componentStyles = () => css`
  background-color: white;
  padding-top: ${common.topBarHeight}px;

  .content-wrapper {
    max-width: 800px;
    margin: 0 auto;
  }

  .time-input {
    display: flex;
    align-items: center;
  }

  .scroll-view {
    padding: 0.75rem 0.75rem 5rem 0.75rem;
  }

  p {
    padding: 0.75rem 0 0 0;
  }

  h2:not(:first-child) {
    border-top: 1px solid ${colors.lightGrey};
    margin: 0.75rem -0.75rem 0 -0.75rem;
    padding: 0.75rem 0.75rem 0 0.75rem;
  }

  .activity {
    display: flex;
    align-items: center;
    border-top: 1px solid ${colors.lightGrey};
    padding: 0.75rem 0.75rem 0.75rem 0;
    margin: 0 -0.75rem 0 0rem;

    &:last-of-type {
      border-bottom: 1px solid ${colors.lightGrey};
    }

    .name {
      flex: 1 1 70%;
      padding: 0;
    }

    svg {
      height: 40px; /* WARNING: Magic number */
    }

    input {
      flex: 1 1 2rem;
      margin: 0 0.5rem 0 0.5rem;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .note {
    color: rgb(110, 110, 110);
  }
`;

export default memo(WorkTimeRegistration);
