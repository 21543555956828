import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

// css
import { css } from 'emotion';
import colors from '../../../style/colors';

// actions

import TopBar from '../../ui/TopBar';
import Page from '../../ui/Page';
import { useSelector } from 'react-redux';
import getBackButtonUrl from '../../../utilities/get-back-button-url';
import getPageFromId from '../../../utilities/get-page-from-id';
import ActionWrapper from '../../ui/ActionWrapper';
import pageNavigator from '../../../utilities/page-navigator';
import { ArrowBackIcon } from 'mdi-react';
import ScrollView from '../../ui/ScrollView';
import TextInput from '../../ui/TextInput';
import InlineSpinner from '../../ui/InlineSpinner';
import ForemanTeamUser from './ForemanTeamUser';
import req from '../../../utilities/request-utility';

function ForemanRegistration(props) {
	const pages = useSelector(s => s.pages.pages);
	const lang = useSelector(s => s.language.language);

	const page = getPageFromId(pages, props.match.params.pageId);
	const backButtonURL = getBackButtonUrl(page);

	const [interfaceIsBusy, setInterfaceIsBusy] = useState(false);
	const [filterTerm, setFilterTerm] = useState('');

	const { user } = useSelector(({ auth }) => ({ user: auth.user }));

	const {
		data: { data: dataUsers = [] } = {},
		isError: isErrorUsers,
		isLoading: isLoadingUsers
	} = useQuery('users', () => req()(`semcotime/foremen-teams/users`));

	const {
		data: { data: dataTeamUsers = [] } = {},
		isError: isErrorTeamUsers,
		isLoading: isLoadingTeamUsers,
		refetch: refetchTeamUsers
	} = useQuery('teamUsers', () => req()(`semcotime/foremen-teams/my-team`));

	const users = {
		data: dataUsers.filter(d => d.id !== user.id),
		tempData: dataUsers,
		isError: isErrorUsers,
		isLoading: isLoadingUsers
	};

	const teamUsers = {
		data: dataTeamUsers.filter(d => d.id !== user.id),
		isError: isErrorTeamUsers,
		isLoading: isLoadingTeamUsers
	};

	const teamUserIds = dataTeamUsers.map(d => d.id);

	const addToTeam = async id => {
		setInterfaceIsBusy(true);

		try {
			await req().put(`semcotime/foremen-teams/my-team/${id}`);
			await refetchTeamUsers();
			setInterfaceIsBusy(false);
		} catch (error) {
			setInterfaceIsBusy(false);
		}
	};

	const removeFromTeam = async id => {
		setInterfaceIsBusy(true);

		try {
			await req().delete(`semcotime/foremen-teams/my-team/${id}`);
			await refetchTeamUsers();
			setInterfaceIsBusy(false);
		} catch (error) {
			setInterfaceIsBusy(false);
		}
	};

	useEffect(() => {
		const loggedInUser = users.tempData.filter(d => d.id === user.id);
		if (loggedInUser.length !== 0) addToTeam(loggedInUser[0].id);
	}, [users.data.length]);

	return (
		<Page className={componentStyles(props)}>
			<TopBar
				actionLeft={
					<ActionWrapper onClick={() => pageNavigator(backButtonURL, 'backward')}>
						<ArrowBackIcon />
					</ActionWrapper>
				}
				title={page.title}
			/>
			<div className="main-content">
				<div className="col col-left">
					<TextInput
						value={filterTerm}
						onChange={e => setFilterTerm(e.target.value)}
						placeholder={lang.filterList}
						style={{ margin: '1rem 1rem 0 1rem', width: 'calc(100% - 2rem)' }}
					/>

					<ScrollView style={{ padding: '1rem' }}>
						{users.isLoading && !users.isError && <InlineSpinner style={{ margin: '2rem' }} />}

						{!users.isLoading &&
							users.data.map(user => {
								if (filterTerm) {
									const regex = new RegExp(
										`(${filterTerm})|(${filterTerm.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')})`,
										'gi'
									);
									if (!regex.test(user.name) && !regex.test(user.ticketNumber)) return null;
								}

								const isSelected = teamUserIds.includes(user.id);
								return (
									<ForemanTeamUser
										key={user.id}
										user={user}
										onClick={() => (isSelected ? null : addToTeam(user.id))}
										isSelected={isSelected}
									/>
								);
							})}
					</ScrollView>
				</div>

				<div className="col col-right">
					<ScrollView style={{ padding: '1rem' }}>
						<div className="meta" style={{ margin: '0.75rem 0 1.7rem 0', textAlign: 'left' }}>
							{teamUsers.data.length} / {users.data.length}
							{(users.isLoading || teamUsers.isLoading || interfaceIsBusy) && (
								<InlineSpinner
									size="14"
									style={{ display: 'inline-block', margin: '0 0rem -0.6rem 0.5rem' }}
								/>
							)}
						</div>

						{teamUsers.isLoading && !teamUsers.isError && <InlineSpinner style={{ margin: '2rem' }} />}

						{!teamUsers.isLoading &&
							teamUsers.data.map(user => (
								<ForemanTeamUser
									key={user.id}
									onClick={() => removeFromTeam(user.id)}
									user={user}
									isDeletable={true}
								/>
							))}
					</ScrollView>
				</div>
			</div>
		</Page>
	);
}

const componentStyles = () => css`
	.main-content {
		display: flex;
		justify-content: space-between;
		height: 100%;
		overflow: hidden;

		.col {
			flex: 50% 0 1;
			background-color: ${colors.white};
		}

		.col-left {
			border-right: 1px ${colors.midGrey} solid;
			box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
			position: relative;
			display: flex;
			flex-direction: column;
		}

		.col-right {
			display: flex;
			flex-direction: column;
		}
	}

	.meta {
		color: ${colors.darkGrey};
		text-align: center;
	}
`;

export default ForemanRegistration;
