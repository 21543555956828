import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hideModalPage } from "../../actions/uiActions";
import { css } from "emotion";
import { CSSTransition } from "react-transition-group";
import { durations } from "../../config/animations";
import { CloseIcon } from "mdi-react";
import Page from "./Page";
import Overlay from "./Overlay";

// Components
import TopBar from "./TopBar";
import ActionWrapper from "./ActionWrapper";

const ModalPage = props => {
  let { active, closeCallback, title, actionRight, content } = props.modalPage;

  return (
    <>
      <Overlay active={active} />
      <CSSTransition in={active} timeout={durations.normal} mountOnEnter={true} unmountOnExit={true} classNames="modal-page">
        <Page key={0} className={container()} data-test-id="modal-page">
          <TopBar
            actionLeft={
              <ActionWrapper
                onClick={closeCallback ? () => closeCallback(hideModalPage) : props.hideModalPage}
                data-test-id="modal-page__action-left"
              >
                <CloseIcon />
              </ActionWrapper>
            }
            actionRight={actionRight}
            title={title}
          />
          {/* {content && content()} */}
          {content && typeof content === "function" && content()}
          {content && typeof content === "object" && content}
        </Page>
      </CSSTransition>
    </>
  );
};

const container = () => css`
  position: fixed;
  top: 0;
  left: 0;
  will-change: auto;

  &.modal-page-enter {
    opacity: 0;

    /**
     Animates from scren edge for screen less than 700px, for screen larger than 700px
     it will animate with a fixed pixel offset since the duration of the animations is fixed
     and a fast animation from screen edge has to rapid movement. Its also quite difficult
     to get it to run smooth on smaller hardware 
     */
    @media screen and (max-height: 700px) {
      transform: translate3d(0, 100%, 0);
    }
    @media screen and (min-height: 701px) {
      transform: translate3d(0, 300px, 0);
    }
  }
  &.modal-page-enter-active {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
    transition: opacity ${durations.normal}ms, transform ${durations.normal}ms;
  }
  &.modal-page-exit {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
  &.modal-page-exit-active {
    opacity: 0;
    @media screen and (max-height: 700px) {
      transform: translate3d(0, 100%, 0);
    }
    @media screen and (min-height: 701px) {
      transform: translate3d(0, 300px, 0);
    }
    transition: opacity ${durations.normal}ms, transform ${durations.normal}ms;
  }
`;

const mapStateToProps = state => ({
  modalPage: state.ui.modalPage,
  primaryColor: state.appConfig.primaryColor
});

const mapDispatchToProps = dispatch => ({
  hideModalPage: bindActionCreators(hideModalPage, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalPage);
