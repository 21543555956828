import React from 'react';
import { useSelector } from 'react-redux';
import { RadioboxBlankIcon, RadioboxMarkedIcon, ApproveIcon } from 'mdi-react';
import moment from 'moment';
import { Tag } from 'antd';
import 'moment/locale/da'; // Import the Danish locale
// css
import { css } from 'emotion';
import colors from '../../style/colors';

import ActionWrapper from '../ui/ActionWrapper';

function ForemanListItem(props) {
	const { approvedTimeRecords, data: registration, onClick, selected, selectAllApproved } = props;
	const { data, type, isNewType, lockedByForeman } = registration;
	const { timeRegistrations = [] } = data;

	const primaryColor = useSelector(s => s.appConfig.primaryColor);
	const selectedLanguage = useSelector(s => s.auth.user.language);

	const isRecordApproved =
		lockedByForeman && timeRegistrations.filter(d => d.approvedByForeman).length === timeRegistrations.length;

	const {
		id: registrationId,
		date,
		endTimeHours,
		endTimeMinutes,
		startTimeHours,
		startTimeMinutes,
		startDate,
		startTime,
		endDate,
		endTime
	} = data;
	let displayTimes = [];

	// show the earliest in time and latest out time
	if (type === 'time') {
		const foremattedTimeRegistrations = timeRegistrations.map(d => {
			const inTimeRaw = moment(`${date} ${d.startTime}`);
			const outTimeRaw = moment(`${date} ${d.endTime}`);

			return {
				inTime: d.startTime,
				outTime: d.endTime,
				inTimeRaw,
				outTimeRaw,
				isOvernight: moment(`${date} ${d.startTime}`).isAfter(moment(`${date} ${d.endTime}`))
			};
		});

		// if all time registrations are within whole day
		const earliestInTime = foremattedTimeRegistrations.reduce((acc, obj) => {
			return acc.inTimeRaw.isBefore(obj.inTimeRaw) ? acc : obj;
		}).inTime;
		const latestOutTime = foremattedTimeRegistrations.reduce((acc, obj) => {
			return acc.outTimeRaw.isAfter(obj.outTimeRaw) ? acc : obj;
		}).outTime;

		displayTimes.push(<Tag key={date}>{`${earliestInTime} - ${latestOutTime}`}</Tag>);

		// if there are overnight registrations and check if only one registration to avoid duplicate
		if (foremattedTimeRegistrations.some(d => d.isOvernight) && foremattedTimeRegistrations.length > 1) {
			const overlappingRegistration = foremattedTimeRegistrations.filter(d => d.isOvernight);

			// selected index zero since there will be only one overnight per registration
			displayTimes.push(
				<Tag key={date}>{`${overlappingRegistration[0].inTime} - ${overlappingRegistration[0].outTime}`}</Tag>
			);
		}
	}

	const isRecordIncluded = approvedTimeRecords.find(d => d.id === registrationId);

	return (
		<ActionWrapper className={`${componentStyles(primaryColor)} ${selected ? 'selected' : ''}`} onClick={onClick}>
			<>
				{type === 'time' ? (
					<div className="info-time">
						<div style={{ display: 'flex' }}>
							<div>
								{selected || (isRecordIncluded && selectAllApproved) ? (
									<RadioboxMarkedIcon />
								) : (
									<RadioboxBlankIcon />
								)}
								<span className="date">{date}</span>
								<span>{moment(date).locale(selectedLanguage).format('dddd')}</span>
							</div>
						</div>

						{!isNewType && (
							<span className="clock">
								{`${startTimeHours}:${startTimeMinutes}:00`}-{`${endTimeHours}:${endTimeMinutes}:00`}
							</span>
						)}
					</div>
				) : (
					<div className="info-absence">
						{selected || (type === 'absence' && selectAllApproved) ? (
							<RadioboxMarkedIcon />
						) : (
							<RadioboxBlankIcon />
						)}
						<span className="date">{moment(startDate).format('YYYY-MM-DD')}</span>
						<span className="to"> - </span>
						<span className="date">{moment(endDate).format('YYYY-MM-DD')}</span>
						<span className="clock">{`${startTime}:00`}</span>
						<span className="to"> - </span>
						<span className="clock">{`${endTime}:00`}</span>
					</div>
				)}
			</>
			<div className="hours">
				{displayTimes.length > 0 && displayTimes.map((d, i) => <span key={i}>{d}</span>)}
			</div>
			<p className="type">
				<>
					{type}
					{isRecordApproved ? (
						<ApproveIcon style={{ marginLeft: '.3rem' }} size={'1rem'} />
					) : (
						<span style={{ display: 'inline-block', width: '1.3rem', height: '1rem' }} />
					)}
				</>
			</p>
		</ActionWrapper>
	);
}

const componentStyles = primaryColor => css`
	display: flex;
	border: 1px ${colors.lightGrey} solid;
	padding: 0.75rem;
	border-radius: 0;
	width: 100%;
	margin-bottom: -1px;
	align-items: center;
	justify-content: space-between;

	&.selected {
		outline: 3px ${primaryColor} solid;
		outline-offset: 0px;
		outline-style: auto;

		svg {
			color: ${primaryColor};
		}
	}

	.date {
		font-weight: 700;
		margin: 0 0.85rem;
	}

	.to {
		font-weight: 700;
		position: relative;
		top: -1px;
	}

	.type {
		font-weight: 700;
		color: #6e6e6e;
	}

	.type:first-letter {
		text-transform: capitalize;
	}

	.info-absence {
		display: inline;

		span {
			position: relative;
			top: -6px;
		}
	}

	.info-time {
		flex-grow: 2;
	}

	svg {
		color: ${colors.darkGrey};
	}

	@media (max-width: 414px) {
		flex-direction: column;

		.info {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: baseline;
		}
	}
`;

export default ForemanListItem;
