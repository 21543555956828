import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Select } from 'antd';

const DropDown = ({
	allowClear = false,
	changeGetPopupContainer = true,
	disabled = false,
	loading = false,
	name = '',
	multiple = false,
	onChange = () => {},
	options = [],
	placeholder = 'Select one from the list',
	style = {},
	value = undefined,
	size = 'large'
}) => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

	return (
		<Select
			allowClear={allowClear}
			disabled={disabled}
			loading={loading}
			listHeight={isTabletOrMobile ? 150 : 256}
			name={name}
			{...(multiple ? { mode: 'multiple' } : {})}
			onChange={e => onChange({ target: { name, value: e } })}
			optionFilterProp="children"
			getPopupContainer={changeGetPopupContainer ? trigger => trigger.parentElement : null}
			placeholder={loading ? 'Loading' : placeholder}
			showSearch
			size={size}
			style={{ width: '100%', ...style }}
			value={loading || options.length === 0 ? (multiple ? [] : null) : value || null}>
			{options.map((d, i) => (
				<Select.Option disabled={d.disabled} key={i} value={d.value}>
					{d.label}
				</Select.Option>
			))}
		</Select>
	);
};

export default DropDown;
