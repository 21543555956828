import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';

import workHourState from '../../config/semcotimedk-workhour-states';

// css
import { css } from 'emotion';
import colors from '../../style/colors';
import {
	CheckIcon,
	DeleteEmptyIcon,
	DotsVerticalIcon,
	PlusIcon,
	PencilIcon,
	LockOpenOutlineIcon,
	LockIcon,
	TrashCanOutlineIcon
} from 'mdi-react';

// ui
import InlineSpinner from '../ui/InlineSpinner';

// actions
import { showContextMenu, showModalPage, showDialog, hideDialog } from '../../actions/uiActions';
import AbsenceRegistrationDK from './AbsenceRegistrationDK';

const getTitle = titleString => {
	let [date, startTime, endTime, fullDay] = titleString.split('__');
	fullDay = JSON.parse(fullDay);
	let returnString = date;
	if (startTime && endTime && !fullDay) returnString += `  ${startTime} - ${endTime}`;
	return returnString;
};

function ListItemDK(props) {
	const { lang, registration, showModalPage, showContextMenu } = props;
	const { ifsData = null } = registration;

	const [expanded, setExpanded] = useState(false);
	const [deleting, setDeleting] = useState(false);

	const onDelete = async () => {
		setDeleting(true);

		await props.deleteRegistration(registration);

		setDeleting(false);
	};

	return (
		<div className={`${componentStyles(props)} ${expanded ? 'expanded' : 'collapsed'}`}>
			<div className="item-header" onClick={() => setExpanded(!expanded)}>
				<div className="left">
					{registration.state === workHourState.open && <LockOpenOutlineIcon />}
					{registration.state === workHourState.lockedByForeman && <LockIcon />}
					{registration.state === workHourState.locked && <LockIcon />}
					{registration.state === workHourState.approved && <CheckIcon />}
					{registration.state === workHourState.deleted && <DeleteEmptyIcon style={{ color: 'red' }} />}
					<p>{getTitle(registration.timeKey)}</p>
				</div>
				<div className="right">
					<div style={{ marginRight: '0.25rem' }}>{registration.totalHours}</div>
					{deleting && (
						<InlineSpinner size="14" style={{ display: 'inline-block', margin: '5px 0.5rem 0rem 0rem' }} />
					)}
					{!deleting && (
						<>
							<DotsVerticalIcon
								style={{ width: '1.35rem', height: '1.35rem', margin: '0 0.25rem ' }}
								onClick={e => {
									e.stopPropagation();
									showContextMenu([
										{
											icon: <PencilIcon />,
											title: lang.edit,
											callback: () =>
												showModalPage({
													content: (
														<AbsenceRegistrationDK
															title={lang.updateRegistration}
															registration={registration}
															addRegistration={props.updateRegistration}
														/>
													)
												})
										},
										{
											icon: <TrashCanOutlineIcon />,
											title: lang.delete,
											callback: () =>
												props.showDialog({
													styleType: 'warning',
													title: 'Delete record',
													content:
														"You're about to delete this registration. Are you sure you want to delete it?",
													primaryActionTitle: 'YES - Delete',
													primaryAction: onDelete
												})
										}
									]);
								}}
							/>
							<PlusIcon
								style={{ marginRight: '-0.05rem' }}
								className={expanded ? 'rotate' : 'rotate-neutral'}
							/>
						</>
					)}
				</div>
			</div>
			<div className={`item-details`}>
				<div className={`${expanded ? 'show' : 'hide'}`}>
					<div style={{ borderTop: `1px solid ${colors.midGrey}` }}>
						{registration.state === workHourState.deleted && <div className="deleted">DELETED IN IFS</div>}

						<div className="details">
							<p>
								<span>{lang.startDate}: </span>
								{format(parseISO(registration.data.startDate), 'yyyy-MM-dd')}
							</p>
							<p>
								<span>{lang.endDate}: </span>
								{format(parseISO(registration.data.endDate), 'yyyy-MM-dd')}
							</p>
							{registration.data.fullDay ? (
								<p>
									<span>All Day: </span>
									Yes
								</p>
							) : (
								<>
									<p>
										<span>{lang.startTime}: </span>
										{registration.data.startTime}
									</p>
									<p>
										<span>{lang.endTime}: </span>
										{registration.data.endTime}
									</p>
								</>
							)}
							<p>
								<span>{lang.note}: </span>
								{registration.data.note}
							</p>
							{ifsData && (
								<table>
									<thead>
										<tr>
											<th>{lang.wageCode}</th>
											<th>{lang.hours}</th>
										</tr>
									</thead>
									<tbody>
										{ifsData.map(d => (
											<tr key={d.WageCode}>
												<td>
													{d.WageCode} - {d.WageCodeDesc}
												</td>
												<td>{d.WageHours} hours</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const componentStyles = props => css`
	background-color: ${colors.white};
	border-top: 1px ${colors.midGrey} solid;
	border-bottom: 1px ${colors.midGrey} solid;
	margin-bottom: -1px;

	svg {
		color: ${props.primaryColor};
		width: 1rem;
		height: 1rem;
		margin-right: 0.5rem;
		margin-top: 2px;
		display: block;
	}

	&.collapsed {
		margin: 0;
		border-left: 3px solid transparent;
		transition: margin 240ms ease;
		margin-bottom: -1px;

		.hide {
			max-height: 0;
			opacity: 0;
			overflow: hidden;
			transition: max-height 240ms ease, opacity 240ms ease, overflow 240ms ease;
		}

		.rotate-neutral {
			transform: rotate(0deg);
			transition: transform 240ms ease;
		}
	}

	&.expanded {
		margin: 0.65rem 0;
		border-left: 3px solid ${props.primaryColor};
		transition: margin 240ms ease, border-left 240ms ease;

		&:first-of-type {
			margin-top: 0px;
		}

		.item-header {
			color: ${props.primaryColor};

			.rotate {
				transform: rotate(-45deg);
				transition: transform 240ms ease;
			}
		}

		.item-details {
			.deleted {
				width: 100%;
				text-align: center;
				background-color: red;
				color: #fff;
				padding: 10px;
				margin-bottom: 15px;
			}

			.show {
				max-height: 1000px;
				opacity: 1;
				transition: max-height 240ms ease, opacity 240ms ease;
			}
		}
	}

	.item-header {
		display: flex;
		padding: 0.75rem;
		margin-bottom: -1px;
		color: ${colors.darkGrey};
		font-weight: bold;

		&:active {
			background-color: ${colors.ultraLightGrey};
			transition: background-color 80ms ease;
		}

		.right {
			display: flex;
			margin-left: auto;
			font-weight: bold;
		}

		.left {
			display: flex;
			overflow: hidden;

			p {
				padding-right: 0.5rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}

	.item-details {
		.details {
			padding: 0.5rem 0.75rem 0.25rem 0.75rem;
			color: ${colors.darkGrey};

			span {
				font-weight: bold;
			}

			p {
				display: block;
				margin-bottom: 0.5rem;
			}
		}
	}
`;

const mapStateToProps = state => ({
	lang: state.language.language,
	primaryColor: state.appConfig.primaryColor
});

const mapDispatchToProps = dispatch => ({
	showContextMenu: bindActionCreators(showContextMenu, dispatch),
	showModalPage: bindActionCreators(showModalPage, dispatch),
	showDialog: bindActionCreators(showDialog, dispatch),
	hideDialog: bindActionCreators(hideDialog, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ListItemDK);
