import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tinycolor from 'tinycolor2';
import { css } from 'emotion';
import { isEqual } from 'lodash';

// Actions
import { openSideMenu } from '../actions/sideMenuActions';
import { showModalPage } from '../actions/uiActions';
import { getPages } from '../actions/pagesActions';
import { fetch as fetchUsers, set as setSelectedUser } from '../actions/userActions';
import StatusBox from './ui/StatusBox';

// Style
import colors from '../style/colors';

// Utilities
import iconConverter from '../utilities/icon-converter';
import linkPathCreator from '../utilities/get-link-path-from-page-object';
import pageNavigator from '../utilities/page-navigator';
import clearLocalStorage from '../utilities/clear-local-storage';
import getAppName from '../utilities/get-app-name';

// Components
import Page from '../components/ui/Page';
import TopBar from '../components/ui/TopBar';
import SearchInput from '../components/ui/SearchInput';
import Notification from './ui/Notification';
import Dropdown from './ui/DropDown';
import CorrectionsAlert from './semco-time/CorrectionAlert';

// Icons
import { MenuIcon, SearchIcon, CheckboxBlankCircleIcon } from 'mdi-react';

const frontPageIcon =
	process.env.REACT_APP_CUSTOM_NODE_ENV === 'development'
		? `${process.env.PUBLIC_URL}/img/dev-front-icon.png`
		: process.env.REACT_APP_CUSTOM_NODE_ENV === 'staging'
		? `${process.env.PUBLIC_URL}/img/test-front-icon.png`
		: process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
		? `${process.env.PUBLIC_URL}/img/prod-front-icon.png`
		: '';

const FrontPage = () => {
	const [clickCount, setClickCount] = useState(0);

	const dispatch = useDispatch();

	const pages = useSelector(({ pages }) => pages, isEqual);
	const lang = useSelector(({ language }) => language.language, isEqual);
	const userName = useSelector(({ auth }) => auth.user.name, isEqual);
	const userExternalId = useSelector(({ auth }) => auth.user.externalId, isEqual);
	const userList = useSelector(({ users }) => users.lists, isEqual);
	const userListLoading = useSelector(({ users }) => users.loading, isEqual);
	const selectedUser = useSelector(({ users }) => users.selectedUser, isEqual);
	const isTimeAppAdmin = useSelector(({ auth }) => auth.user.isTimeAppAdmin, isEqual);
	const appConfig = useSelector(({ appConfig }) => appConfig, isEqual);

	// This is set because of notifications - when a user clicks on a module, the pages need to update..
	useEffect(() => {
		dispatch(getPages());
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (isTimeAppAdmin) {
			if (userList.length === 0) dispatch(fetchUsers());
			else if (userList.length !== 0 && !selectedUser) dispatch(setSelectedUser(userExternalId));
		}
	}, [isTimeAppAdmin, userList.length]);

	useEffect(() => {
		// Toggles dialog if name is pressed 15 times
		if (clickCount === 15) {
			if (
				window.confirm(
					'You are about to reset the app. This will log you out. Are you sure you want to continue?'
				)
			) {
				clearLocalStorage(err => {
					window.location = window.location.origin + '/' + getAppName();
				});
			} else {
				setClickCount(0);
			}
		}

		// eslint-disable-next-line
	}, [clickCount]);

	let semcoTimeCorrectionsNotification = false;
	if (pages.pages) {
		const semcoTimeCorrectionsNotificationData = pages.pages.find(d => d.module === 'SemcoTimeCorrections');
		semcoTimeCorrectionsNotification = semcoTimeCorrectionsNotificationData
			? semcoTimeCorrectionsNotificationData.notifications !== '0'
				? true
				: false
			: false;
	}

	const currentUser = userList.length !== 0 ? userList.find(d => d.externalId === selectedUser) : null;

	return (
		<Page backgroundColor={colors.white}>
			{/* Topbar */}
			<TopBar
				actionLeft={<MenuIcon onClick={() => dispatch(openSideMenu())} />}
				title={appConfig && <img src={frontPageIcon} alt={`Logo for ${appConfig.title}`} />}
				atFrontPage={true}
				actionsRight={[
					<SearchIcon
						onClick={() => {
							dispatch(
								showModalPage({
									title: 'Search',
									content: () => (
										<SearchInput searching={false} primaryColor={appConfig.primaryColor} />
									)
								})
							);
						}}
					/>
				]}
			/>

			{/* Header */}
			<div className={header()} onClick={() => setClickCount(clickCount + 1)}>
				<p className="text-center">
					{lang.hello}, {currentUser ? currentUser.name : userName}
				</p>
				{isTimeAppAdmin && (
					<Dropdown
						disabled={userListLoading}
						loading={userListLoading}
						onChange={e => dispatch(setSelectedUser(e.target.value))}
						options={userList.map(d => ({ label: d.name, value: d.externalId }))}
						changeGetPopupContainer={false}
						placeholder="Select an employee"
						style={{ width: '30%' }}
						value={selectedUser}
					/>
				)}
			</div>
			{semcoTimeCorrectionsNotification && <CorrectionsAlert pages={pages} />}

			{/* Loading -> "Skeleton" icons with blinking animations */}
			{pages.pages.length === 0 && pages.loading && !pages.error && (
				<div className={`${iconsContainer(appConfig)} frontpage-navigation`}>
					{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num, index) => (
						<div className={icons()} key={index}>
							<CheckboxBlankCircleIcon
								className="placeholder-icon"
								style={{ animationDelay: `${index * 30}ms` }}
							/>
							<div className="fake-title" style={{ animationDelay: `${index * 30}ms` }}></div>
						</div>
					))}
				</div>
			)}

			{/* App misconfigured */}
			{pages.pages.length === 0 && !pages.loading && !pages.error && (
				<div className={`${iconsContainer(appConfig)} frontpage-navigation`}>
					<StatusBox
						style={{ marginTop: '2rem', color: colors.white }}
						action={{ callback: getPages, title: 'Try again' }}
						title="It looks like this app doesn't have any pages"
						content="We didn't find any  pages for this app. Try again or contact us at support@†oecho.dk"
					/>
				</div>
			)}

			{/* Error getting pages */}
			{pages.pages.length === 0 && !pages.loading && pages.error && (
				<StatusBox
					style={{ marginTop: '2rem' }}
					action={{ callback: getPages, title: 'Try again' }}
					title="There was an error loading the pages"
					content="We didn't find any  pages for this app. Try again or contact us at support@†oecho.dk"
				/>
			)}

			{/* Pages present */}
			{pages.pages.length > 0 && !pages.error && (
				<div className={`${iconsContainer(appConfig)} frontpage-navigation`}>
					{pages.pages
						.filter(el => el.showAtFrontPage)
						.map((page, index) => (
							<div
								data-test-id={`frontpage-icon__${page.module}`}
								onClick={() => pageNavigator(linkPathCreator(page, 'absolute'), 'forward')}
								className={icons()}
								key={index}>
								{iconConverter(page.icon)}
								{page.title}
								{page.notifications > 0 && (
									<Notification
										style={{
											position: 'absolute',
											top: '50%',
											left: '50%',
											transform: 'translate(9px, -50px)'
										}}
										notifications={page.notifications}
									/>
								)}
							</div>
						))}
				</div>
			)}
		</Page>
	);
};

const header = () => css`
	flex: 0 0 70px;
	display: flex;
	z-index: 0;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15);
`;

const iconsContainer = appConfig => css`
	flex: 0 1 100%;
	overflow-y: auto;
	overflow-x: hidden;
	background-image: url(${getBackgroundImageURL(appConfig)});
	background-size: cover;
	background-position: center;
	background-color: ${appConfig.primaryColor};
`;

const icons = () => css`
	position: relative;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 33.333%;
	height: 25%;
	padding: 0.5rem;
	vertical-align: top;
	font-size: 0.8rem;
	color: ${colors.white};
	text-align: center;
	text-decoration: none;
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	border-bottom: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};

	/* First column */
	&:nth-child(3n + 1) {
		border-right: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};
	}
	/* Second column */
	&:nth-child(3n + 2) {
		border-right: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};
	}

	svg {
		margin-bottom: 0.5rem;
		width: 2rem;
		height: 2rem;
		filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
	}

	svg.placeholder-icon {
		width: 2.25rem;
		height: 2.25rem;
		animation: blink 1.5s ease infinite;
		opacity: 0.3;
	}

	.fake-title {
		width: 65px;
		height: 7px;
		border-radius: 5px;
		margin-top: 7px;
		background-color: rgba(255, 255, 255, 0.6);
		animation: blink 1.5s ease infinite;
		opacity: 0.3;
	}

	@keyframes blink {
		0% {
			opacity: 0.3;
		}
		35% {
			opacity: 0.3;
		}
		55% {
			opacity: 0.7;
		}
		100% {
			opacity: 0.3;
		}
	}
`;

const getBackgroundImageURL = appConfig =>
	`${appConfig.frontPageImage.baseURL}b_black,o_50,h_800,q_auto,f_auto/${appConfig.frontPageImage.image}`;

export default memo(FrontPage);
