import React from "react";
import { css } from "emotion";
import PropTypes from "prop-types";
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";

/** Used for displaying content in a tablelike manner
 * It was developed to have a component that could show a users points, and from where those came.
 * But could in reality be used for any data needed this layout.
 * 
 * ```
 * <TableList
            style={{ marginTop: "1rem" }}
            headerLeft={"Date"}
            headerCenter={"Activity"}
            headerRight={"Points"}
            values={[
              ...pointlog.map(value => ({
                left: <p className="meta">{parseDate(value.date)}</p>,
                center: value.description,
                right: value.points
              })),
              {
                left: "",
                center: <p style={{ fontWeight: 700, textAlign: "right", marginTop: "0.5rem" }}>Total:</p>,
                right: (
                  <p style={{ fontWeight: 700, textAlign: "right", marginTop: "0.5rem" }}>
                    {pointlog.length > 0 && pointlog.map(e => e.points).reduce((total, value) => total + value)}
                  </p>
                )
              }
            ]}
          />
 * ```
 * PLEASE NOTE
 * ```
 *  the values passed to the components structure is very important, otherwise, they won't show up as intended
 * ```
 * 
 */

const TableList = props => {
  let result = (
    <table className={style()} style={props.style}>
      <thead colSpan="3">
        <tr className="t-header">
          <th>{props.headerLeft}</th>
          <th>{props.headerCenter}</th>
          <th>{props.headerRight}</th>
        </tr>
      </thead>
      <tbody>
        {props.values.map((value, valueKey) => (
          <tr className="t-content" key={valueKey}>
            <td className="left">{value.left}</td>
            <td className="center">{value.center}</td>
            <td className="right">{value.right}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  return result;
};

const basePadding = 0.5;

const style = primaryColor => css`
  width: 100%;
  background-color: ${colors.white};
  border-collapse: collapse;
  color: ${colors.black};

  @media screen and (min-width: ${breakpoints.lg}px) {
    width: ${breakpoints.lg}px;
    margin: 0 auto;
    border-radius: 3px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

    thead tr {
      border-top-width: 0px !important;
    }
  }

  th,
  td {
    padding: ${basePadding}rem ${basePadding * 2}rem;
  }

  thead th {
    padding: ${basePadding * 2}rem ${basePadding * 2}rem;
  }

  tbody tr:first-child td {
    padding-top: ${basePadding * 2}rem;
  }

  tbody tr:last-child td {
    padding-bottom: ${basePadding * 2.5}rem;
  }

  thead tr {
    border-top: 1px solid ${colors.midGrey};
    th {
      text-align: left;
      :last-child {
        text-align: right;
      }
    }
  }

  tbody tr {
    &:nth-child(even) {
      background-color: ${colors.ultraLightGrey};
    }

    border-bottom: 1px ${colors.lightGrey} solid;

    td {
      white-space: initial;
    }

    td.left {
      white-space: nowrap;
    }
    td.center {
      width: 100%;
    }
    td.right {
      font-weight: 700;
    }
  }

  tr {
    :last-child:after {
      content: "";
      border: none;
    }
  }

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    :last-child {
      text-align: right;
    }
  }
`;

TableList.propTypes = {
  /** Header Title for elements in the left side */
  headerleft: PropTypes.string,
  /** Header Title for elements in the middle */
  headerCenter: PropTypes.string,
  /** Header Title for elements in the right side */
  headerRight: PropTypes.string,
  /** Array of values to each position, a value has to be: {left: XX, center: YY, right: ZZ} */
  values: PropTypes.array
};

export default TableList;
