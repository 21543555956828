import React, { Component } from 'react';
import { parse, format, addDays } from 'date-fns';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, message, notification } from 'antd';
import params from 'jquery-param';

import req from '../../utilities/request-utility';

// css
import { css } from 'emotion';
import colors from '../../style/colors';
import { CloseIcon, InformationOutlineIcon } from 'mdi-react';

// actions
import { hideModalPage } from '../../actions/uiActions';

// ui-components
import ScrollView from '../ui/ScrollView';
import Page from '../ui/Page';
import TextInput from '../ui/TextInput';
import DropDown from '../ui/DropDown';
import DatePicker from '../ui/DatePicker';
import TextAreaInput from '../ui/TextareaInput';
import Button from '../ui/Button';
import common from '../../style/common';
import zeroPad from '../../utilities/zero-pad';
import InlineSpinner from '../ui/InlineSpinner';
import StatusBox from '../ui/StatusBox';
import CheckboxGroup from '../ui/CheckboxGroup';

const rotationStatuses = {
	noChange: 'NO_CHANGE',
	beforePlan: 'BEFORE_PLAN_ROTATION',
	startPlanned: 'START_PLANNED_ROTATION',
	startPlannedNightShift: 'START_PLANNED_ROTATION_NIGHT_SHIFT',
	endOfRotation: 'END_OF_ROTATION',
	changeShift: 'CHANGE_SHIFT',
	preRotationDayShift: 'PRE_ROTATION_DAY_SHIFT',
	preRotationNightShift: 'PRE_ROTATION_NIGHT_SHIFT',
	oneDayCourse: 'ONE_DAY_COURSE',
	startMultiDayCourse: 'START_MULTI_DAY_COURSE',
	course: 'COURSE',
	endOfMultiDayCourse: 'END_OF_MULTI_DAY_COURSE'
};

class WorkTimeRegistration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// Formdata
			formData: {
				location: undefined, //offshore/onshore
				project: undefined,
				subProject: undefined,
				projectActivity: undefined,
				activities: [],
				description: '',
				date: undefined,
				startTimeHours: '00',
				startTimeMinutes: '00',
				endTimeHours: '00',
				endTimeMinutes: '00',
				rotationStatus: rotationStatuses.noChange,
				absenceNightTime: false,
				midRotation: false,
				courses: false
			},

			// Api data
			projects: [],
			subProjects: [],
			projectActivities: [],
			wageCodes: [],
			loadingWageCodes: true,

			// Local state
			isProjectActivitiesEmpty: false,
			selectedActivity: null,
			isFormReady: true,
			submittingForm: false,
			editMode: false,
			errorLoadingFormData: false,
			// wageCodesCourses: ["315", "301", "320"],
			wageCodesNightTime: ['311', '313', '352', '362'],
			midRotationStatuses: [
				'NO_CHANGE',
				'START_PLANNED_ROTATION',
				'START_PLANNED_ROTATION_NIGHT_SHIFT',
				'END_OF_ROTATION'
			],
			courseRotations: ['ONE_DAY_COURSE', 'START_MULTI_DAY_COURSE', 'COURSE', 'END_OF_MULTI_DAY_COURSE']
		};
	}

	componentDidMount = () => {
		let { updateRegistration } = this.props;
		// EDIT MODE
		if (updateRegistration) {
			this.initializeFormForEdit();
			this.setState({ editMode: true });

			// ADD MODE
		} else {
			let date = format(new Date(), 'yyyy-MM-dd');
			this.setState({ formData: { ...this.state.formData, date } }); // Default date to today (nice detail)

			// Initialize data-fetching
			this.getDefaultProjectSubProjectAndActivity().then(this.getProjects);
			// this.getWageCodes();
			this.getWageCodesV2();

			// Gets last used time-item in local storage (uses whatever the user entered last)
			let lastUsedTime = localStorage.getItem('semcotime-last-time-input-norway');
			if (lastUsedTime) {
				lastUsedTime = JSON.parse(lastUsedTime);
				this.setState(state => ({
					formData: {
						...state.formData,
						startTimeHours: lastUsedTime.startTimeHours,
						startTimeMinutes: lastUsedTime.startTimeMinutes,
						endTimeHours: lastUsedTime.endTimeHours,
						endTimeMinutes: lastUsedTime.endTimeMinutes
					}
				}));
			}
		}
	};

	async initializeFormForEdit() {
		const { registration, fetchDate, selectedUser } = this.props;

		try {
			this.setState({ isFormReady: false });
			let { data } = await req()(
				`semcotime/work-hours/v3/${
					registration.timeCardTransactionId || registration.incrementTransactionId
				}?${params({
					date: fetchDate,
					externalId: selectedUser
				})}`
			);

			await this.getProjects();
			//temp solution
			const aaa = this.state.projects.filter(d => d.name === registration.registrations[0].project)[0];
			this.setState({ formData: { ...this.state.formData, project: aaa.id } });
			await this.getSubProjects();
			this.setState({
				formData: { ...this.state.formData, subProject: registration.registrations[0].subProjectId }
			});
			await this.getProjectActivities();
			this.setState({ formData: { ...this.state.formData, projectActivity: data.activitySequence } });
			// await this.getWageCodes();
			await this.getWageCodesV2();

			this.setState({
				formData: {
					...this.state.formData,
					date: format(parse(data.date, 'yyyyMMdd', 0), 'yyyy-MM-dd'),
					startTimeHours:
						data.startTime === 'Invalid date'
							? ''
							: format(parse(data.startTime, 'yyyyMMddHHmmss', 0), 'HH'),
					startTimeMinutes:
						data.startTime === 'Invalid date'
							? ''
							: format(parse(data.startTime, 'yyyyMMddHHmmss', 0), 'mm'),
					endTimeHours:
						data.endTime === 'Invalid date' ? '' : format(parse(data.endTime, 'yyyyMMddHHmmss', 0), 'HH'),
					endTimeMinutes:
						data.endTime === 'Invalid date' ? '' : format(parse(data.endTime, 'yyyyMMddHHmmss', 0), 'mm'),
					description: data.description,
					transactionId: data.transactionId,
					rotationStatus: this.getRotationStatusFromExistingData(data),
					courses: data.courses,
					activities: data.extras.map(extra => {
						const wageCode = this.state.wageCodes.find(d => d.id === extra.wageCode);
						return {
							id: extra.wageCode,
							name: wageCode ? wageCode.name : extra.wageCode,
							hours: extra.hours,
							transactionId: extra.transactionId
						};
					}),
					absenceNightTime: data.absenceNightTime,
					midRotation: data.midRotation
				}
			});

			this.setState({ isFormReady: true });
		} catch (err) {
			console.log('err: ', err.response);
			this.setState({ errorLoadingFormData: true });
		}
	}

	getRotationStatusFromExistingData(apiData) {
		if (apiData.endOfRotation === true) return rotationStatuses.endOfRotation;
		else if (apiData.firstDay === true) return rotationStatuses.startPlanned;
		else if (apiData.firstDayNightShift === true) return rotationStatuses.startPlannedNightShift;
		else if (apiData.preRotation === true) return rotationStatuses.beforePlan;
		else if (apiData.changeShift === true) return rotationStatuses.changeShift;
		else if (apiData.preRotationDayShift) return rotationStatuses.preRotationDayShift;
		else if (apiData.preRotationNightShift) return rotationStatuses.preRotationNightShift;
		else if (apiData.oneDayCourse === true) return rotationStatuses.oneDayCourse;
		else if (apiData.startMultiDayCourse === true) return rotationStatuses.startMultiDayCourse;
		else if (apiData.course === true) return rotationStatuses.course;
		else if (apiData.endOfMultiDayCourse === true) return rotationStatuses.endOfMultiDayCourse;
		else return rotationStatuses.noChange;
	}

	getProjects = () => {
		const { selectedUser } = this.props;

		return new Promise((resolve, reject) => {
			req()(`semcotime/projects?${params({ externalId: selectedUser })}`)
				.then(res => res.data)
				.then(projects => this.setState({ projects }))
				.then(resolve)
				.catch(err => {
					reject();
				});
		});
	};

	getSubProjects = () => {
		return new Promise((resolve, reject) => {
			req()(`semcotime/projects/${this.state.formData.project}/sub-projects`)
				.then(res => res.data)
				.then(subProjects => this.setState({ subProjects }))
				.then(resolve)
				.catch(err => {
					reject(err);
				});
		});
	};

	getProjectActivities = () => {
		this.setState({ isProjectActivitiesEmpty: false });
		let { project, subProject, date } = this.state.formData;

		return new Promise((resolve, reject) => {
			req()(
				`semcotime/projects/${project}/sub-projects/${subProject}/activities?${params({
					date: date ? date : format(new Date(), 'yyyy-MM-dd')
				})}`
			)
				.then(res => res.data)
				.then(projectActivities => {
					if (projectActivities.length === 0) {
						this.setState({ isProjectActivitiesEmpty: true });
					}
					this.setState({ projectActivities });
				})
				.then(resolve)
				.catch(err => {
					reject(err);
				});
		});
	};

	// getWageCodes = () => {
	// 	const { selectedUser } = this.props;

	// 	return new Promise((resolve, reject) => {
	// 		req()(`semcotime/wage-codes?${params({ externalId: selectedUser, wageType: 'increment' })}`)
	// 			.then(res => res.data)
	// 			.then(wageCodes => {
	// 				this.setState({ wageCodes, loadingWageCodes: false });
	// 				resolve();
	// 			})
	// 			.catch(err => {
	// 				reject(err);
	// 			});
	// 	});
	// };

	getWageCodesV2 = () => {
		const { selectedUser } = this.props;

		return new Promise((resolve, reject) => {
			req()(`semcotime/wage-codes/matrix?${params({ externalId: selectedUser, wageType: 'increment' })}`)
				.then(res => res.data)
				.then(wageCodes => {
					if (wageCodes.length === 0) {
						notification.error({
							duration: 7,
							message: 'No Wage Codes Available',
							description: 'No matching wage code, please check Employee details'
						});
						return this.setState({ loadingWageCodes: false });
					}

					this.setState({ wageCodes, loadingWageCodes: false });
					resolve();
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	getDefaultProjectSubProjectAndActivity = () => {
		const { selectedUser } = this.props;

		return new Promise((resolve, reject) => {
			req()(`semcotime/projects/default-project-sub-project-and-activity?${params({ externalId: selectedUser })}`)
				.then(async ({ data }) => {
					// No default project for current user
					if (!data.projectId) {
						resolve();
						return;
					}

					await this.getProjects();
					// 1. set project id
					this.setState({ formData: { ...this.state.formData, project: data.projectId } });
					// 2. Fetch subProjects for given project id
					await this.getSubProjects();
					// 3. Set subProjectId
					this.setState({ formData: { ...this.state.formData, subProject: data.subProjectId } });
					// 4. Fetch project-activities
					await this.getProjectActivities();
					// 5. Set project-activitiy
					this.setState({ formData: { ...this.state.formData, projectActivity: data.activitySequence } });
				})
				.catch(err => {
					resolve();
				});
		});
	};

	handleTimeInput = (e, type) => {
		// Exception for deleing
		if (e.target.value === '') {
			this.setState({
				formData: {
					...this.state.formData,
					[e.target.name]: e.target.value
				}
			});
		}

		let num = parseInt(e.target.value);

		if (
			// If not a number
			isNaN(num) ||
			// If length is more than two digits
			e.target.value.length > 2 ||
			// type is hours and value is not between 0-23
			(type === 'hours' && (num < 0 || num > 23)) ||
			// type is minutes and value is not between 0-59
			(type === 'minutes' && (num < 0 || num > 59))
		) {
			return;
		}

		this.setState({
			formData: {
				...this.state.formData,
				[e.target.name]: e.target.value
			}
		});
	};

	handleFormChange = e => {
		let { formData, wageCodesNightTime } = this.state;
		const defaultTimeStamp = {
			endTimeHours: '00',
			endTimeMinutes: '00',
			startTimeHours: '00',
			startTimeMinutes: '00'
		};

		// Handling of absence night
		if (e.absenceNightTime) {
			this.setState({
				formData: {
					...formData,
					absenceNightTime: e.absenceNightTime.length !== 0 ? true : false
					// activities: formData.activities.filter((d) => wageCodesNightTime.includes(d.id)),
					// ...defaultTimeStamp
				},
				selectedActivity: null
			});
		}

		if (e.midRotation) {
			this.setState({
				formData: {
					...formData,
					midRotation: e.midRotation.length !== 0 ? true : false,
					rotationStatus: 'NO_CHANGE'
				}
			});
		}

		// Handling of Courses
		if (e.courses) {
			const courses = e.courses.length !== 0 ? true : false;

			this.setState({
				selectedActivity: null,
				formData: {
					...formData,
					courses,
					rotationStatus: courses ? 'COURSE' : 'NO_CHANGE',
					...defaultTimeStamp
				}
			});
		}

		// Handling of date
		if (e.date) {
			this.setState({
				formData: {
					...formData,
					date: e.date
					// projectActivity: undefined,
				}
			});

			return;
		}

		if (e.persist) e.persist();

		// Handling of rest
		if (e.target) {
			this.setState(
				{
					formData: {
						...formData,
						[e.target.name]: e.target.value
					}
				},
				() => {
					// Update formData variable (prevents stale state)
					formData = this.state.formData;

					// If a project is chosen, remove potentially chosen subProjects and projectActivites.
					// Both in the formData object, but also in the api-responses
					if (e.target.name === 'project') {
						this.setState(
							{
								subProjects: [],
								projectActivities: [],
								formData: {
									...formData,
									subProject: undefined,
									projectActivity: undefined
								}
							},
							this.getSubProjects
						);
					}

					// The same as above, but only with subProject
					if (e.target.name === 'subProject') {
						this.setState(
							{
								projectActivities: [],
								formData: {
									...formData,
									projectActivities: undefined
								}
							},
							this.getProjectActivities
						);
					}
				}
			);
		}
	};

	isFormValid = () => {
		let { formData } = this.state;

		// Cases hvor form er valid:
		// -------------------------
		// 1) For alle cases skal projekt, underprojekt, aktivitet og dato skal være udfyldt.
		// 2) Hvis der ikke er valgt en aktivitet skal både start- og sluttidspunkt være udfyldt
		// 3) Hvis der er valgt mindst en aktivitet, men ingen start og sluttidspunkt
		// 4) Hvis der er valgt mindst en aktivtet og både start og sluttidspunkt

		// 1)
		if (!formData.project || !formData.subProject || !formData.date) {
			notification.error({
				duration: 7,
				message: 'FAILED',
				description: 'Du mangler at vælge entet projekt, underprojekt eller dato'
			});
			return false;
		}

		// if (!formData.absenceNightTime) { removed condition if NIGHT is checked
		// 2)
		if (
			formData.activities.length === 0 &&
			(!formData.startTimeHours ||
				!formData.startTimeMinutes ||
				!formData.endTimeHours ||
				!formData.endTimeMinutes)
		) {
			notification.error({
				duration: 7,
				message: 'FAILED',
				description: 'Du mangler start- og/eller sluttidspunkt.'
			});
			return false;
		}

		// 4)
		if (
			formData.activities.length > 0 &&
			(formData.startTimeHours ||
				formData.startTimeMinutes ||
				formData.endTimeHours ||
				formData.endTimeMinutes) &&
			!(formData.startTimeHours && formData.startTimeMinutes && formData.endTimeHours && formData.endTimeMinutes)
		) {
			notification.error({
				duration: 7,
				message: 'FAILED',
				description:
					'Du har tilføjet et tillæg og kun udfyldt start- eller sluttidspunkt delvist. Du kan enten udfylde både start- og sluttidspunkt, slette værdierne fra start- og sluttidspunkt eller fjerne dit tillæg.'
			});
			return false;
		}

		if (formData.courses === true && formData.activities.length === 0) {
			notification.error({
				duration: 7,
				message: 'FAILED',
				description: 'Lønnskode 301, 315 eller 320 er nødvendig for kurs'
			});
			return false;
		}

		return true;
	};

	getStartAndEndTimeAndDate() {
		let { formData } = this.state;

		let date = format(parse(formData.date, 'yyyy-MM-dd', 0), 'yyyyMMdd');

		let startTime = `${zeroPad(formData.startTimeHours)}${zeroPad(formData.startTimeMinutes)}`;
		let endTime = `${zeroPad(formData.endTimeHours)}${zeroPad(formData.endTimeMinutes)}`;

		// If registration stretch across midnight offset date for endTime by one
		if (startTime > endTime) {
			startTime = `${date}${zeroPad(formData.startTimeHours)}${zeroPad(formData.startTimeMinutes)}00`;
			endTime = `${format(addDays(parse(formData.date, 'yyyy-MM-dd', 0), 1), 'yyyyMMdd')}${zeroPad(
				formData.endTimeHours
			)}${zeroPad(formData.endTimeMinutes)}00`;
		} else {
			startTime = `${date}${zeroPad(formData.startTimeHours)}${zeroPad(formData.startTimeMinutes)}00`;
			endTime = `${date}${zeroPad(formData.endTimeHours)}${zeroPad(formData.endTimeMinutes)}00`;
		}

		// Handle when user hasn't entered start or end time (which is actually ok?)
		if (!formData.startTimeHours || !formData.startTimeMinutes) startTime = '';
		if (!formData.endTimeHours || !formData.endTimeMinutes) endTime = '';

		return { startTime, endTime, date };
	}

	handleSave = ({ copyNextDay = false }) => {
		let { addRegistration, hideModalPage, lang } = this.props;
		let { formData } = this.state;

		// eslint-disable-next-line
		if (this.isFormValid()) {
			this.setState({ submittingForm: true });
			let { startTime, endTime, date } = this.getStartAndEndTimeAndDate();
			addRegistration({
				date,
				startTime,
				endTime,
				project: formData.project,
				subProject: formData.subProject,
				description: formData.description,
				activitySequence: formData.projectActivity,
				extras: formData.activities.map(({ id, hours }) => ({ wageCode: id, hours })),
				preRotation: formData.rotationStatus === rotationStatuses.beforePlan,
				firstDay: formData.rotationStatus === rotationStatuses.startPlanned,
				firstDayNightShift: formData.rotationStatus === rotationStatuses.startPlannedNightShift,
				endOfRotation: formData.rotationStatus === rotationStatuses.endOfRotation,
				changeShift: formData.rotationStatus === rotationStatuses.changeShift,
				preRotationDayShift: formData.rotationStatus === rotationStatuses.preRotationDayShift,
				preRotationNightShift: formData.rotationStatus === rotationStatuses.preRotationNightShift,
				absenceNightTime: formData.absenceNightTime,
				midRotation: formData.midRotation,
				courses: formData.courses,
				oneDayCourse: formData.rotationStatus === rotationStatuses.oneDayCourse,
				startMultiDayCourse: formData.rotationStatus === rotationStatuses.startMultiDayCourse,
				course: formData.rotationStatus === rotationStatuses.course,
				endOfMultiDayCourse: formData.rotationStatus === rotationStatuses.endOfMultiDayCourse
			})
				.then(() => {
					// Saves last used item in local storage
					localStorage.setItem(
						'semcotime-last-time-input-norway',
						JSON.stringify({
							startTimeHours: formData.startTimeHours,
							startTimeMinutes: formData.startTimeMinutes,
							endTimeHours: formData.endTimeHours,
							endTimeMinutes: formData.endTimeMinutes
						})
					);

					if (!copyNextDay) {
						notification.success({
							duration: 7,
							message: 'SUCCESS',
							description: lang.savedRegistrationToastContent
						});

						return hideModalPage();
					}

					// Copy next day
					let nextDay = addDays(parse(date, 'yyyyMMdd', 0), 1);
					let nextDayFormatted = format(nextDay, 'yyyy-MM-dd');

					this.setState({
						formData: {
							...formData,
							date: nextDayFormatted
						},
						submittingForm: false
					});

					notification.success({
						duration: 7,
						message: 'SUCCESS',
						description: `${lang.savedRegistrationToastContent}, The day is now ${nextDayFormatted}`
					});
				})
				.catch(err => {
					let errorMessage = 'Unspecified error';
					if (err && err.response && err.response.data) errorMessage = err.response.data.message;

					notification.error({
						duration: 7,
						message: 'FAILED',
						description: `${lang.errorSavingRegistrationToastContent}. Fejlkode: ${errorMessage}`
					});

					this.setState({ submittingForm: false });
				});
		}
	};

	handleUpdate = () => {
		let { updateRegistration, hideModalPage, lang } = this.props;
		let { formData } = this.state;

		// eslint-disable-next-line
		if (this.isFormValid()) {
			this.setState({ submittingForm: true });
			let { startTime, endTime, date } = this.getStartAndEndTimeAndDate();

			let registration = {
				date,
				startTime,
				endTime,
				project: formData.project,
				subProject: formData.subProject,
				description: formData.description,
				activitySequence: formData.projectActivity,
				transactionId: formData.transactionId,
				extras: formData.activities.map(({ id, hours, transactionId = -1 }) => ({
					wageCode: id,
					hours,
					transactionId
				})),
				absenceNightTime: formData.absenceNightTime,
				midRotation: formData.midRotation,
				courses: formData.courses
			};

			// If user hos chosen nothing, not update these values
			if (formData.rotationStatus !== rotationStatuses.noChange) {
				registration = {
					...registration,
					preRotation: formData.rotationStatus === rotationStatuses.beforePlan,
					firstDay: formData.rotationStatus === rotationStatuses.startPlanned,
					firstDayNightShift: formData.rotationStatus === rotationStatuses.startPlannedNightShift,
					endOfRotation: formData.rotationStatus === rotationStatuses.endOfRotation,
					changeShift: formData.rotationStatus === rotationStatuses.changeShift,
					preRotationDayShift: formData.rotationStatus === rotationStatuses.preRotationDayShift,
					preRotationNightShift: formData.rotationStatus === rotationStatuses.preRotationNightShift,
					oneDayCourse: formData.rotationStatus === rotationStatuses.oneDayCourse,
					startMultiDayCourse: formData.rotationStatus === rotationStatuses.startMultiDayCourse,
					course: formData.rotationStatus === rotationStatuses.course,
					endOfMultiDayCourse: formData.rotationStatus === rotationStatuses.endOfMultiDayCourse
				};
			}

			updateRegistration(registration)
				.then(() => {
					notification.success({
						duration: 7,
						message: 'SUCCESS',
						description: lang.savedRegistrationToastContent
					});
					hideModalPage();
				})
				.catch(err => {
					let errorMessage = 'Unspecified error';
					if (err && err.response && err.response.data) errorMessage = err.response.data.message;

					notification.error({
						duration: 7,
						message: 'FAILED',
						description: `${lang.errorSavingRegistrationToastContent}. Fejlkode: ${errorMessage}`
					});

					this.setState({ submittingForm: false });
				});
		} else {
			notification.error({
				duration: 7,
				message: 'FAILED',
				description: 'Du skal udfylde projekt, underprojekt, aktivitet, dato, og start- og sluttid'
			});
		}
	};

	getProjectActivityPlaceholder() {
		let { lang } = this.props;
		let { isProjectActivitiesEmpty, projectActivities } = this.state;

		if (isProjectActivitiesEmpty === true) {
			return lang.noActivities;
		} else if (projectActivities.length === 0 && isProjectActivitiesEmpty === false) {
			return lang.loading;
		} else {
			return lang.chooseActivity;
		}
	}

	render() {
		const { lang, selectedUserName } = this.props;
		const {
			formData,
			projects,
			subProjects,
			projectActivities,
			wageCodes,
			isFormReady,
			loadingWageCodes,
			errorLoadingFormData,
			editMode,
			submittingForm,
			// wageCodesCourses,
			wageCodesNightTime,
			courseRotations,
			midRotationStatuses
		} = this.state;

		if (!isFormReady && !errorLoadingFormData) {
			return (
				<Page className={this.componentStyles()}>
					<InlineSpinner style={{ marginTop: '1rem' }} />
				</Page>
			);
		}

		if (errorLoadingFormData) {
			return (
				<Page className={this.componentStyles()}>
					<StatusBox
						title="Error loading data"
						content="The system were unable to get data for this record. Please try again later or contact an administrator"
					/>
				</Page>
			);
		}

		return (
			<Page className={this.componentStyles()}>
				<ScrollView>
					<div className="content-wrapper">
						{selectedUserName ? (
							<div className="title-container">
								<h2>{lang.general}</h2>
								<span>{selectedUserName}</span>
							</div>
						) : (
							<h2>{lang.general}</h2>
						)}

						{/* Project */}
						<p>{lang.project}</p>
						<DropDown
							name="project"
							onChange={this.handleFormChange}
							options={projects.map(d => ({ label: `${d.id} - ${d.name}`, value: d.id }))}
							placeholder={lang.chooseProject}
							value={formData.project}
						/>

						{/* Sub project (depends on project) */}
						{formData.project && (
							<>
								<p>{lang.subproject}</p>
								<DropDown
									name="subProject"
									onChange={this.handleFormChange}
									options={subProjects.map(d => ({
										label: `${d.id} - ${d.description}`,
										value: d.id
									}))}
									placeholder={lang.chooseSubproject}
									value={formData.subProject}
								/>
							</>
						)}

						{/* Activity (depends on project and subProject) */}
						{formData.project && formData.subProject && (
							<>
								<p>{lang.activity}</p>
								<DropDown
									name="projectActivity"
									onChange={this.handleFormChange}
									options={projectActivities.map(d => ({
										label: `${d.id} - ${d.description}`,
										value: d.id
									}))}
									placeholder={this.getProjectActivityPlaceholder()}
									value={formData.projectActivity}
								/>
							</>
						)}

						{/* Description */}
						<p>{lang.description}</p>
						<TextAreaInput
							name="description"
							value={formData.description}
							onChange={this.handleFormChange}
						/>

						<div style={{ display: 'flex' }}>
							{/* Mid Rotation */}
							{!formData.courses && (
								<div className={'absenceNightTimeContainer'} style={{ marginRight: '10px' }}>
									<CheckboxGroup
										onSelect={e => this.handleFormChange({ midRotation: e })}
										options={[{ id: 'midRotation', title: lang.midRotation }]}
										selected={formData.midRotation ? ['midRotation'] : []}
									/>
								</div>
							)}

							<br />

							{/* Courses */}
							{!formData.midRotation && (
								<div className={'absenceNightTimeContainer'}>
									<CheckboxGroup
										onSelect={e => this.handleFormChange({ courses: e })}
										options={[{ id: 'courses', title: lang.courses }]}
										selected={formData.courses ? ['courses'] : []}
									/>
								</div>
							)}
						</div>

						{/* Rotation status */}
						<h2 style={{ marginBottom: '0.75rem' }}>{lang.rotationStatus}</h2>
						<DropDown
							name="rotationStatus"
							onChange={this.handleFormChange}
							options={(() => {
								let rotationStatusList = [
									{
										value: rotationStatuses.noChange,
										label: `${
											formData.midRotation
												? lang.midRotationNoChangeStatus
												: lang.noChangeInRotationStatus
										}`
									},

									{ value: rotationStatuses.preRotationDayShift, label: lang.preRotationDay },
									{ value: rotationStatuses.preRotationNightShift, label: lang.preRotationNight },

									{
										value: rotationStatuses.startPlanned,
										label: `${
											formData.midRotation
												? lang.midRotationFirstDayDay
												: lang.firstDayPlanRotation
										}`
									},
									{
										value: rotationStatuses.startPlannedNightShift,
										label: `${
											formData.midRotation
												? lang.midRotationFirstDayNight
												: lang.firstDayPlanRotationNightShift
										}`
									},
									{
										value: rotationStatuses.endOfRotation,
										label: `${formData.midRotation ? lang.midRotationLastDay : lang.endOfRotation}`
									},

									{ value: rotationStatuses.oneDayCourse, label: lang.oneDayCourse },
									{ value: rotationStatuses.startMultiDayCourse, label: lang.startMultiDayCourse },
									{ value: rotationStatuses.course, label: lang.courseStatus },
									{ value: rotationStatuses.endOfMultiDayCourse, label: lang.endOfMultiDayCourse },
									{ value: rotationStatuses.changeShift, label: lang.changeShift }
								];

								if (formData.midRotation)
									rotationStatusList = rotationStatusList.filter(d =>
										midRotationStatuses.includes(d.value)
									);

								rotationStatusList = rotationStatusList.filter(d =>
									formData.courses
										? courseRotations.includes(d.value)
										: !courseRotations.includes(d.value)
								);

								return rotationStatusList;
							})()}
							placeholder={this.getProjectActivityPlaceholder()}
							value={formData.rotationStatus}
						/>

						{/* Date */}
						<h2>{lang.dateAndTime}</h2>

						{this.state.editMode ? (
							<p className="fake-input">
								Date: <span style={{ color: colors.darkGrey }}>{formData.date}</span>
							</p>
						) : (
							<>
								<p>{lang.date}</p>
								<DatePicker
									name="date"
									value={formData.date}
									onDateChange={date => {
										this.handleFormChange({ date });
										this.getProjectActivities();
									}}
								/>
							</>
						)}

						{!formData.courses && (
							<>
								{/* Start time hours */}
								<p>{lang.startTime}</p>
								<div className="time-input">
									<TextInput
										name="startTimeHours"
										type="number"
										value={formData.startTimeHours}
										placeholder={lang.hours}
										onChange={e => this.handleTimeInput(e, 'hours')}
									/>

									{/* Start time minutes */}
									<p style={{ padding: '0 0.5rem' }}>{` : `}</p>
									<TextInput
										name="startTimeMinutes"
										type="number"
										value={formData.startTimeMinutes}
										placeholder={lang.minutes}
										onChange={e => this.handleTimeInput(e, 'minutes')}
									/>
								</div>
								{/* End time hours */}
								<p>{lang.endTime}</p>
								<div className="time-input">
									<TextInput
										type="number"
										name="endTimeHours"
										value={formData.endTimeHours}
										placeholder={lang.hours}
										onChange={e => this.handleTimeInput(e, 'hours')}
									/>

									{/* End time minutes */}
									<p style={{ padding: '0 0.5rem' }}>{` : `}</p>
									<TextInput
										type="number"
										name="endTimeMinutes"
										value={formData.endTimeMinutes}
										placeholder={lang.minutes}
										onChange={e => this.handleTimeInput(e, 'minutes')}
									/>
								</div>
							</>
						)}

						{/* Activities (Wage codes) */}
						<h2>{lang.sc_extra}</h2>
						{loadingWageCodes === false && wageCodes.length === 0 && (
							<p className="meta" style={{ color: colors.darkGrey }}>
								<InformationOutlineIcon
									style={{
										color: colors.darkGrey,
										width: '1.25rem',
										height: '1.25rem',
										marginBottom: '-4px'
									}}
								/>{' '}
								Der er ingen tilgængelige tillæg
							</p>
						)}
						{loadingWageCodes === false && wageCodes.length === 0 ? null : (
							<>
								<p>{lang.addActivities}</p>
								<Input.Group size="large">
									<DropDown
										name="activity"
										onChange={e => this.setState({ selectedActivity: e.target.value })}
										options={(() => {
											let wageCodeList = [];
											wageCodeList = wageCodes
												.filter(d => formData.activities.findIndex(el => el.id === d.id) === -1)
												.map(d => ({
													label: `${d.name} - ${d.id}`,
													value: d.id,
													showInCourses: d.showInCourses
												}));

											if (formData.courses)
												wageCodeList = wageCodeList.filter(d => d.showInCourses === 'YES');

											return wageCodeList;
										})()}
										placeholder={lang.chooseExtras}
										style={{ width: '65%' }}
										value={this.state.selectedActivity}
									/>

									<Button
										style={{ display: 'initial', lineHeight: '13px', width: '35%' }}
										fullWidth={false}
										onClick={() => {
											if (this.state.selectedActivity) {
												let activity = wageCodes.filter(
													x => x.id === this.state.selectedActivity
												)[0];
												activity.hours = '';
												this.setState({
													formData: {
														...formData,
														activities: [activity, ...formData.activities]
													},
													selectedActivity: null
												});
											}
										}}>
										{lang.add}
									</Button>
								</Input.Group>
							</>
						)}

						{formData.activities.map((activity, index) => (
							<div className="extrasContainer">
								<div className="activity" key={index}>
									<p className="name">
										{activity.name} - {activity.id}
									</p>
									<TextInput
										name={activity.name.toLowerCase().replace(/ /g, '')}
										type="number"
										value={activity.hours}
										placeholder={lang.enterHours}
										onChange={e => {
											activity.hours = e.target.value; // Temporay mutation of map-element (is canceled on next render where change is committed)
											this.setState({
												formData: {
													...formData,
													activities: [
														...formData.activities.slice(0, index),
														activity,
														...formData.activities.slice(index + 1)
													]
												}
											});
										}}
									/>
									<div
										onClick={() => {
											this.setState({
												formData: {
													...formData,
													activities: formData.activities.filter(x => activity.id !== x.id)
												}
											});
										}}>
										<CloseIcon />
									</div>
								</div>
								{wageCodesNightTime.includes(activity.id) && (
									<div className={'absenceNightTimeContainer'}>
										<CheckboxGroup
											onSelect={e => this.handleFormChange({ absenceNightTime: e })}
											options={[{ id: 'absenceNightTime', title: 'Night' }]}
											selected={formData.absenceNightTime ? ['absenceNightTime'] : []}
										/>
									</div>
								)}
							</div>
						))}

						<div className="seperator"></div>

						<Button
							active={submittingForm}
							style={{ marginTop: '2rem' }}
							onClick={editMode ? this.handleUpdate : this.handleSave}>
							{lang.save}
						</Button>
						{!editMode && (
							<Button
								active={submittingForm}
								style={{ marginTop: '1rem' }}
								onClick={() => this.handleSave({ copyNextDay: true })}>
								{lang.foremanRegistrationWorkTimeSaveAndCopyToNextDay}
							</Button>
						)}
					</div>
				</ScrollView>
			</Page>
		);
	}

	componentStyles = () => css`
		background-color: white;
		padding-top: ${common.topBarHeight}px;

		.content-wrapper {
			max-width: 800px;
			margin: 0 auto;

			.title-container {
				align-items: center;
				display: flex;
				justify-content: space-between;
			}
		}

		.time-input {
			display: flex;
			align-items: center;
		}

		.scroll-view {
			padding: 0.75rem 0.75rem 5rem 0.75rem;
		}

		p {
			padding: 0.75rem 0 0.5rem 0;
		}

		h2:not(:first-child) {
			border-top: 1px solid ${colors.lightGrey};
			margin: 0.75rem -0.75rem 0 -0.75rem;
			padding: 0.75rem 0.75rem 0 0.75rem;
		}

		.extrasContainer {
			border-top: 1px solid ${colors.lightGrey};
			display: flex;
		}

		.activity {
			flex-grow: 1;
			display: flex;
			align-items: center;
			padding: 0.75rem 0.75rem 0.75rem 0;
			margin: 0 0 0 0rem;

			&:last-of-type {
				border-bottom: 1px solid ${colors.lightGrey};
			}

			.name {
				flex: 1 1 70%;
				padding: 0;
			}

			svg {
				height: 40px; /* WARNING: Magic number */
			}

			input {
				flex: 1 1 2rem;
				margin: 0 0.5rem 0 0.5rem;
			}
		}

		.absenceNightTimeContainer {
			margin-top: 7px;

			.checkbox {
				padding: 5px 7px 5px 5px;
			}
		}

		.flex {
			display: flex;
			justify-content: space-between;
		}

		@media (max-width: 480px) {
			.extrasContainer {
				flex-direction: column;
			}

			.absenceNightTimeContainer {
				padding-bottom: 7px;
				display: flex;
				justify-content: flex-end;
				padding-right: 3%;
			}
		}
	`;
}

const mapStateToProps = state => ({
	lang: state.language.language,
	selectedUser: state.users.selectedUser,
	selectedUserName:
		state.users.lists.length !== 0
			? state.users.lists.find(d => d.externalId === state.users.selectedUser).name
			: null
});

const mapDispatchToProps = dispatch => ({
	hideModalPage: bindActionCreators(hideModalPage, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkTimeRegistration);
