import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { notification, Tabs } from 'antd';
import { useMediaQuery } from 'react-responsive';
// css
import { css } from 'emotion';
import { ArrowBackIcon } from 'mdi-react';
import colors from '../../../style/colors';

import TopBar from '../../ui/TopBar';
import Page from '../../ui/Page';
import getBackButtonUrl from '../../../utilities/get-back-button-url';
import getPageFromId from '../../../utilities/get-page-from-id';
import ActionWrapper from '../../ui/ActionWrapper';
import pageNavigator from '../../../utilities/page-navigator';
import DropDown from '../../ui/DropDown';
import InlineSpinner from '../../ui/InlineSpinner';

import TimesheetRenderModal from './TimesheetSignatureComponents/TimesheetRenderModal';
import TimesheetListItem from './TimesheetSignatureComponents/TimesheetListItem';

// utilities
import req from '../../../utilities/request-utility';

const TimesheetsSigned = props => {
	const pages = useSelector(s => s.pages.pages);
	const loggedInUser = props.user;
	const { id: loggedInUserID, loggedUserTypeDK } = loggedInUser;
	const page = getPageFromId(pages, props.match.params.pageId);
	const backButtonURL = getBackButtonUrl(page);

	const [selectedEmployee, setSelectedEmployee] = useState(loggedUserTypeDK === 'foreman' ? loggedInUserID : null);
	const [selectedTimesheet, setSelectedTimesheet] = useState({});
	const [toggleModal, setToggleModal] = useState(false);

	const lang = useSelector(({ language }) => language.language);

	const isMobile = useMediaQuery({ maxWidth: '414px' });

	const {
		data: { data: foremanTeamMembers = [] } = {},
		isFetching: foremanTeamMembersIsFetching,
		isLoading: foremanTeamMembersIsLoading,
		refetch: foremanTeamMembersRefetch
	} = useQuery(
		['GetForemanTeamMembers'],
		() => {
			if (loggedUserTypeDK === 'admin') return req()(`semcotime/foremen-teams/my-team/all`);

			return req()(`semcotime/foremen-teams/my-team`);
		},
		{
			staleTime: 30 * 1000,
			refetchOnWindowFocus: false,
			disabled: loggedUserTypeDK === 'user'
		}
	);

	const {
		data: { data: allSubmittedData = [] } = {},
		isFetching: allSubmittedIsFetching,
		isLoading: allSubmittedIsLoading,
		refetch: allSubmittedRefetch
	} = useQuery(
		['AllSubmitted', selectedEmployee],
		({ queryKey: [key, selectedEmployee] = [] }) => {
			const blueCollarId = selectedEmployee ? selectedEmployee : loggedInUserID;
			return req()(`semcotime/timesheet/getAllSubmitted/${blueCollarId}`);
		},
		{
			staleTime: 30 * 1000
		}
	);

	console.log('allSubmittedData: ', allSubmittedData);

	return (
		<Page className={componentStyles(props)}>
			<TopBar
				actionLeft={
					<ActionWrapper onClick={() => pageNavigator(backButtonURL, 'backward')}>
						<ArrowBackIcon />
					</ActionWrapper>
				}
				title={page.title}
			/>
			{(loggedUserTypeDK === 'admin' || loggedUserTypeDK === 'foreman') && (
				<div style={isMobile ? { padding: '1rem', width: '100%', margin: 0 } : { margin: '1rem' }}>
					<DropDown
						name="blueCollarId"
						placeholder="Select a User"
						onChange={e => setSelectedEmployee(e.target.value)}
						loading={foremanTeamMembersIsLoading}
						options={
							!foremanTeamMembersIsLoading
								? foremanTeamMembers
										.filter(d => d.externalId)
										.map(d => ({
											label: `${d.name} | Ticket No. ${d.ticketNumber ? d.ticketNumber : 'N/A'}`,
											value: d.id
										}))
								: []
						}
						value={selectedEmployee}
					/>
				</div>
			)}
			<div className="inline-scroll-wrapper">
				<Tabs defaultActiveKey="1">
					<Tabs.TabPane
						tab={
							<>
								{'Submitted Timesheets'}{' '}
								{allSubmittedData.length > 0 ? <>{allSubmittedData.length}</> : ''}
								{(allSubmittedIsLoading || allSubmittedIsFetching) && (
									<InlineSpinner
										size="14"
										style={{ display: 'inline-block', margin: '0 0rem -0.6rem 0.5rem' }}
									/>
								)}
							</>
						}
						key="1">
						<>
							{allSubmittedIsLoading && <InlineSpinner />}

							{!allSubmittedIsLoading && (
								<TimesheetListItem
									sheets={allSubmittedData}
									setSelectedTimesheet={setSelectedTimesheet}
									setToggleModal={setToggleModal}
									employeeView={true}
								/>
							)}
						</>
					</Tabs.TabPane>
				</Tabs>
			</div>

			<TimesheetRenderModal
				employeeView={true}
				setToggleModal={setToggleModal}
				selectedTimesheet={selectedTimesheet}
				toggleModal={toggleModal}
			/>
		</Page>
	);
};

const componentStyles = isUser => css`
	background-color: ${colors.white} !important;

	.inline-scroll-wrapper {
		overflow-x: auto;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		padding: 1rem;

		.react-pdf__Page__canvas {
			margin: 0 auto !important;
		}
	}
`;

export default TimesheetsSigned;
