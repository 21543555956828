import React, { useEffect, useLayoutEffect, useState } from "react";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import usePageAndBackButtonURL from "../../hooks/usePageAndBackButtonURL";
import ActionWrapper from "../ui/ActionWrapper";
import pageNavigator from "../../utilities/page-navigator";
import { ArrowBackIcon, InfoOutlineIcon, PlusIcon } from "mdi-react";
import { css } from "emotion";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import colors from "../../style/colors";
import { parse, format, startOfMonth, endOfMonth } from "date-fns";
import req from "../../utilities/request-utility";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

function WorkTimeTable(props) {
  const { page, backButtonURL } = usePageAndBackButtonURL(props);
  const [showDetails, setShowDetails] = useState(false);
  const [filterDates, setFilterDates] = useState({
    fromDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    toDate: format(endOfMonth(new Date()), "yyyy-MM-dd"),
  });

  const [tableData, setTableData] = useState({
    data: {},
    error: false,
    loading: false,
  });

  function getTableData() {
    if (!filterDates.fromDate || !filterDates.toDate) return;

    setTableData({
      ...tableData,
      loading: true,
      error: false,
    });

    req()(
      `semcotime/work-hours/v3/table-data?fromDate=${filterDates.fromDate.replace(
        /-/g,
        ""
      )}&toDate=${filterDates.toDate.replace(/-/g, "")}`
    )
      .then(({ data }) => {
        setTableData({
          loading: false,
          error: false,
          data,
        });
      })
      .catch((err) => {
        setTableData({
          loading: false,
          error: true,
        });
      });
  }

  function handleDateChange(e) {
    setFilterDates({
      ...filterDates,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line
  }, [filterDates]);

  useLayoutEffect(() => {
    let el = document.getElementById("custom-scroll-wrapper-kasd872jhKsd72");
    disableBodyScroll(el);
    return () => {
      enableBodyScroll(el);
    };
  }, []);

  return (
    <Page className={componentStyles()}>
      <TopBar
        title={page.title}
        actionLeft={
          <ActionWrapper onClick={() => pageNavigator(backButtonURL, "backward")}>
            <ArrowBackIcon />
          </ActionWrapper>
        }
      />
      <div className="date-selector-bar">
        <p>Showing from</p>
        <input value={filterDates.fromDate} type="date" name="fromDate" onChange={handleDateChange} />
        <p>to</p>
        <input value={filterDates.toDate} type="date" name="toDate" onChange={handleDateChange} />
      </div>
      {tableData.loading && !tableData.error && <InlineSpinner style={{ marginTop: "2rem" }} title="Loading content..." />}

      {!tableData.loading && !tableData.error && tableData.data.data && tableData.data.data.length === 0 && (
        <StatusBox
          style={{ marginTop: "2rem" }}
          title="No results"
          content="No results found. Try with different dates"
          icon={<InfoOutlineIcon />}
        ></StatusBox>
      )}

      <div className="inline-scroll-wrapper" id="custom-scroll-wrapper-kasd872jhKsd72">
        {!tableData.loading && !tableData.error && tableData.data.data && tableData.data.data.length > 0 && (
          <table className={showDetails ? "show-details" : "hide-details"}>
            <thead>
              <tr>
                <th>
                  <div className="show-more-button">
                    <div>
                      {/* Show details */}
                      <ActionWrapper onClick={() => setShowDetails(!showDetails)}>
                        <PlusIcon
                          style={{
                            transition: "transform 200ms ease",
                            transform: `rotate(${showDetails ? "45deg" : "0deg"})`,
                          }}
                        />
                        {showDetails && "Hide"}
                        {!showDetails && "Show"} details
                      </ActionWrapper>
                    </div>

                    <p className="fake-total-column">Total</p>
                  </div>
                </th>

                {/* Headers or date */}
                {tableData.data.dates.map((date) => (
                  <th>{format(parse(date, "yyyy-MM-dd", 0), "eee d/M")}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {tableData.data.data.map((row) => (
                <tr>
                  <th>
                    <div className="body-label">
                      <div>
                        {showDetails && (
                          <>
                            <div className="meta-info-wrapper">
                              <span className="meta">Project:</span> {row.project}
                            </div>
                            <div className="meta-info-wrapper">
                              <span className="meta">Sub Project:</span> {row.subProject}
                            </div>
                          </>
                        )}

                        <div className="meta-info-wrapper">{row.reportCode}</div>
                        <div className="meta-info-wrapper">{row.reportCodeDescription}</div>
                      </div>
                      <p className="highlight fake-total-column" style={{ fontWeight: 700 }}>
                        {row.data.reduce((el, acc) => Number(el) + Number(acc), 0)}
                      </p>
                    </div>
                  </th>

                  {row.data.map((dataPoint) => (
                    <th className={dataPoint === 0 ? "no-data" : ""}>{dataPoint}</th>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Page>
  );
}

const componentStyles = () => css`
  .inline-scroll-wrapper {
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .date-selector-bar {
    height: 55px;
    border-bottom: 1px ${colors.midGrey} solid;
    background-color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      margin: 0 0.5rem;
      color: ${colors.darkGrey};
      border: 1px ${colors.darkGrey} dashed;
      border-width: 0 0 1px 0;
      font-size: 1rem;
    }

    @media screen and (max-width: 500px) {
      flex-wrap: wrap;
      font-size: 0.9rem;
      padding: 0.5rem 0;
      height: initial;

      input {
        font-size: 0.9rem;
        flex-basis: 50%;
      }
      p {
        text-align: right;
        flex-basis: 30%;
      }

      p,
      input {
        margin-bottom: 0.25rem;
        flex-shrink: 0;
      }
    }
  }

  table {
    position: relative;
    border-collapse: collapse;
    min-width: 100%;
    min-width: calc(100% + 1px);
    min-height: calc(100% + 1px);

    .fake-total-column {
      width: 40px;
      text-align: center;
      margin-left: 1rem;
      padding-left: 0.3rem;
      height: 100%;
      flex-shrink: 0;
    }

    th.no-data {
      color: ${colors.midGrey};
    }

    .body-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .show-more-button {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;

      svg {
        margin-right: 0.15rem;
      }

      button {
        display: flex;
        align-items: center;
      }
    }

    &.show-details {
      .meta-info-wrapper {
        margin-bottom: 0.3rem;
      }
    }

    &.hide-details {
      .meta-info-wrapper {
        margin-bottom: 0.15rem;
        /* white-space: nowrap; */
      }
    }

    /* All cells */
    td,
    th {
      padding: 0.5em;
      font-size: 0.85rem;
      font-weight: 400;
      color: ${colors.black};
      min-width: 50px;
    }

    /* Cells in header */
    thead th {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: ${colors.ultraLightGrey};
      color: ${colors.darkGrey};
      border-bottom: 1px ${colors.midGrey} solid;
      border-right: 1px ${colors.midGrey} solid;
      box-shadow: 1px 1px 0 0 ${colors.midGrey};

      &:first-child {
        left: 0;
        z-index: 2;
      }
    }

    /* Cells in header */

    tbody th {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      left: 0;
      background: #fff;
      border-bottom: 1px ${colors.midGrey} solid;
      border-right: 1px ${colors.midGrey} solid;

      &:first-of-type {
        z-index: 1;
        text-align: left;
        background-color: ${colors.white};
        box-shadow: 1px 0 0 0 ${colors.midGrey};

        span.meta {
          color: ${colors.darkGrey};
        }
      }
    }
  }
`;

export default WorkTimeTable;
