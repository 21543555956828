import store from "../store";
import history from "./navigation-history";
import getAppName from "./get-app-name";
import req from "./request-utility";

import { setTransitionModeToBackward, setTransitionModeToForward } from "../actions/pagesActions";

export default function(path, direction = "backward") {
  /**
   * Utiltity function to animate transitions between routes
   * -------------------------------------
   * Take two arguments:
   * @argument {string} path Required
   * @argument {string} directon optional (defaults to "forward")
   *
   * It dispatches an action setting the global transition state,
   * and hereafter animates the route transition according to the
   * dispatched state
   *
   * Special options for path:
   *
   * `BACK`
   * The previous route ("pops" a level off from the routes)
   * Example: pageNavigator("BACK", "backward")
   * domain.com/users/12343/likes -> domain.com/users/12343
   *
   * `FRONTPAGE`
   * Goes to the frontpage
   */

  // Set the direction
  if (direction === "forward") {
    store.dispatch(setTransitionModeToForward());
  } else if (direction === "backward") {
    store.dispatch(setTransitionModeToBackward());
  }

  // Replaces double slashes with single (url.com/lorem//ipsum -> url.com/lorem/ipsum)
  path = path.replace(/\/\//gm, "/");

  // Special path arguments
  if (path === "BACK") {
    // Goes up one level in the paths
    path = history.location.pathname
      .split("/")
      .slice(0, -1)
      .join("/");
  }
  //implemented 29/04/2019 by niclas
  //comment: when navigating within a testsheet, we dont have a view for a single task within a jobpackage, so we would get a white screen if we use just BACK.
  //I figured that this functionality might be needed in other places where we don't have the intermediate view, and need to navigate further back than 1 level
  if (path === "DOUBLEBACK") {
    // Goes up two level in the paths
    path = history.location.pathname
      .split("/")
      .slice(0, -2)
      .join("/");
  }

  if (path === "TRIPLEBACK") {
    // Goes up two level in the paths
    path = history.location.pathname
      .split("/")
      .slice(0, -3)
      .join("/");
  }

  // Special path arguments
  if (path === "FRONTPAGE") {
    // Goes to frontpage
    path = `/${getAppName()}/`;
  }

  // Set the direction
  requestAnimationFrame(() => {
    history.replace(path);

    // Log app visit. Logic for duplicated visit-logs is handled in node api
    // It's fine to fire this request as many times a needed. The api will make sure
    // the log wont be registered multiple times. See business rules in Node-api for further details
    // req().post("statistics/app/visits");
  });
}
