import React from 'react';
import { Modal, List, Button, Typography } from 'antd';
import moment from 'moment';

const TimesheetCorrectedModal = props => {
	const { correctedBySemsupportData, isTimesheetSubmitting = false, onGenerateNewTimesheet } = props;

	return (
		<Modal visible={true} title="Signed Timesheets Needing Attention" width="80vw" footer={null} closable={false}>
			<List
				bordered
				dataSource={correctedBySemsupportData}
				renderItem={sheet => {
					const { id, canceledDate, canceledBy } = sheet;

					return (
						<List.Item
							actions={[
								<Button
									type="primary"
									loading={isTimesheetSubmitting}
									onClick={() => {
										onGenerateNewTimesheet(id);
									}}>
									Generate New
								</Button>
							]}
							key={id}>
							<Typography.Text>
								{`Timesheet ID : ${id} |  Corrected by ${canceledBy} on ${moment(
									canceledDate,
									'YYYYMMDDHHmmss'
								).format('DD-MM-YYYY HH:mm')}`}
							</Typography.Text>
						</List.Item>
					);
				}}
			/>
		</Modal>
	);
};

export default TimesheetCorrectedModal;
