import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import tinycolor from 'tinycolor2';
import { css } from 'emotion';
import { CloseIcon, SettingsOutlineIcon } from 'mdi-react';
import { CSSTransition } from 'react-transition-group';
import getAppName from '../utilities/get-app-name';

// Redux actions
import { closeSideMenu } from '../actions/sideMenuActions';

// Styles
import colors from '../style/colors';
import common from '../style/common';

// Utilities
import pageNavigator from '../utilities/page-navigator';
import linkPathCreator from '../utilities/get-link-path-from-page-object';
import iconConvertor from '../utilities/icon-converter';
import getProfilePicture from '../utilities/get-profile-picture-from-user-object';

import Overlay from './ui/Overlay';
import Notification from './ui/Notification';

function SideMenu(props) {
	const { user, active } = props;

	return (
		<>
			<Overlay active={active} onClick={props.closeSideMenu} />
			<CSSTransition in={active} timeout={300} mountOnEnter={true} unmountOnExit={true} classNames="side-menu">
				<div key={0} className={style()} data-test-id="side-menu">
					<CloseIcon className="close-icon" onClick={props.closeSideMenu} />
					<div
						className="user"
						onClick={() => {
							pageNavigator(`/${getAppName()}/profile/${user.id}`, 'forward');
							props.closeSideMenu();
						}}>
						{getProfilePicture(user, 42)}
						<div data-test-id="side-menu-user" style={{ marginLeft: '0.75rem' }}>
							<p className="bold">{user.name}</p>
							<p
								className="meta"
								onClick={e => {
									e.stopPropagation();
									pageNavigator(`/${getAppName()}/profile/${user.id}/?tab=1`, 'forward');
									props.closeSideMenu();
								}}>
								{user.masterGroup.title}, {user.userGroup.title}
							</p>
						</div>
						<SettingsOutlineIcon
							data-test-id="side-menu-settings"
							className="settings-icon"
							onClick={e => {
								e.stopPropagation();
								pageNavigator(
									`/${getAppName()}/profile/${user.id}/?tab=${props.enableHighscore ? 2 : 1}`,
									'forward'
								);
								props.closeSideMenu();
							}}
						/>
					</div>
					<div className="navigation">
						<div className="side-menu scroll-hider">
							{props.pages.map((page, pageKey) => (
								<div className="pageWrapper" key={`page-${page.id}`}>
									{iconConvertor(page.icon, { flexShrink: 0, color: colors.darkGrey })}
									<p
										className="page-title"
										onClick={() => {
											props.closeSideMenu();
											pageNavigator(linkPathCreator(page, 'absolute'), 'forward');
										}}
										key={pageKey}>
										{page.title}
									</p>
									{!page.notifications ? null : (
										<Notification notifications={parseInt(page.notifications)} />
									)}
								</div>
							))}
						</div>
					</div>
				</div>
			</CSSTransition>
		</>
	);
}

const style = config => css`
	position: fixed;
	display: flex;
	/* z-index: 2; */
	top: 0;
	flex-direction: column;
	width: 305px;
	height: 100%;
	background-color: ${colors.white};
	color: ${colors.black};
	border-right: 1px ${colors.lightGrey} solid;
	transition: background-color 120ms ease;
	transition-delay: 300ms;

	.page-title {
		flex-shrink: 1;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		padding: 0rem 0.5rem;
		flex-grow: 1;
	}

	.pageWrapper {
		position: relative;
		display: flex;
		align-items: center;
		width: 91%;
		justify-content: space-between;
		padding: 1rem;
	}

	&.side-menu-enter {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}
	&.side-menu-enter-active {
		transform: translate3d(0%, 0, 0);
		opacity: 1;
		transition: transform 190ms ease, opacity 300ms;
	}
	&.side-menu-exit {
		opacity: 1;
		transform: scale(1);
	}
	&.side-menu-exit-active {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
		transition: transform 300ms ease, opacity 300ms;
	}

	.close-icon {
		width: ${common.topBarHeight}px;
		height: ${common.topBarHeight}px;
		padding: 0.75rem;
	}

	.user {
		position: relative;
		display: flex;
		align-items: center;
		padding: 0.75rem;
		border: solid ${colors.lightGrey};
		border-width: 1px 0;

		.settings-icon {
			position: absolute;
			top: 0.75rem;
			right: 0.75rem;
			width: 1.25rem;
			height: 1.25rem;
			fill: ${colors.darkGrey};
			&:active {
				fill: ${tinycolor(colors.darkGrey).lighten(30).toString()};
			}
		}

		&:active {
			background-color: ${colors.ultraLightGrey};
			transition: background-color 120ms ease;
		}
	}

	.navigation {
		overflow: hidden;
		flex-grow: 1;
		position: relative;

		.scroll-hider {
			position: absolute;
			height: 100%;
			width: 110%; /* Hides scrollbar */
			overflow-y: auto;
			padding: 0.25rem 0;
			-webkit-overflow-scrolling: touch;
		}
	}
`;

const mapStateToProps = state => ({
	active: state.sideMenu.active,
	enableHighscore: state.appConfig.enableHighscore,
	pages: state.pages.pages,
	user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
	closeSideMenu: bindActionCreators(closeSideMenu, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
