import getAppName from "../utilities/get-app-name";

export default function (page, mode) {
  /**
   * Function that returns a url for a given page object
   * -----------------------------------------------
   * page must be a page object.
   * mode must be a string of either "relative" or "absolute"
   *
   * Absolute:
   * at: url.com/app/some-page/
   * navigate to: /some-other-page/
   * result: url.com/some-other-page&
   *
   * Relative:
   * at: url.com/app/some-page/
   * navigate to: some-other-page/
   * result: url.com/app/some-page/some-other-page/
   * */

  let prefix = "";

  if (mode === "absolute") {
    prefix += `/${getAppName()}/`;
  }

  /**
   * One-off rules for semcompletion. The semcompletion modules are names as:
   * "SemcompletionInstallation"
   * "SemcompletionPulling"
   * "SemcompletionTermination"
   * "SemcompletionMechanicalCompletion"
   *
   * The desired routes are:
   * "semcompletion/:jobPackageType/"
   *
   * The module name are therefore stripped of the first 13 characters ("Semcompletion")
   * so it becomes "installation", "pulling" etc.
   */
  if (page.dataId) {
    return `${prefix}${page.module}/${page.id}/?dataId=${page.dataId}`;
    // For every other module (without a dataId)
  } else {
    return `${prefix}${page.module}/${page.id}/`;
  }
}
