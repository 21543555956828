import TinyAnimate from "TinyAnimate";
export default function(element, duration = 300) {
  /** Smoothly scrools to top of given element in specified time
   *  @param element [HTMLElement] required
   *  @param duration [integer] optional - default to 300
   *
   */

  //  Fail silently if element is missing...
  if (!element) return;

  let scrollHeight = element.scrollHeight;
  let currentScroll = element.scrollTop;
  let endScroll = scrollHeight - element.getBoundingClientRect().height;

  TinyAnimate.animate(
    currentScroll,
    endScroll,
    duration,
    x => {
      element.scrollTo(x, 0);
    },
    "easeInOutQuart"
  );
}