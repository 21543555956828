import getAppName from "./get-app-name";

export default (state) => {
  try {
    const stateToSave = {
      appConfig: state.appConfig,
      auth: state.auth,
      language: state.language,
      pages: state.pages,
      semcompletion: state.semcompletion,
      users: state.users,
    };

    const serializedState = JSON.stringify(stateToSave);
    localStorage.setItem(`${getAppName()}-state`, serializedState);
  } catch (err) {
    // Could not write state to localstorage
  }
};
