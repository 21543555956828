import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';
import SignatureCanvas from 'react-signature-canvas';

const TimesheetSignaturePadModal = props => {
	const { isTimesheetSubmitting, onAddSignature, setToggleModal, toggleModal } = props;

	const language = useSelector(s => s.language.language);

	const signaturePadRef = useRef(null);

	const footerButtons = [
		<Button key="Clear" loading={isTimesheetSubmitting} onClick={() => signaturePadRef.current.clear()}>
			Clear
		</Button>,
		<Button
			key="Place Signature"
			loading={isTimesheetSubmitting}
			type="primary"
			onClick={async () => {
				const { isEmpty } = signaturePadRef.current;

				if (isEmpty()) {
					return Modal.warning({ title: 'Please sign before submitting' });
				}

				await onAddSignature({ signatureData: signaturePadRef.current.toDataURL() });
				signaturePadRef.current.clear();
				setToggleModal(false);
			}}>
			Add
		</Button>
	];

	return (
		<Modal
			maskClosable={false}
			title={`Add Signature`}
			visible={toggleModal}
			onCancel={() => {
				signaturePadRef.current.clear();
				setToggleModal(false);
			}}
			footer={footerButtons}>
			<div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'gray', padding: '5px' }}>
				<SignatureCanvas
					ref={signaturePadRef}
					penColor="black"
					canvasProps={{ style: { backgroundColor: 'white' }, width: 200, height: 200 }}
				/>
			</div>
		</Modal>
	);
};

export default TimesheetSignaturePadModal;
