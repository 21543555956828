import React from "react";
import { css } from "emotion";
import { CalendarRangeIcon } from "mdi-react";
import PropTypes from "prop-types";
//Style

import common from "../../style/common";
import colors from "../../style/colors";

/**
 * Component for handling a user picking a date
 * @param {function} onDateChange - A change listener that returns only the new date
 * @param {function} onChange - A regular onchange listener that returns the original event
 * @param {string} defaultDate - A specific date already selected
 * @param {string} name - html-attribute. Used to assicate inputs with label. `name={props.name}`
 */
function DatePicker(props) {
  const { disabled = false, onDateChange, onChange, defaultDate, name = "", style, value } = props;

  return (
    <div className={`${componentStyle()} ${props.className ? props.className : ""} `} style={style}>
      <label>
        <CalendarRangeIcon className="svg" />
        <input
          disabled={disabled}
          type="date"
          name={name}
          onChange={(e) => {
            if (onDateChange) onDateChange(e.target.value);
            if (onChange) onChange(e);
          }}
          value={value || defaultDate || ""}
        />
      </label>
    </div>
  );
}
var height = 40;
const componentStyle = () => css`
  display: flex;
  align-items: end;
  line-height: ${height}px;
  width: 100%;

  label {
    display: flex;
    width: 100%;
  }

  .svg {
    position: relative;
    margin-right: -40px;
    padding: 0.45rem;
    width: 40px;
    color: ${colors.darkGrey};
    height: ${height}px;
    border-right: 1px solid ${colors.lightGrey};
  }

  input {
    border: 1px solid ${colors.midGrey};

    background: transparent;
    padding-left: 3rem;
    display: block;
    height: ${height}px;
    color: #6e6e6e;
    width: 100%;
    font-family: ${common.fontStack};
    font-size: 1rem;
  }
`;
DatePicker.propTypes = {
  /** function to execute when a date is selected/clicked -> Returns only the selected date*/
  onDateChange: PropTypes.func,
  /** function to execute when a date is selected/clicked -> Returns a regular event*/
  onChange: PropTypes.func,
};
export default DatePicker;
