import React, { useEffect, useLayoutEffect, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ArrowBackIcon, InfoOutlineIcon, PlusIcon, FilePdfIcon, FileExcelIcon, MagnifyIcon } from 'mdi-react';

import { Button, notification } from 'antd';
import { parse, format, startOfMonth, endOfMonth } from 'date-fns';
import { css } from 'emotion';
import { useQuery } from 'react-query';
import param from 'jquery-param';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FileSaver from 'file-saver';
import { Document, Page } from 'react-pdf';
import * as ExcelJS from 'exceljs';

import usePageAndBackButtonURL from '../../../hooks/usePageAndBackButtonURL';
import pageNavigator from '../../../utilities/page-navigator';
import req from '../../../utilities/request-utility';

import colors from '../../../style/colors';
import InlineSpinner from '../../ui/InlineSpinner';
import StatusBox from '../../ui/StatusBox';
import DropDown from '../../ui/DropDown';
import ActionWrapper from '../../ui/ActionWrapper';
import ComponentPage from '../../ui/Page';
import TopBar from '../../ui/TopBar';

function WorkTimePendingApprovalHoursTimesheetTeamSelectedDK(props) {
	const { user = {} } = props;
	const { id: userAppId = '', loggedUserTypeDK = 'user', loggedUserAuthorizedForeman = false } = user;

	const { page, backButtonURL } = usePageAndBackButtonURL(props);
	const [excelData, setExcelData] = useState([]);
	const [tableData, setTableData] = useState({
		pdfFile: '',
		error: false,
		loading: false,
		empty: false
	});

	const exportAndGenerateTemplate = async () => {
		const workbook = new ExcelJS.Workbook();
		const sheet = workbook.addWorksheet('table');

		sheet.columns = [
			{ header: 'Ticket No.', key: 'ticketNo', width: 30 },
			{ header: 'Employee No.', key: 'id', width: 30 },
			{ header: 'Employee Name.', key: 'name', width: 30 },
			{ header: 'Foreman', key: 'foremanName', width: 30 },
			{ header: 'Account Date', key: 'accountDate', width: 30 }
		];

		const headerRange = sheet.getRow(1);
		headerRange.eachCell(cell => {
			cell.border = {
				top: { style: 'medium' },
				left: { style: 'medium' },
				bottom: { style: 'medium' },
				right: { style: 'medium' }
			};
		});

		if (excelData.length === 0)
			return notification.error({
				duration: 1,
				message: 'FAILED',
				description: 'Empty, no data to show'
			});

		excelData.forEach(rowData => {
			sheet.addRow(rowData);
		});

		const buffer = await workbook.xlsx.writeBuffer();
		const blobData = new Blob([buffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		});
		const { employeeCategory = '', fromDate = '', toDate = '' } = watch();

		FileSaver.saveAs(blobData, `Missing Hours Team Member ${employeeCategory}-${fromDate}-${toDate}.xlsx`);
	};

	const {
		data: { data: foremen = {} } = {},
		isFetching: foremenIsFetching,
		isLoading: foremenIsLoading,
		refetch: foremenRefetch
	} = useQuery(
		['GetForemen'],
		() => {
			return req()(`semcotime/foremen/with-admin`);
		},
		{
			staleTime: 30 * 1000
		}
	);

	const schema = yup.object().shape({
		employeeCategory: yup.string().required('Employee Category is required'),
		foremanId: yup.string(),
		fromDate: yup.string().required('From date is required'),
		toDate: yup.string().required('To date is required')
	});

	const defaultValues = {
		employeeCategory: 'temporary',
		foremanId: '',
		fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
		toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd')
	};

	const {
		control,
		formState: { errors },
		getValues,
		reset,
		setValue,
		handleSubmit,
		watch
	} = useForm({
		defaultValues,
		resolver: yupResolver(schema)
	});

	const onSubmit = async formData => {
		try {
			const { employeeCategory, foremanId, fromDate = '', toDate = '' } = formData;

			setTableData({ pdfFile: '', loading: true, error: false, empty: false });

			const response = await req()(
				`semcotime/reports/missingHours?${param({
					employeeCategory,
					foremanId,
					fromDate,
					toDate
				})}`,
				{
					responseType: 'json'
				}
			);

			const pdfBuffer = Buffer.from(response.data.pdfBuffer.data);

			// Create a Blob from the Buffer data
			const receivedBlob = new Blob([pdfBuffer], { type: 'application/pdf' });

			setExcelData(response.data.missingHoursMembers);
			setTableData({
				loading: false,
				pdfFile: window.URL.createObjectURL(receivedBlob),
				error: false,
				empty: false
			});
		} catch (err) {
			const { status } = err.response;
			if (status === 404) return setTableData({ loading: false, pdfFile: '', error: false, empty: true });

			setTableData({ loading: false, pdfFile: '', error: true, empty: false });
		}
	};

	const onDownload = () => {
		const { employeeCategory = '', foremanId = '', fromDate = '', toDate = '' } = watch();
		FileSaver.saveAs(tableData.pdfFile, `Missing Hours Team Member ${employeeCategory}-${fromDate}-${toDate}`);
	};

	return (
		<ComponentPage className={componentStyles(loggedUserTypeDK === 'user' ? true : false)}>
			<TopBar
				title={page.title}
				actionLeft={
					<ActionWrapper onClick={() => pageNavigator(backButtonURL, 'backward')}>
						<ArrowBackIcon />
					</ActionWrapper>
				}
			/>
			<div className="date-selector-bar">
				{loggedUserTypeDK != 'user' && (
					<div className="dropdown-fields">
						{(loggedUserTypeDK === 'admin' || loggedUserAuthorizedForeman) && (
							<div>
								<Controller
									name="foremanId"
									control={control}
									render={({
										field: { onChange, onBlur, value, name, ref },
										fieldState: { error }
									}) => {
										return (
											<DropDown
												allowClear={true}
												name="foremanId"
												placeholder="Select a Foreman"
												loading={foremenIsLoading}
												onChange={e => onChange(e)}
												options={
													!foremenIsLoading
														? foremen.all.map(d => ({ label: d.name, value: d.id }))
														: []
												}
												value={value}
											/>
										);
									}}
								/>
							</div>
						)}
						<div>
							<Controller
								name="employeeCategory"
								control={control}
								render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
									return (
										<DropDown
											name="employeeCategory"
											placeholder="Employee Category"
											onChange={e => {
												setValue('employeeId', '');
												onChange(e);
											}}
											options={[
												{ label: 'Full Time App Users', value: 'fulltime' },
												{ label: 'Temporary Time App Users', value: 'temporary' }
											]}
											value={value}
										/>
									);
								}}
							/>
						</div>
					</div>
				)}
				<div className="right">
					<div className="date-inputs">
						<p>From: </p>
						<Controller
							name="fromDate"
							control={control}
							render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
								return <input value={value} type="date" name="fromDate" onChange={e => onChange(e)} />;
							}}
						/>
						<p>To: </p>
						<Controller
							name="toDate"
							control={control}
							render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
								return <input value={value} type="date" name="toDate" onChange={e => onChange(e)} />;
							}}
						/>
					</div>
					<div className="buttons">
						<Button
							disabled={tableData.loading}
							onClick={handleSubmit(
								e => onSubmit(e),
								e => {
									notification.error({
										duration: 1,
										message: 'FAILED',
										description: Object.keys(e).map(d => e[d].message)[0]
									});
								}
							)}>
							<MagnifyIcon />
						</Button>
						<Button onClick={onDownload} disabled={!tableData.pdfFile || tableData.loading}>
							<FilePdfIcon />
						</Button>
						<Button onClick={exportAndGenerateTemplate} disabled={!tableData.pdfFile || tableData.loading}>
							<FileExcelIcon />
						</Button>
					</div>
				</div>
			</div>
			{tableData.loading && <InlineSpinner style={{ marginTop: '2rem' }} title="Loading timesheet..." />}

			{!tableData.loading && tableData.empty && (
				<StatusBox
					style={{ marginTop: '2rem' }}
					title="No results"
					content="No results found. Try with different dates"
					icon={<InfoOutlineIcon />}></StatusBox>
			)}

			{!tableData.loading && tableData.error && (
				<StatusBox
					style={{ marginTop: '2rem' }}
					title="Something went wrong"
					icon={<InfoOutlineIcon />}></StatusBox>
			)}

			{!tableData.loading && !tableData.error && tableData.pdfFile && (
				<div className="inline-scroll-wrapper" id="custom-scroll-wrapper-kasd872jhKsd72">
					<Document
						// orientation="landscape"
						size="A4"
						file={tableData.pdfFile}
						onLoadError={e => console.log('PDF Render Error: ', e)}
						// onLoadSuccess={(e) => console.log(e)}
					>
						<Page style={{ marginLeft: '50%' }} pageNumber={1} />
					</Document>
				</div>
			)}
		</ComponentPage>
	);
}

const componentStyles = isUser => css`
	.inline-scroll-wrapper {
		overflow-x: auto;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		.react-pdf__Page__canvas {
			margin: 0 auto !important;
		}
	}

	.date-selector-bar {
		height: 55px;
		border-bottom: 1px ${colors.midGrey} solid;
		background-color: ${colors.white};
		display: flex;
		align-items: center;
		justify-content: ${isUser ? 'end' : 'space-between'};
		font-size: 1rem;
		padding-right: 15px;
		padding-left: 15px;

		.dropdown-fields {
			width: 35%;
			display: flex;
			justify-content: space-evenly;

			& > div:first-child {
				flex-grow: 2; /* Make the first child wider */
			}

			& > div {
				flex-grow: 1;
			}
		}

		.right {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.date-inputs {
			display: contents;
		}

		input {
			margin: 0 0.5rem;
			color: ${colors.darkGrey};
			border: 1px ${colors.darkGrey} dashed;
			border-width: 0 0 1px 0;
			font-size: 1rem;
		}
	}

	@media screen and (max-width: 944px) {
		.date-selector-bar {
			display: flex;
			flex-direction: column;
			height: initial;
			gap: 15px;

			.dropdown-fields {
				display: block;
				margin-top: 10px;
				width: 100%;
			}

			.right {
				display: block;

				.date-inputs {
					display: inline-flex;
				}

				.buttons {
					margin-top: 10px;
					display: flex;
					justify-content: center;
					margin-bottom: 10px;
				}
			}
		}
	}
`;

export default WorkTimePendingApprovalHoursTimesheetTeamSelectedDK;
