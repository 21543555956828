import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { notification, Tabs } from 'antd';
import { useMediaQuery } from 'react-responsive';
import params from 'jquery-param';

// css
import { css } from 'emotion';
import { ArrowBackIcon } from 'mdi-react';
import colors from '../../../style/colors';

import TopBar from '../../ui/TopBar';
import Page from '../../ui/Page';
import getBackButtonUrl from '../../../utilities/get-back-button-url';
import getPageFromId from '../../../utilities/get-page-from-id';
import ActionWrapper from '../../ui/ActionWrapper';
import pageNavigator from '../../../utilities/page-navigator';
import DropDown from '../../ui/DropDown';
import InlineSpinner from '../../ui/InlineSpinner';

import TimesheetRenderModal from './TimesheetSignatureComponents/TimesheetRenderModal';
import TimesheetListItem from './TimesheetSignatureComponents/TimesheetListItem';
import TimesheetSignaturePadModal from './TimesheetSignatureComponents/TimesheetSignaturePadModal';
import TimesheetCorrectedModal from './TimesheetSignatureComponents/TimesheetCorrectedModal';

// utilities
import req from '../../../utilities/request-utility';

const TimesheetsPendingSignature = props => {
	const pages = useSelector(s => s.pages.pages);
	const loggedInUser = props.user;
	const { id, loggedUserTypeDK } = loggedInUser;
	const page = getPageFromId(pages, props.match.params.pageId);
	const backButtonURL = getBackButtonUrl(page);

	const [selectedForeman, setSelectedForeman] = useState(loggedUserTypeDK === 'foreman' ? id : null);
	const [selectedTimesheet, setSelectedTimesheet] = useState({});
	const [toggleModal, setToggleModal] = useState(false);
	const [isTimesheetSubmitting, setIsTimesheetSubmitting] = useState(false);

	const [toggleSignaturePadModal, setToggleSignaturePadModal] = useState(false);
	const [draftSignedTimesheet, setDraftSignedTimesheet] = useState(null);

	const lang = useSelector(({ language }) => language.language);

	const isMobile = useMediaQuery({ maxWidth: '414px' });

	const {
		data: { data: needsSignatureData = [] } = {},
		isFetching: needsSignatureIsFetching,
		isLoading: needsSignatureIsLoading,
		refetch: needsSignatureRefetch
	} = useQuery(
		['NeedsSignature', selectedForeman],
		({ queryKey: [key, selectedForeman] = [] }) => {
			if (!selectedForeman) return;
			return req()(`semcotime/timesheet/getAllPending/${selectedForeman}`);
		},
		{
			staleTime: 30 * 1000,
			refetchOnWindowFocus: false
		}
	);

	const {
		data: { data: archiveData = [] } = {},
		isFetching: archiveIsFetching,
		isLoading: archiveIsLoading,
		refetch: archiveRefetch
	} = useQuery(
		['archiveTimesheets', selectedForeman],
		({ queryKey: [key, selectedForeman] = [] }) => {
			if (!selectedForeman) return;
			return req()(`semcotime/timesheet/getAllArchive/${selectedForeman}`);
		},
		{
			staleTime: 30 * 1000,
			refetchOnWindowFocus: false
		}
	);

	const {
		data: { data: correctedBySemsupportData = [] } = {},
		isFetching: correctedBySemsupportIsFetching,
		isLoading: correctedBySemsupportIsLoading,
		refetch: correctedBySemsupportRefetch
	} = useQuery(
		['correctedTimesheets', selectedForeman],
		({ queryKey: [key, selectedForeman] = [] }) => {
			if (!selectedForeman) return;
			return req()(`semcotime/timesheet/getAllCorrectedBySemsupport/${selectedForeman}`);
		},
		{
			staleTime: 30 * 1000,
			refetchOnWindowFocus: true
		}
	);

	const { data: { data: foremen = { all: [] } } = {}, isFetching: foremenIsFetching } = useQuery(
		['GetForemen'],
		() => {
			return req()(`semcotime/foremen/with-admin`);
		},
		{
			staleTime: 30 * 1000,
			refetchOnWindowFocus: false,
			enabled: loggedUserTypeDK === 'admin'
		}
	);

	const onCancelTimesheetRequest = async timesheetID => {
		try {
			setIsTimesheetSubmitting(true);
			await req().put(`semcotime/timesheet/markAsCanceled/${timesheetID}`);

			setIsTimesheetSubmitting(false);

			notification.success({ duration: 7, message: 'SUCCESS', description: 'Timesheet is rejected' });
			setToggleModal(false);
			needsSignatureRefetch();
			archiveRefetch();
		} catch (error) {
			setIsTimesheetSubmitting(false);

			notification.error({
				duration: 7,
				message: 'FAILED',
				description: error.response.data.message
			});
		}
	};

	const onAddSignature = async ({ signatureData }) => {
		try {
			setIsTimesheetSubmitting(true);
			const { id: timesheetID = '' } = selectedTimesheet;

			const response = await req().post(
				`semcotime/timesheet/draftSignatureTimesheet/${timesheetID}`,
				{ signatureData },
				{
					headers: {
						'Content-Type': 'application/json'
					}
				}
			);

			const { data: { modifiedPdfBytes: pdfBuffer } = {} } = response;

			setIsTimesheetSubmitting(false);
			const pdfBufferData = Buffer.from(pdfBuffer);
			const receivedBlob = new Blob([pdfBufferData], { type: 'application/pdf' });
			setDraftSignedTimesheet(receivedBlob);
		} catch (error) {
			setIsTimesheetSubmitting(false);

			notification.error({
				duration: 7,
				message: 'FAILED',
				description: error.response.data.message
			});
		}
	};

	const onApproveTimesheet = async () => {
		try {
			setIsTimesheetSubmitting(true);
			const { blueCollarId = '', id: timesheetID = '' } = selectedTimesheet;

			const formData = new FormData();

			formData.append('file', draftSignedTimesheet);
			formData.append('blueCollarId', blueCollarId);

			await req().put(`semcotime/timesheet/markAsApproved/${timesheetID}`, formData, {
				headers: {
					'Content-Type': 'application/json'
				}
			});

			needsSignatureRefetch();
			notification.success({ duration: 7, message: 'SUCCESS', description: 'Timesheet is Approved' });
			archiveRefetch();
			setIsTimesheetSubmitting(false);
			setToggleModal(false);
			setDraftSignedTimesheet(null);
		} catch (error) {
			setIsTimesheetSubmitting(false);
			notification.error({
				duration: 7,
				message: 'FAILED',
				description: error.response.data.message
			});
		}
	};

	const onGenerateNewTimesheet = async id => {
		try {
			setIsTimesheetSubmitting(true);
			await req().put(`semcotime/timesheet/generateNewTimesheet/${id}`);

			await correctedBySemsupportRefetch();
			setIsTimesheetSubmitting(false);

			notification.success({ duration: 7, message: 'SUCCESS', description: 'Timesheet is generated' });
			needsSignatureRefetch();
		} catch (err) {
			setIsTimesheetSubmitting(false);
			notification.error({ duration: 7, message: 'FAILED', description: err.response.data.message });
		}
	};

	return (
		<Page className={componentStyles(props)}>
			<TopBar
				actionLeft={
					<ActionWrapper onClick={() => pageNavigator(backButtonURL, 'backward')}>
						<ArrowBackIcon />
					</ActionWrapper>
				}
				title={page.title}
			/>
			{loggedUserTypeDK === 'admin' && (
				<div style={isMobile ? { padding: '1rem', width: '100%', margin: 0 } : { margin: '1rem' }}>
					<DropDown
						name="foreman"
						loading={foremenIsFetching}
						options={foremen.all.map(d => ({
							label: `${d.name} | Ticket No. ${d.ticketNumber ? d.ticketNumber : 'N/A'}`,
							value: d.id
						}))}
						placeholder="Select Foreman"
						value={selectedForeman}
						onChange={e => setSelectedForeman(e.target.value)}
					/>
				</div>
			)}
			<div className="inline-scroll-wrapper">
				<Tabs defaultActiveKey="1">
					<Tabs.TabPane
						tab={
							<>
								{lang.timesheetNeedsSignature}{' '}
								{needsSignatureData.length > 0 ? <>{needsSignatureData.length}</> : ''}
								{(needsSignatureIsLoading || needsSignatureIsFetching) && (
									<InlineSpinner
										size="14"
										style={{ display: 'inline-block', margin: '0 0rem -0.6rem 0.5rem' }}
									/>
								)}
							</>
						}
						key="1">
						<>
							{needsSignatureIsLoading && <InlineSpinner />}

							{!needsSignatureIsLoading && (
								<TimesheetListItem
									sheets={needsSignatureData}
									setSelectedTimesheet={setSelectedTimesheet}
									setToggleModal={setToggleModal}
								/>
							)}
						</>
					</Tabs.TabPane>
					<Tabs.TabPane
						tab={
							<>
								{lang.archive} {archiveData.length > 0 ? <>{archiveData.length}</> : ''}
								{(archiveIsLoading || archiveIsFetching) && (
									<InlineSpinner
										size="14"
										style={{ display: 'inline-block', margin: '0 0rem -0.6rem 0.5rem' }}
									/>
								)}
							</>
						}
						key="2">
						<>
							{archiveIsLoading && <InlineSpinner />}

							{!archiveIsLoading && (
								<TimesheetListItem
									sheets={archiveData}
									setSelectedTimesheet={setSelectedTimesheet}
									setToggleModal={setToggleModal}
								/>
							)}
						</>
					</Tabs.TabPane>
				</Tabs>
			</div>

			<TimesheetRenderModal
				draftSignedTimesheet={draftSignedTimesheet}
				isTimesheetSubmitting={isTimesheetSubmitting}
				onCancelTimesheetRequest={onCancelTimesheetRequest}
				onApproveTimesheet={onApproveTimesheet}
				selectedTimesheet={selectedTimesheet}
				setDraftSignedTimesheet={setDraftSignedTimesheet}
				setToggleModal={setToggleModal}
				setToggleSignaturePadModal={setToggleSignaturePadModal}
				toggleModal={toggleModal}
			/>

			<TimesheetSignaturePadModal
				isTimesheetSubmitting={isTimesheetSubmitting}
				onAddSignature={onAddSignature}
				selectedTimesheet={selectedTimesheet}
				setDraftSignedTimesheet={setDraftSignedTimesheet}
				setToggleModal={setToggleSignaturePadModal}
				toggleModal={toggleSignaturePadModal}
			/>

			{correctedBySemsupportData.length !== 0 && (
				<TimesheetCorrectedModal
					correctedBySemsupportData={correctedBySemsupportData}
					isTimesheetSubmitting={isTimesheetSubmitting}
					onGenerateNewTimesheet={onGenerateNewTimesheet}
				/>
			)}
		</Page>
	);
};

const componentStyles = isUser => css`
	background-color: ${colors.white} !important;

	.inline-scroll-wrapper {
		overflow-x: auto;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		padding: 1rem;

		.react-pdf__Page__canvas {
			margin: 0 auto !important;
		}
	}
`;

export default TimesheetsPendingSignature;
