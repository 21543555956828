import params from "jquery-param";

import { USERS } from "./actionTypes";

import req from "../utilities/request-utility";

export const set = (payload) => ({ type: USERS.SETUSER, payload });

export const fetch = () => async (dispatch, getState) => {
  try {
    const { user } = getState().auth;

    dispatch({ type: USERS.FETCH.REQUEST });

    const { data } = await req()(`users?${params({ all: true, masterGroupId: user.masterGroup.id })}`);

    dispatch({ type: USERS.FETCH.SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USERS.FETCH.FAILURE });
    throw error.response.data;
  }
};
