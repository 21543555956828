import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { CloseIcon, DeleteIcon, PlusIcon } from "mdi-react";
import { isEqual, keyBy, omit, values, isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Checkbox, Modal, notification, Popconfirm, Radio } from "antd";
import param from "jquery-param";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import workHourState from "../../../config/semcotimedk-workhour-states";

// css
import { css } from "emotion";
import common from "../../../style/common";
import colors from "../../../style/colors";
import TextInput from "../../ui/TextInput";
import DatePicker from "../../ui/DatePicker";
import InlineSpinner from "../../ui/InlineSpinner";
import ScrollView from "../../ui/ScrollView";
import getTotalHours from "../../../utilities/get-total-hours";
import req from "../../../utilities/request-utility";
import getTimerOptions from "../../../utilities/get-timer-options";

//ui
import Button from "../../ui/Button";
import CheckboxGroup from "../../ui/CheckboxGroup";
import DropDown from "../../ui/DropDown";
import ForemanSubRecordForm from "./ForemanSubRecordForm";
import { toggleWorkHourModal } from "../../../actions/uiActions";

function ForemanWorkHourForm(props, ref) {
  const dispatch = useDispatch();
  const {
    data: registration = {},
    externalId = null,
    onDelete: onDeleteParent,
    onSubmit: onSubmitParent,
    sentToIFSAt,
    lang,
    page = "foreman",
    userId = null,
  } = props;
  const { data = {}, ifsData = null } = registration;

  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const modal = useSelector(({ ui }) => ui.workHourModal);

  const getLastUsedFormValues = () => {
    let lastUsedTime = localStorage.getItem(`semcotime-last-time-input-${userId}`);
    lastUsedTime = lastUsedTime ? JSON.parse(lastUsedTime) : null;
    let formDataInitialNewValues = { ...formDataInitialValues };
    if (lastUsedTime) {
      formDataInitialNewValues = {
        ...formDataInitialNewValues,
        project: lastUsedTime.project,
        subProject: lastUsedTime.subProject,
        projectActivity: lastUsedTime.activity,
        startTimeHours: lastUsedTime.startTimeHours,
        startTimeMinutes: lastUsedTime.startTimeMinutes,
        endTimeHours: lastUsedTime.endTimeHours,
        endTimeMinutes: lastUsedTime.endTimeMinutes,
        dabLocation: lastUsedTime.dabLocation,
        // dabTrade: lastUsedTime.dabTrade,
      };
    }

    return formDataInitialNewValues;
  };

  const formDataInitialValues = {
    project: undefined,
    subProject: undefined,
    projectActivity: undefined,
    subRecords: {},
    activities: [],
    customerDetail: "",
    customerNote: "",
    foreman: undefined,
    date: undefined,
    startTimeHours: "00",
    startTimeMinutes: "00",
    endTimeHours: "00",
    endTimeMinutes: "00",
    note: "",
    hours: "",
    shift: "Day",
    type: "time",
    totalHours: 0,
    unpaidBreak: false,
    paidBreak: false,
    transport: false,
    endOfRotation: false,
    changeShift: false,
    heliTime: false,
    standby: false,
    dabLocation: "",
    dabTrade: "",

    ...(!isEmpty(data) ? data : getLastUsedFormValues()),
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [formData, setFormData] = useState(formDataInitialValues);
  const [formDataOrig, setFormDataOrig] = useState(formDataInitialValues);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const [additionalData, setAdditionalData] = useState({
    projects: [],
    subProjects: [],
    projectActivities: [],
    wageCodes: [],
    foremen: [],
    dabTradeCodes: [],
    dabLocations: [],
  });

  const [isFormReady, setIsFormReady] = useState(false);
  const [hoursOptions, setHoursOptions] = useState([]);
  const [foremen, setForemen] = useState([]);

  const [fetchingProjects, setFetchingProjects] = useState(false);
  const [fetchingSubprojects, setFetchingSubprojects] = useState(false);
  const [fetchingActivities, setFetchingActivities] = useState(false);
  const [fetchingWageCodes, setFetchingWageCodes] = useState(false);
  const [fetchingDabTrade, setFetchingDabTrade] = useState(false);
  const [fetchingDabLocations, setFetchingDabLocations] = useState(false);
  const [tradeCodesList, setTradeCodesList] = useState([]);

  const archived = useMemo(() => (registration.lockedByIFS ? true : false), [registration.lockedByIFS]);
  const needsProcessing = useMemo(() => (registration.sentToIFSAt ? true : false), [registration.sentToIFSAt]);

  const { date, startTimeHours, startTimeMinutes, endTimeHours, endTimeMinutes } = formData;
  useEffect(() => {
    // If data isn't complete
    if (!date || !startTimeHours || !startTimeMinutes || !endTimeHours || !endTimeMinutes) {
      setHoursOptions([]);
      return;
    }

    setHoursOptions(getTimerOptions({ startTimeHours, startTimeMinutes, endTimeHours, endTimeMinutes, date }));

    // Break it up into array of 15-minuts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, startTimeHours, startTimeMinutes, endTimeHours, endTimeMinutes]);

  const onSubmit = async (form) => {
    setIsSubmitting(true);
    await onSubmitParent(form);
    setIsSubmitting(false);
  };

  const onDelete = async () => {
    setIsDeleting(true);
    await onDeleteParent();
    setIsDeleting(false);
  };

  // Fetches all the addtional data needed (projects, subprojects etc.)
  const getProjects = async () => {
    setFetchingProjects(true);
    const { data: projects } = await req()(`semcotime/projects`);
    setFetchingProjects(false);
    return setAdditionalData((additionalData) => ({ ...additionalData, projects }));
  };

  const getSubProjects = async (project) => {
    setFetchingSubprojects(true);
    const { data: subProjects } = await req()(`semcotime/projects/${project}/sub-projects`);
    setFetchingSubprojects(false);
    return setAdditionalData((additionalData) => ({ ...additionalData, subProjects }));
  };

  const getProjectActivities = async ({ project, subProject }) => {
    setFetchingActivities(true);
    const { data: projectActivities } = await req()(`semcotime/projects/${project}/sub-projects/${subProject}/activities`);
    setFetchingActivities(false);
    return setAdditionalData((additionalData) => ({ ...additionalData, projectActivities }));
  };

  const getWageCodes = async () => {
    setFetchingWageCodes(true);
    const { data: wageCodes } = await req()(`semcotime/wage-codes?wageType=increment`);
    setFetchingWageCodes(false);
    return setAdditionalData((additionalData) => ({ ...additionalData, wageCodes }));
  };

  const getDABTradeCodes = async () => {
    setFetchingDabTrade(true);
    const { data: dabTradeCodes } = await req()(`semcotime/dab-trade-codes`);
    setFetchingDabTrade(false);
    return setAdditionalData((additionalData) => ({ ...additionalData, dabTradeCodes }));
  };

  const getDABLocations = async () => {
    setFetchingDabLocations(true);
    const { data: dabLocations } = await req()(`semcotime/dab-locations`);
    setFetchingDabLocations(false);
    return setAdditionalData((additionalData) => ({ ...additionalData, dabLocations }));
  };

  const getDefaultEmployeeTradeRequest = async () => {
    const { data: defaultEmployeeValues } = await req()(`semcotime/projects/default-employee-values/${externalId}`);
    return setAdditionalData((additionalData) => ({ ...additionalData, defaultEmployeeValues }));
  };
  const getAdditionalData = async (formData = {}) => {
    const { project = null, subProject = null } = formData;

    try {
      const requests = [
        getProjects(),
        getWageCodes(),
        getDABTradeCodes(),
        getDABLocations(),
        getDefaultEmployeeTradeRequest(),
      ];
      if (project) requests.push(getSubProjects(project));
      if (project && subProject) requests.push(getProjectActivities({ project, subProject }));
      await Promise.all(requests);
      setIsFormReady(true);
    } catch (err) {
      notification.error({
        duration: 7,
        message: "FAILED",
        description: `${lang.dkDropdownsFail}`,
      });
    }
  };

  const setDefaultEmployeeTrade = async () => {
    const { data: defaultEmployeeValues } = await req()(`semcotime/projects/default-employee-values/${externalId}`);
    setFormData((formData) => ({ ...formData, dabTrade: defaultEmployeeValues.employeeTrade }));
  };

  const getForemen = () => {
    try {
      setIsFormReady(false);

      req()(`semcotime/foremen?${param({ foremanGroupIdentifier: page === "admin" ? "ADMIN" : "FOREMEN" })}`).then(
        ({ data }) => {
          const assignedIds = data.assigned.map((d) => d.id);

          setForemen([
            ...data.assigned,
            { disabled: true, id: "", name: "------------------" },
            ...data.all.filter((d) => !assignedIds.includes(d.id)),
          ]);
        }
      );

      setIsFormReady(true);
    } catch (err) {
      // setErrorLoadingFormData(true);
    }
  };

  // as the second argument
  useImperativeHandle(ref, () => ({
    isFormDataChanged: () => (!isEqual(formDataOrig, formData) ? true : false),
  }));

  // Sets the formData
  useEffect(() => {
    getForemen();

    if (Object.keys(registration).length !== 0) {
      const totalHours = getTotalHours(data);
      let otherValuesChange = {};
      if (totalHours) {
        otherValuesChange = { ...otherValuesChange, totalHours };
        if (totalHours < 5) otherValuesChange = { ...otherValuesChange, paidBreak: false, unpaidBreak: false };
        if (totalHours >= 5 && totalHours <= 12) otherValuesChange = { ...otherValuesChange, paidBreak: false };
        if (totalHours > 12) otherValuesChange = { ...otherValuesChange, paidBreak: true, unpaidBreak: false };
      }

      setFormData({ subRecords: [], hours: "", ...data, ...otherValuesChange });
      setFormDataOrig({ subRecords: [], hours: "", ...data, ...otherValuesChange });
      getAdditionalData(data);
    } else {
      // Gets last used time-item in local storage (uses whatever the user entered last)
      let lastUsedTime = localStorage.getItem(`semcotime-last-time-input-${userId}`);
      lastUsedTime = lastUsedTime ? JSON.parse(lastUsedTime) : null;
      if (lastUsedTime) {
        setFormData(getLastUsedFormValues());
        setFormDataOrig(getLastUsedFormValues());
        getAdditionalData(lastUsedTime);
      } else {
        getAdditionalData();
        setDefaultEmployeeTrade();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(registration)]);

  useEffect(() => {
    if (totalHours !== formData.hours && formData.hours === "") {
      setFormData((formData) => ({ ...formData, hours: totalHours }));
    } // eslint-disable-next-line
  }, [formData.hours, totalHours]);

  useEffect(() => {
    setTradeCodesList(additionalData.dabTradeCodes);
  }, [additionalData.dabTradeCodes.length]);

  useEffect(() => {
    if (formData.dabLocation === "Abroad - Onshore" || formData.dabLocation === "Abroad - Offshore") {
      setTradeCodesList(additionalData.dabTradeCodes.filter((d) => ["022", "070", "120", "125"].includes(d.DabTradeId)));
      return setFormData((formData) => ({ ...formData }));
    } else {
      setTradeCodesList(additionalData.dabTradeCodes);
      setFormData((formData) => ({
        ...formData,
        ...(isEmpty(registration) && {
          dabTrade: additionalData.defaultEmployeeValues
            ? additionalData.defaultEmployeeValues.employeeTrade
            : formData.dabTrade,
        }),
        transport: formData.dabLocation === "OffShore" ? false : formData.transport,
        heliTime: formData.dabLocation === "OnShore" ? false : formData.heliTime,
      }));
    }
  }, [formData.dabLocation, additionalData.dabTradeCodes.length]);

  useEffect(() => {
    const { startTimeHours = "", endTimeHours = "", startTimeMinutes = "", endTimeMinutes = "" } = formData;

    setFormData({
      ...formData,
      shift: moment(`${endTimeHours}:${endTimeMinutes}`, "hh:mm").isBefore(
        moment(`${startTimeHours}:${startTimeMinutes}`, "hh:mm")
      )
        ? "Night"
        : "Day",
    });
  }, [formData.startTimeHours, formData.endTimeHours, formData.startTimeMinutes, formData.endTimeMinutes]);

  const onChange = (e, subRecordId = null) => {
    if (e.persist) e.persist();

    // Handling of rest
    if (e.target) {
      if (subRecordId) {
        const field = e.target.name.split(".")[1];
        const subRecordData = formData.subRecords[subRecordId];

        setFormData({
          ...formData,
          subRecords: {
            ...formData.subRecords,
            [subRecordId]: {
              ...subRecordData,
              [field]: e.target.value,
            },
          },
        });
      } else {
        let { checked, name, value } = e.target;

        let otherValuesChange = {};
        if (name === "project") {
          getSubProjects(value);
          otherValuesChange = { ...otherValuesChange, subProject: "", projectActivity: "" };
        }
        if (name === "subProject") {
          getProjectActivities({ project: formData.project, subProject: value });
          otherValuesChange = { ...otherValuesChange, projectActivity: "" };
        }
        if (name === "break") {
          if (value === "paid") otherValuesChange = { ...otherValuesChange, paidBreak: true, unpaidBreak: false };
          else otherValuesChange = { ...otherValuesChange, paidBreak: false, unpaidBreak: true };
        }
        if (name === "manualApproval") {
          value = value.includes("manualApproval") ? true : false;
        }

        setFormData((formData) => {
          if (name === "date") {
            const totalHours = getTotalHours({ ...formData, [name]: value });
            if (totalHours) {
              otherValuesChange = { ...otherValuesChange, totalHours };
              if (totalHours < 5) otherValuesChange = { ...otherValuesChange, paidBreak: false, unpaidBreak: false };
              if (totalHours >= 5 && totalHours <= 12) otherValuesChange = { ...otherValuesChange, paidBreak: false };
              if (totalHours > 12) otherValuesChange = { ...otherValuesChange, paidBreak: true, unpaidBreak: false };
            }
          }
          if (name === "transport" || name === "heliTime") {
            return {
              ...formData,
              ...otherValuesChange,
              [name]: checked,
              [name === "transport" ? "heliTime" : "transport"]: false,
              standby: false,
            };
          } else if (name === "endOfRotation" || name === "changeShift" || name === "standby") {
            return { ...formData, ...otherValuesChange, [name]: checked };
          } else {
            return { ...formData, ...otherValuesChange, [name]: value };
          }
        });
      }
    }
  };

  const addSubRecord = useCallback(() => {
    const id = uuidv4();

    setFormData({
      ...formData,
      subRecords: {
        ...formData.subRecords,
        [id]: {
          id,
          customerDetail: "",
          customerNote: "",
          note: "",
          hours: "",
        },
      },
    });
  }, [formData]);

  const duplicateRecord = useCallback(
    (duplicateData) => {
      const id = uuidv4();

      let subRecords = values(formData.subRecords);
      const prevRecordIndex = subRecords.indexOf(duplicateData);

      const totalHours = parseFloat(formData.hours),
        newHour = parseFloat(duplicateData.hours);
      let totalSubRecordsHours = 0;
      subRecords.map((d) => (totalSubRecordsHours += parseFloat(d.hours)));

      subRecords.splice(prevRecordIndex + 1, 0, {
        ...duplicateData,
        hours: totalSubRecordsHours + newHour <= totalHours ? newHour : totalHours - totalSubRecordsHours,
        id,
      });

      setFormData({
        ...formData,
        subRecords: keyBy(subRecords, "id"),
      });
    },
    [formData]
  );

  const deleteSubRecord = useCallback(
    (id) => {
      setFormData({ ...formData, subRecords: { ...omit(formData.subRecords, id) } });
    },
    [formData]
  );

  const onChangeTimeInputs = (e, type, formData) => {
    e.persist();

    // Exception for deleing
    if (e.target.value === "") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    let num = parseInt(e.target.value);

    if (
      // If not a number
      isNaN(num) ||
      // If length is more than two digits
      e.target.value.length > 2 ||
      // type is hours and value is not between 0-23
      (type === "hours" && (num < 0 || num > 23)) ||
      // type is minutes and value is not between 0-59
      (type === "minutes" && (num < 0 || num > 59))
    ) {
      return;
    }

    let otherValuesChange = {};
    const { name, value } = e.target;
    setFormData((formData) => {
      const totalHours = getTotalHours({ ...formData, [name]: value });
      if (totalHours) {
        otherValuesChange = { ...otherValuesChange, hours: totalHours, totalHours };
        if (totalHours < 5) otherValuesChange = { ...otherValuesChange, paidBreak: false, unpaidBreak: false };
        if (totalHours >= 5 && totalHours <= 12) otherValuesChange = { ...otherValuesChange, paidBreak: false };
        if (totalHours > 12) otherValuesChange = { ...otherValuesChange, paidBreak: true, unpaidBreak: false };
      }
      return { ...formData, ...otherValuesChange, [name]: value };
    });
  };

  const { changeShift = false, paidBreak = false, shift = "Day", totalHours = 0, unpaidBreak = false } = formData;

  const disabled = archived || needsProcessing === true;

  const renderForm = () => {
    return (
      <>
        <ScrollView
          className={scrollViewStyles({ topBarHeight: common.topBarHeight, bottomPanelHeight: archived ? 0 : 66 })}
        >
          <div className={componentStyles(primaryColor)}>
            {!isFormReady && (
              <div className="meta">
                <InlineSpinner title={lang.loadingForm} />
              </div>
            )}

            {isFormReady && registration.state === workHourState.deleted && <div className="deleted">DELETED IN IFS</div>}

            {isFormReady && registration.state === workHourState.lockedByForeman && needsProcessing === true && (
              <div className="processing">PROCESSING IN IFS</div>
            )}

            {isFormReady && (
              <>
                {/* Projects */}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.project}</label>
                  <DropDown
                    disabled={disabled}
                    loading={fetchingProjects}
                    name="project"
                    onChange={onChange}
                    options={additionalData.projects.map((d) => ({ label: `${d.id} - ${d.name}`, value: d.id }))}
                    placeholder={lang.chooseAProject}
                    value={formData.project}
                  />
                </div>

                {/* Sub projects */}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.subproject}</label>
                  <DropDown
                    disabled={disabled}
                    loading={fetchingSubprojects}
                    name="subProject"
                    onChange={onChange}
                    options={additionalData.subProjects.map((d) => ({ label: `${d.id} - ${d.description}`, value: d.id }))}
                    placeholder={lang.chooseASubProject}
                    value={formData.subProject}
                  />
                </div>

                {/* Activites */}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.activity}</label>
                  <DropDown
                    disabled={disabled}
                    loading={fetchingActivities}
                    name="projectActivity"
                    onChange={onChange}
                    options={additionalData.projectActivities.map((d) => ({
                      label: `${d.no} - ${d.description}`,
                      value: d.id,
                    }))}
                    placeholder={lang.chooseActivity}
                    value={formData.projectActivity}
                  />
                </div>

                {/* Locations */}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.location}</label>
                  <DropDown
                    disabled={disabled}
                    loading={fetchingDabLocations}
                    name="dabLocation"
                    onChange={onChange}
                    options={additionalData.dabLocations.map((d) => ({
                      label: d.DabLocationValue,
                      value: d.DabLocationValue,
                    }))}
                    placeholder={lang.chooseADabLocations}
                    value={formData.dabLocation}
                  />
                </div>

                {/* Trade */}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.dabTrade}</label>
                  <DropDown
                    disabled={disabled}
                    loading={fetchingDabTrade}
                    name="dabTrade"
                    onChange={onChange}
                    options={tradeCodesList.map((d) => ({
                      label: `${d.DabTradeId} - ${d.DabTradeDesc}`,
                      value: d.DabTradeId,
                    }))}
                    placeholder={lang.chooseADabTrade}
                    value={formData.dabTrade}
                  />
                </div>
                {/* Customer details */}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.customerDetail}</label>
                  <TextInput
                    disabled={disabled}
                    onChange={onChange}
                    className="input"
                    value={formData.customerDetail ? formData.customerDetail : ""}
                    name="customerDetail"
                  />
                </div>

                {/* Customer note */}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.customerNote}</label>
                  <TextInput
                    disabled={disabled}
                    onChange={onChange}
                    className="input"
                    value={formData.customerNote ? formData.customerNote : ""}
                    name="customerNote"
                  />
                </div>

                {/* Note */}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.note}</label>
                  <TextInput
                    disabled={disabled}
                    onChange={onChange}
                    className="input"
                    value={formData.note ? formData.note : ""}
                    name="note"
                  />
                </div>

                <div className="input-wrapper">
                  <label htmlFor="">Transport</label>
                  <div className="flex">
                    <Checkbox
                      checked={formData.transport}
                      disabled={disabled || formData.dabLocation === "OffShore"}
                      name="transport"
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className="input-wrapper">
                  <label htmlFor="">Heli Time</label>
                  <div className="flex">
                    <Checkbox
                      checked={formData.heliTime}
                      disabled={disabled || formData.dabLocation === "OnShore"}
                      name="heliTime"
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className="input-wrapper">
                  <label htmlFor="">{lang.standby}</label>
                  <div className="flex">
                    <Checkbox
                      checked={formData.standby}
                      disabled={disabled || formData.heliTime || formData.transport}
                      name="standby"
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className="input-wrapper">
                  <label htmlFor="">End of Rotation</label>{" "}
                  <div className="flex">
                    <Checkbox
                      checked={formData.endOfRotation}
                      disabled={disabled}
                      name="endOfRotation"
                      onChange={onChange}
                    />
                  </div>
                </div>

                {/* Date */}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.date}</label>
                  <DatePicker
                    className="input"
                    disabled={disabled}
                    onChange={onChange}
                    value={formData.date ? formData.date : ""}
                    name="date"
                  />
                </div>

                {/* Foreman */}
                {page === "admin" && (
                  <div className="input-wrapper">
                    <label htmlFor="">Foreman</label>
                    <DropDown
                      disabled={disabled}
                      name="foreman"
                      className="input"
                      onChange={onChange}
                      options={foremen.map((d) => ({ disabled: d.disabled, label: d.name, value: d.id }))}
                      placeholder="Choose a foreman"
                      value={formData.foreman}
                    />
                  </div>
                )}

                {/* Start time, */}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.startTime}</label>
                  <div className="time-input">
                    <TextInput
                      disabled={disabled}
                      onChange={(e) => onChangeTimeInputs(e, "hours", formData)}
                      name="startTimeHours"
                      type="number"
                      value={formData.startTimeHours ? formData.startTimeHours : ""}
                    />
                    <p style={{ padding: "0 0.5rem" }}>{` : `}</p>
                    <TextInput
                      disabled={disabled}
                      onChange={(e) => onChangeTimeInputs(e, "minutes", formData)}
                      name="startTimeMinutes"
                      type="number"
                      value={formData.startTimeMinutes ? formData.startTimeMinutes : ""}
                    />
                  </div>
                </div>

                {/* End time */}
                <div className="input-wrapper">
                  <label htmlFor="">{lang.endTime}</label>
                  <div className="time-input">
                    <TextInput
                      disabled={disabled}
                      onChange={(e) => onChangeTimeInputs(e, "hours", formData)}
                      name="endTimeHours"
                      type="number"
                      value={formData.endTimeHours ? formData.endTimeHours : ""}
                    />
                    <p style={{ padding: "0 0.5rem" }}>{` : `}</p>
                    <TextInput
                      disabled={disabled}
                      onChange={(e) => onChangeTimeInputs(e, "minutes", formData)}
                      name="endTimeMinutes"
                      type="number"
                      value={formData.endTimeMinutes ? formData.endTimeMinutes : ""}
                    />
                  </div>
                </div>

                {/* Hours */}

                <div className="input-wrapper">
                  <label htmlFor="">{lang.hours}</label>
                  <DropDown
                    disabled={disabled}
                    name="hours"
                    onChange={onChange}
                    options={hoursOptions.map((d) => ({
                      label: d.name,
                      value: d.value,
                    }))}
                    value={formData.hours}
                  />
                </div>

                <div className="input-wrapper">
                  <label htmlFor="">{lang.break}</label>
                  <Radio.Group
                    disabled={disabled}
                    name="break"
                    onChange={onChange}
                    size="large"
                    value={paidBreak ? "paid" : unpaidBreak ? "unpaid" : ""}
                  >
                    <Radio value="paid">Paid Break</Radio>
                    <Radio value="unpaid">Un Paid Break</Radio>
                  </Radio.Group>
                </div>
                <div className="input-wrapper">
                  <label htmlFor="">{lang.shift}</label>
                  <Radio.Group disabled={disabled} name="shift" onChange={onChange} size="large" value={shift}>
                    <Radio value="Day">Day</Radio>
                    <Radio value="Night">Night</Radio>
                  </Radio.Group>
                </div>
                <div className="input-wrapper">
                  <label htmlFor="">Change Shift</label>{" "}
                  <div className="flex">
                    <Checkbox checked={changeShift} disabled={disabled} name="changeShift" onChange={onChange} />
                  </div>
                </div>
                <div className="input-wrapper">
                  <label htmlFor="">{lang.addActivities}</label>
                  <div className="flex">
                    <DropDown
                      disabled={disabled}
                      loading={fetchingWageCodes}
                      name="activity"
                      onChange={(e) => setSelectedActivity(e.target.value)}
                      options={additionalData.wageCodes
                        .filter((code) => formData.activities.findIndex((el) => el.id === code.id) === -1)
                        .map((d) => ({ label: `${d.name} - ${d.id}`, value: d.id }))}
                      placeholder={lang.chooseExtras}
                      value={selectedActivity}
                    />

                    <Button
                      disabled={disabled}
                      style={{ lineHeight: "13px", flexShrink: 0 }}
                      fullWidth={false}
                      onClick={() => {
                        if (selectedActivity) {
                          let activity = additionalData.wageCodes.filter((x) => x.id === selectedActivity)[0];
                          activity.hours = "";
                          setFormData({ ...formData, activities: [activity, ...formData.activities] });
                        }
                      }}
                    >
                      {lang.add}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>

          {isFormReady && formData.activities && formData.activities.length !== 0 && (
            <div className={componentStyles(primaryColor)}>
              {formData.activities.map((d, i) => (
                <div className="input-wrapper" key={d.id}>
                  <label htmlFor="">{i === 0 ? "Extras" : ""}</label>
                  <div className="extras">
                    <p className="name">
                      {d.id} - {d.name}
                    </p>
                    <TextInput
                      disabled={disabled}
                      style={{ width: "20%" }}
                      name={d.name.toLowerCase().replace(/ /g, "")}
                      type="number"
                      value={d.hours}
                      onChange={(e) => {
                        d.hours = e.target.value; // Temporay mutation of map-element (is canceled on next render where change is committed)
                        setFormData({
                          ...formData,
                          activities: [...formData.activities.slice(0, i), d, ...formData.activities.slice(i + 1)],
                        });
                      }}
                    />
                    <div
                      onClick={() => {
                        setFormData({ ...formData, activities: formData.activities.filter((x) => d.id !== x.id) });
                      }}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {isFormReady && ifsData && (
            <div className={componentStyles(primaryColor)}>
              {ifsData.map((d, i) => (
                <div className="input-wrapper" key={d.WageCode}>
                  <label htmlFor="">{i === 0 ? lang.wageCode : ""}</label>
                  <div className="time-input">
                    <p>
                      {d.WageCode} - {d.WageCodeDesc}
                    </p>
                    <p style={{ padding: "0 0.5rem" }}>{` - `}</p>
                    {`${d.WageHours} ${parseFloat(d.WageHours) === 1 ? "hour" : "hours"}`}
                  </div>
                </div>
              ))}
            </div>
          )}

          {isFormReady && !!formData.hours && (
            <ForemanSubRecordForm
              deleteSubRecord={deleteSubRecord}
              duplicateRecord={duplicateRecord}
              enabled={!archived}
              form={formData}
              handleChange={onChange}
              sentToIFSAt={sentToIFSAt}
              subRecords={values(formData.subRecords)}
              lang={lang}
            />
          )}

          {isFormReady && !archived && !!formData.hours && !needsProcessing && (
            <button className={subRecordBtnStyles()} onClick={addSubRecord}>
              <PlusIcon /> <span>{lang.addUnderRegistration}</span>
            </button>
          )}
        </ScrollView>
        {!isMobile && isFormReady && !archived && !needsProcessing && (
          <div className={footerButtonStyles()}>
            <div className="col-75 checkboxContainer">
              {!!data.id && (
                <CheckboxGroup
                  options={[{ id: "manualApproval", title: lang.requiresManualApproval }]}
                  onSelect={(e) => onChange({ target: { name: "manualApproval", value: e } })}
                />
              )}
            </div>
            <div className="col-25">
              <Button disabled={isSubmitting} onClick={() => onSubmit(formData)}>
                {isSubmitting && (
                  <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                )}
                {lang.approved}
              </Button>
              {!needsProcessing && (
                <Popconfirm
                  disabled={isSubmitting}
                  title={lang.confirmText}
                  onConfirm={onDelete}
                  okText={lang.confirmYes}
                  cancelText={lang.confirmNo}
                >
                  <Button disabled={isSubmitting} styleType="error">
                    {isSubmitting || isDeleting ? (
                      <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                    ) : (
                      <DeleteIcon />
                    )}
                  </Button>
                </Popconfirm>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const isMobile = useMediaQuery({ maxWidth: "414px" });

  const close = () => {
    dispatch(toggleWorkHourModal(false));
  };

  return (
    <>
      {!isMobile && renderForm()}

      {isMobile && (
        <Modal
          centered
          footer={
            <div className={footerButtonStyles()}>
              <div className="col-75 checkboxContainer">
                {!!data.id && (
                  <CheckboxGroup
                    options={[{ id: "manualApproval", title: lang.requiresManualApproval }]}
                    onSelect={(e) => onChange({ target: { name: "manualApproval", value: e } })}
                  />
                )}
              </div>
              <div className="col-25">
                <Button disabled={isSubmitting} onClick={() => onSubmit(formData)}>
                  {isSubmitting && (
                    <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                  )}
                  {lang.approved}
                </Button>
                {!needsProcessing && (
                  <Popconfirm
                    disabled={isSubmitting}
                    title={lang.confirmText}
                    onConfirm={onDelete}
                    okText={lang.confirmYes}
                    cancelText={lang.confirmNo}
                  >
                    <Button disabled={isSubmitting} styleType="error">
                      {isSubmitting || isDeleting ? (
                        <InlineSpinner size="14" style={{ display: "inline-block", margin: "0 0.5rem -0.6rem 0rem" }} />
                      ) : (
                        <DeleteIcon />
                      )}
                    </Button>
                  </Popconfirm>
                )}
              </div>
            </div>
          }
          onCancel={close}
          visible={modal}
        >
          {renderForm()}
        </Modal>
      )}
    </>
  );
}

const scrollViewStyles = ({ bottomPanelHeight, topBarHeight }) => css`
  height: calc(100vh - ${topBarHeight}px - ${bottomPanelHeight}px);
  padding: 1rem;

  @media (max-width: 414px) {
    padding: unset;
  }
`;

const subRecordBtnStyles = () => css`
  align-items: center;
  background-color: transparent;
  border: none;
  color: #103447;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  margin-top: 1rem;
  margin-botom: 1rem;
  padding: 0.3rem;
  width: 100%;
`;

const componentStyles = (primaryColor) => css`
  background-color: ${colors.white};
  border-radius: 3px;
  border: 1px ${colors.midGrey} solid;
  border-left: 3px ${primaryColor} solid;
  padding: 0.65rem;
  margin-bottom: 10px;

  p {
    margin-bottom: 0px;
  }

  .deleted {
    width: 100%;
    text-align: center;
    background-color: red;
    color: #fff;
    padding: 10px;
    margin-bottom: 15px;
  }

  .processing {
    width: 100%;
    text-align: center;
    background-color: blue;
    color: #fff;
    padding: 10px;
    margin-bottom: 15px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &:last-of-type  {
      margin-bottom: 0;
    }

    & > label {
      width: 30%;
      text-align: right;
      padding-right: 0.65rem;
      font-size: 0.9rem;
      color: ${colors.darkGrey};
    }

    .input {
      width: 100%;
    }

    .time-input {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .extras {
      width: 100%;
      display: flex;
      align-items: center;
      border-top: 1px solid ${colors.lightGrey};
      padding: 0.75rem 0.75rem 0.75rem 0;
      margin: 0 -0.75rem 0 0rem;

      &:last-of-type {
        border-bottom: 1px solid ${colors.lightGrey};
      }

      .name {
        flex: 1 1 70%;
        padding: 0;
      }

      svg {
        height: 40px; /* WARNING: Magic number */
      }

      input {
        flex: 1 1 2rem;
        margin: 0 0.5rem 0 0.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    border-left: 1px ${colors.midGrey} solid;
  }

  @media (max-width: 414px) {
    border: none;
    padding: unset;
    margin-bottom: unset;

    .input-wrapper {
      align-items: flex-start;
      flex-direction: column;

      & > label {
        display: block;
        width: unset;
      }
    }
  }
`;

const footerButtonStyles = () => css`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: calc(100%);
  background-color: #f8f8f8;
  padding: 10px 10px 10px 0px;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  display: flex;
  border-top: 1px #103447 solid;

  .checkboxContainer {
    div {
      border: none;

      .checkbox {
        white-space: nowrap;
      }
    }
  }

  .col-25 {
    flex-basis: 25%;
    display: flex;

    button {
      margin-left: 5px;
    }
  }
  .col-75 {
    flex-basis: 75%;
  }

  @media screen and (max-width: 650px) {
    flex-wrap: wrap;

    .col-25,
    .col-75 {
      flex-basis: 100%;

      &:nth-child(odd) {
        margin-bottom: 1.25rem;
      }
    }
  }

  @media (max-width: 768px) {
    position: unset;
  }
  @media (max-width: 414px) {
    position: unset;
    padding: unset;
    background-color: unset;
    border: unset;
  }
`;

export default forwardRef(ForemanWorkHourForm);
