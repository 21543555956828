import { addDays, format, parse } from "date-fns";
import { intervalToDuration } from "date-fns/esm";

import zeroPad from "./zero-pad";

export default ({ date, startTimeHours, startTimeMinutes, endTimeHours, endTimeMinutes }) => {
  if (date && startTimeHours && startTimeMinutes && endTimeHours && endTimeMinutes) {
    const endDate =
      Number(`${startTimeHours}${startTimeMinutes}`) > Number(`${endTimeHours}${endTimeMinutes}`)
        ? format(addDays(parse(date, "yyyy-MM-dd", 0), 1), "yyyy-MM-dd")
        : date;

    const z = zeroPad;

    const parsedStartDate = parse(`${date}${z(startTimeHours)}${z(startTimeMinutes)}`, "yyyy-MM-ddHHmm", 0);
    const parsedEndDate = parse(`${endDate}${z(endTimeHours)}${z(endTimeMinutes)}`, "yyyy-MM-ddHHmm", 0);

    const interval = intervalToDuration({ start: parsedStartDate, end: parsedEndDate });

    return interval.hours + interval.minutes / 60;
  }

  return null;
};
