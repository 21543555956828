import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { notification } from 'antd';

import { css } from 'emotion';
import colors from '../../style/colors';

// Redux actions
import { showContextMenu, showModalPage, hideModalPage } from '../../actions/uiActions';
import { renewPasswordHandled, signInFailureHandled } from '../../actions/authActions';

// components
import Page from '../ui/Page';
import ContextMenu from '../ui/ContextMenu';
import { CellphoneIphoneIcon, EmailOutlineIcon, LifebuoyIcon, LockQuestionIcon, MicrosoftWindowsIcon } from 'mdi-react';
import loginTypes from './config/loginTypes';
import PhoneLogin from './PhoneLogin';
import EmailLogin from './EmailLogin';
import ForgotPassword from './ForgotPassword';
import AdLogin from './AdLogin';
import ModalPage from '../ui/ModalPage';
import Dialog from '../ui/Dialog';
import InlineSpinner from '../ui/InlineSpinner';

/** Page that log's in users
 * Is registered as a route and therefore doesn't need any props.
 *
 * The implementation of email login is a bit cramped in. I initially though the implementation
 * would be leaner by reusing the main functionality and just changing the input element but it
 * turned out a bit weird, so for the next login type we add i would argue for seperate login
 * components: a PhoneLogin.jsx, EmailLogin.jsx, ADLogin.jsx, EmployeeNubmerLogin.jsx etc.
 *
 */

//  Hack to prevent ad-login to start before appConfig has been updated
let firstRenderManual = true;

const appIcon =
	process.env.REACT_APP_CUSTOM_NODE_ENV === 'development'
		? `${process.env.PUBLIC_URL}/img/dev-icon.png`
		: process.env.REACT_APP_CUSTOM_NODE_ENV === 'staging'
		? `${process.env.PUBLIC_URL}/img/test-icon.png`
		: process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
		? `${process.env.PUBLIC_URL}/img/prod-icon.png`
		: '';

function LoginPage(props) {
	const [currentLoginType, setCurrentLoginType] = useState('');
	const [enableInput, setEnableInput] = useState(true);
	const dispatch = useDispatch();
	const appConfig = useSelector(s => s.appConfig);
	const appConfigLoading = useSelector(s => s.appConfig.loading);
	const { renewPasswordSuccess, signInError, signingIn } = useSelector(s => s.auth);

	// This effect makes sure that the app-config is refreshed before any redirects will happen
	useEffect(() => {
		if (firstRenderManual === false && appConfig.apiUrl !== '' && appConfig.loading === false) {
			getDefaultLoginType();
		}
		firstRenderManual = false;

		// eslint-disable-next-line
	}, [appConfig, appConfigLoading]);

	useEffect(() => {
		getDefaultLoginType();
		// eslint-disable-next-line
	}, []);

	function getDefaultLoginType() {
		/**
		 * Determines which login type to use as default. It will use the first in the array
		 * of allowed login types if the first is regonized. Otherwise it will default to "phone".
		 * The array is found in the app-config returned from the api
		 *
		 * Examples
		 * ["email", "phone", "ad"] -> default will be "email"
		 * ["phone", "email", "ad"] -> default will be "phone"
		 * ["blablaLoremIpsum", "email", "phone", "ad"] -> default will be "phone"
		 *
		 */

		let { allowedLoginTypes, title } = appConfig;

		if (!allowedLoginTypes || title === 'SemcoTime DK') allowedLoginTypes = [loginTypes.phone];

		switch (allowedLoginTypes[0]) {
			case loginTypes.phone:
				setCurrentLoginType(loginTypes.phone);
				break;
			case loginTypes.email:
				setCurrentLoginType(loginTypes.email);
				break;
			case loginTypes.ad:
				setCurrentLoginType(loginTypes.ad);
				break;
			default:
				setCurrentLoginType(loginTypes.phone);
		}
	}

	if (signInError) {
		dispatch(signInFailureHandled());
		notification.error({ duration: 7, message: 'FAILED', description: 'Your credentials were invalid' });
	}

	// If everything goes right when resetting password
	if (renewPasswordSuccess) {
		dispatch(renewPasswordHandled());
		notification.success({
			duration: 7,
			message: 'SUCCESS',
			description: 'You will receive a text message with your new password shortly'
		});
	}

	function showChangeLoginTypeMenu() {
		let actions = [
			{
				icon: <LockQuestionIcon />,
				title: 'Forgot password',
				callback: () => {
					setEnableInput(false);
					dispatch(
						showModalPage({
							title: 'Forgot password',
							closeCallback: () => {
								setEnableInput(true);
								dispatch(hideModalPage());
							},
							content: <ForgotPassword />
						})
					);
				}
			}
		];

		if (appConfig.allowedLoginTypes.includes(loginTypes.email) && currentLoginType !== loginTypes.email) {
			actions.push({
				icon: <EmailOutlineIcon />,
				title: 'Login with email',
				callback: () => setCurrentLoginType(loginTypes.email)
			});
		}

		if (appConfig.allowedLoginTypes.includes(loginTypes.phone) && currentLoginType !== loginTypes.phone) {
			actions.push({
				icon: <CellphoneIphoneIcon />,
				title: 'Login with phone',
				callback: () => setCurrentLoginType(loginTypes.phone)
			});
		}

		if (appConfig.allowedLoginTypes.includes(loginTypes.ad) && currentLoginType !== loginTypes.ad) {
			actions.push({
				icon: <MicrosoftWindowsIcon />,
				title: 'Login with AD',
				callback: () => setCurrentLoginType(loginTypes.ad)
			});
		}

		dispatch(showContextMenu({ actions: actions, defaultActionTitle: 'Cancel' }));
	}

	return (
		<Page className={componentStyle(appConfig)} style={{ justifyContent: 'space-between' }}>
			<div className="login-container">
				<div className="app-logo">
					<div className="loading-container" style={{ opacity: signingIn ? 1 : 0 }}>
						{signingIn && <InlineSpinner size={28} />}
					</div>
				</div>

				{/* Actual login part */}
				{currentLoginType === loginTypes.phone && (
					<PhoneLogin
						currentLoginType={currentLoginType}
						enableInput={enableInput}
						showChangeLoginTypeMenu={showChangeLoginTypeMenu}
					/>
				)}
				{currentLoginType === loginTypes.email && <EmailLogin enableInput={enableInput} />}
				{currentLoginType === loginTypes.ad && <AdLogin />}

				{currentLoginType !== '' && !isMobile && (
					<button className="toggle-input-types" onClick={showChangeLoginTypeMenu}>
						<LifebuoyIcon /> Need help?
					</button>
				)}
				{/* Load frontpage image so its cached and ready for frontpage */}
				<img
					alt=""
					style={{ display: 'none' }}
					src={`${appConfig.frontPageImage.baseURL}b_black,o_50,h_800,q_auto,f_auto/${appConfig.frontPageImage.image}`}
				/>
			</div>

			<ModalPage />
			<ContextMenu />
			<Dialog />
		</Page>
	);
}
const componentStyle = appConfig => css`
	.app-logo {
		width: 80px;
		height: 80px;
		border-radius: 18px;
		border: 1px ${colors.lightGrey} solid;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
		background-image: url(${appIcon});
		background-color: rgba(255, 255, 255, 0.75);
		background-size: cover;
		background-position: center;
		margin: 0 auto 5vh auto;
		position: relative;
		overflow: hidden;

		.loading-container {
			transition: opacity 400ms ease;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-color: rgba(255, 255, 255, 0.75);
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.login-container {
		margin: 10vh auto 0 auto;
		max-width: 400px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	button.toggle-input-types {
		display: block;
		font-size: 0.925rem;
		color: ${appConfig.primaryColor};
		padding: 0.5rem 1.45rem;
		border: 1px ${colors.midGrey} solid;
		border-radius: 1rem;
		outline: 0px;
		background-color: transparent;
		transition: background-color 200ms ease;
		margin-top: 2vh;
		&:active {
			background-color: ${colors.midGrey};
			transition: background-color 80ms ease;
		}
		svg {
			width: 1rem;
			height: 1rem;
			margin-bottom: -0.15rem;
			margin-right: 0.35rem;
		}
	}
	div.input {
		display: inline-block;
		padding: 0.75rem;
		margin: 0 auto;
		font-size: 1.3rem;
		letter-spacing: 2px;
		color: ${colors.black};
		white-space: nowrap;
		font-weight: 700;
		&.phone p,
		&.password .password-dots-container {
			display: inline-block;
			margin-left: 1rem;
		}
		&.phone {
			min-width: 179px;
		}
		&.email {
			height: 52px; /* Sry. Another magic number */
		}

		&.email input {
			padding: 0.5rem;
			border: 1px ${colors.midGrey} solid;
			appearance: none;
			border-radius: 2px;
			background-color: rgba(0, 0, 0, 0.03);
			margin-left: 0.5rem;
			font-size: 0.85rem;

			&:focus {
				outline: 0;
				border: 1px ${appConfig.primaryColor} solid;
			}
		}

		svg {
			color: ${colors.darkGrey};
			vertical-align: middle;
			width: 1.5rem;
			height: 1.5rem;
			margin-top: -5px;

			&.lock-icon {
				margin-top: -18px;
			}
		}
	}

	/* Phone cursor */
	.phone .phone-cursor {
		width: 16px;
		height: 3px;
		background-color: ${colors.midGrey};
		display: inline-block;
		vertical-align: text-bottom;
		animation: cursorBlinkPhone 1s linear infinite;
	}

	@keyframes cursorBlinkPhone {
		0% {
			opacity: 0;
		}
		10% {
			opacity: 1;
		}
		60% {
			opacity: 1;
		}
		70% {
			opacity: 0;
		}
		100% {
			opacity: 0;
		}
	}

	/* Password dots */
	.password-dots-container {
		.password-dot {
			display: inline-block;
			width: 22px;
			height: 22px;
			margin-right: 0.4rem;
			border-radius: 50%;
			border: 1px ${colors.midGrey} solid;

			&.blink {
				animation: cursorBlinkPassword 1s linear infinite;
			}

			@keyframes cursorBlinkPassword {
				0% {
					border-width: 1px;
				}
				5% {
					border-width: 4px;
					border-color: ${appConfig.primaryColor};
				}
				53% {
					border-width: 4px;
					border-color: ${appConfig.primaryColor};
				}
				60% {
					border-width: 1px;
				}
			}

			&.filled {
				background-color: ${appConfig.primaryColor};
				border: 0px;
			}
		}
	}
`;

export default LoginPage;
