import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { css } from "emotion";
import { CheckBoxOutlineBlankIcon, CheckboxMarkedIcon } from "mdi-react";
import PropTypes from "prop-types";
//Style
import common from "../../style/common";
import colors from "../../style/colors";

/**
 * List for selection of multiple elements. If you need a list with only one selectable element at a time, see RadioGroup
 */
const CheckboxGroup = (props) => {
  const { appConfig, disabled = false, onSelect: onSelectOutside, options = [], selected = [], style = {} } = props;
  const { primaryColor } = appConfig;

  const [selectedIds, setSelectedIds] = useState([]);

  const onSelect = (checkboxId) => {
    let checkboxIdIndex = selectedIds.indexOf(checkboxId);

    if (checkboxIdIndex === -1) {
      setSelectedIds((selectedIds) => {
        const newIds = [...selectedIds, checkboxId];
        onSelectOutside(newIds);
        return newIds;
      });
    } else {
      setSelectedIds((selectedIds) => {
        const newIds = [...selectedIds.slice(0, checkboxIdIndex), ...selectedIds.slice(checkboxIdIndex + 1)];
        onSelectOutside(newIds);
        return newIds;
      });
    }
  };

  const stringifiedSelected = JSON.stringify(selected);

  useEffect(() => {
    setSelectedIds(selected); // eslint-disable-next-line
  }, [stringifiedSelected]);

  return (
    <div className={componentStyle(primaryColor)} style={style}>
      {options.map((checkbox, index) => (
        <label htmlFor={checkbox.id} onClick={() => (!disabled ? onSelect(checkbox.id) : null)} key={index}>
          {selectedIds.indexOf(checkbox.id) !== -1 ? (
            <div className="checkbox selected">
              <CheckboxMarkedIcon />
              <p>{checkbox.title}</p>
            </div>
          ) : (
            <div className="checkbox">
              <CheckBoxOutlineBlankIcon />
              <p>{checkbox.title}</p>
            </div>
          )}
        </label>
      ))}
    </div>
  );
};
const componentStyle = (primaryColor) => css`
  border: 1px solid ${colors.midGrey};
  border-radius: 3px;

  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-appearance: none;
    border-bottom: 1px solid ${colors.midGrey};
    padding: 0.75rem 0.85rem;
    font-weight: 400;

    svg {
      color: ${colors.midGrey};
      flex-shrink: 0;
    }

    p {
      margin-left: 0.5rem;
      font-size: 1rem;
      font-family: ${common.fontStack};
    }

    &.selected,
    &.selected svg {
      font-weight: 700;
      color: ${primaryColor};
    }
  }
  label:last-child div {
    border-bottom: none;
  }
`;
CheckboxGroup.propTypes = {
  /** Array of items to be displayed, its structure should be  [ { id: XX, title: "YY" } ]*/
  options: PropTypes.array,

  /** Overriding of style in component, if you have very specific needs */
  style: PropTypes.object,
};
const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
});

export default connect(mapStateToProps, null)(CheckboxGroup);
