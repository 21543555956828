import React from 'react';
import { List, Tag, Typography } from 'antd';

import moment from 'moment';

const TimesheetListItem = props => {
	const { employeeView = false, sheets = [], setSelectedTimesheet, setToggleModal } = props;

	return (
		<List
			bordered
			dataSource={sheets}
			renderItem={sheet => {
				const { id, blueCollarName, foremanName, canceled, submittedDate, signed, canceledBySemsupport } =
					sheet;

				const date = moment(submittedDate, 'YYYYMMDDHHmmss').format('DD-MM-YYYY HH:mm');
				const actionTags = [];

				let status = 'Pending';
				if (signed) status = 'Signed';
				if (canceled) status = 'Canceled';
				if (canceledBySemsupport) status = 'Corrected';

				if (status !== 'Pending')
					actionTags.push(<Tag color={status === 'Signed' ? 'green' : 'red'}>{status}</Tag>);

				if (employeeView && status === 'Pending') actionTags.push(<Tag color="orange">Pending</Tag>);

				actionTags.push(<b>{`ID : ${id}`}</b>);

				const listTitle = employeeView ? `Requested to ${foremanName}` : `Requested by ${blueCollarName} `;
				return (
					<List.Item
						actions={actionTags}
						style={{ cursor: 'pointer' }}
						key={id}
						onClick={() => {
							setSelectedTimesheet(sheet);
							setToggleModal(true);
						}}>
						<Typography.Text>{`${date} - ${listTitle} `} </Typography.Text>
					</List.Item>
				);
			}}
		/>
	);
};

export default TimesheetListItem;
