import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Popconfirm } from 'antd';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import PDFRenderComponent from '../../../ui/PDFRenderComponent';

const TimesheetRenderModal = props => {
	const language = useSelector(s => s.language.language);

	const {
		employeeView = false,
		isTimesheetSubmitting,
		onCancelTimesheetRequest,
		onApproveTimesheet,
		selectedTimesheet = {},
		setDraftSignedTimesheet,
		setToggleModal,
		setToggleSignaturePadModal,
		toggleModal,
		draftSignedTimesheet = null
	} = props;

	const {
		blueCollarName,
		canceled,
		canceledBy,
		canceledDate,
		canceledBySemsupport,
		foremanName,
		id,
		originalFile,
		signed,
		signedBy,
		signedDate,
		signedFile,
		submittedDate
	} = selectedTimesheet;

	const isMobile = useMediaQuery({ maxWidth: '1024px' });
	const date = moment(submittedDate, 'YYYYMMDDHHmmss').format('DD-MM-YYYY HH:mm');
	const status = signed ? 'Signed' : canceled ? 'Canceled' : 'Pending';
	const updatedBy = signed ? signedBy : canceledBy;
	const modalTitle = employeeView ? `Requested to ${foremanName}` : `Requested by ${blueCollarName} `;

	let PDFFile = originalFile;
	if (signed || (canceledBySemsupport && canceled)) PDFFile = signedFile;
	if (draftSignedTimesheet !== null) PDFFile = draftSignedTimesheet;

	const footerButtons = [];

	if (!employeeView && status === 'Pending')
		footerButtons.push(
			<div style={isMobile ? { display: 'flex', flexDirection: 'column' } : {}}>
				<Popconfirm
					key="cancel"
					title="Are you sure to cancel this request?"
					onConfirm={() => onCancelTimesheetRequest(id)}
					okText="Yes"
					cancelText="No">
					<Button style={{ margin: '5px' }} loading={isTimesheetSubmitting} type="primary" danger>
						{language.timesheetCancelRequest}
					</Button>
				</Popconfirm>
				<Button
					loading={isTimesheetSubmitting}
					onClick={() => setToggleSignaturePadModal(true)}
					key="draft"
					type="primary"
					style={{ margin: '5px' }}>
					{`${draftSignedTimesheet ? 'Edit' : 'Add'} Signature`}
				</Button>
				{draftSignedTimesheet && (
					<Button
						loading={isTimesheetSubmitting}
						onClick={() => onApproveTimesheet(id)}
						key="save"
						style={{ margin: '5px' }}
						type="primary">
						Approve Timesheet
					</Button>
				)}
			</div>
		);

	if (status !== 'Pending') {
		const updateTimeStamp = status === 'Signed' ? signedDate : canceledDate;

		footerButtons.unshift(
			<p style={{ fontWeight: 'bold' }}>{`${status} on ${moment(updateTimeStamp, 'YYYYMMDDHHmmss').format(
				'DD-MM-YYYY HH:mm'
			)} by ${updatedBy} ${canceledBySemsupport ? ' (Record Corrected)' : ''}`}</p>
		);
	}

	const handleClose = () => {
		if (!draftSignedTimesheet) return setToggleModal(false);

		if (draftSignedTimesheet) {
			Modal.confirm({
				title: 'Signature will be lost, are you sure to cancel?',
				onOk: () => {
					setDraftSignedTimesheet(null);
					setToggleModal(false);
				}
			});
		}
	};

	return (
		<Modal
			maskClosable={false}
			title={`Request ID : ${id} |  ${date} - ${modalTitle}`}
			visible={toggleModal}
			width={isMobile ? '80%' : 'fit-content'}
			onCancel={handleClose}
			footer={footerButtons}>
			<div style={{ overflowX: 'auto', width: isMobile ? 'auto' : 'fit-content' }}>
				<PDFRenderComponent size="A2" pdfData={PDFFile} />
			</div>
		</Modal>
	);
};

export default TimesheetRenderModal;
