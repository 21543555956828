import React from 'react';
import { css } from 'emotion';
import { BackspaceIcon } from 'mdi-react';
import { CSSTransition } from 'react-transition-group';
import { isMobile } from 'react-device-detect';
import colors from '../../style/colors';
import { durations } from '../../config/animations';

const Numpad = props => {
	const { active } = props;
	return (
		<CSSTransition
			in={active}
			timeout={durations.normal}
			mountOnEnter={true}
			unmountOnExit={true}
			classNames="numpad">
			<table className={componentStyle()}>
				<tbody>
					<tr>
						<td data-key="1" onClick={() => props.onKeyPress('1')}>
							1
						</td>
						<td data-key="2" onClick={() => props.onKeyPress('2')}>
							2
						</td>
						<td data-key="3" onClick={() => props.onKeyPress('3')}>
							3
						</td>
					</tr>
					<tr>
						<td data-key="4" onClick={() => props.onKeyPress('4')}>
							4
						</td>
						<td data-key="5" onClick={() => props.onKeyPress('5')}>
							5
						</td>
						<td data-key="6" onClick={() => props.onKeyPress('6')}>
							6
						</td>
					</tr>
					<tr>
						<td data-key="7" onClick={() => props.onKeyPress('7')}>
							7
						</td>
						<td data-key="8" onClick={() => props.onKeyPress('8')}>
							8
						</td>
						<td data-key="9" onClick={() => props.onKeyPress('9')}>
							9
						</td>
					</tr>
					<tr>
						<td data-key="delete" onClick={() => props.onKeyPress('delete')}>
							<BackspaceIcon />
						</td>
						<td data-key="0" onClick={() => props.onKeyPress('0')}>
							0
						</td>
						<td data-key="+" onClick={() => props.onKeyPress('+')}>
							+
						</td>
					</tr>
				</tbody>
			</table>
		</CSSTransition>
	);
};

const componentStyle = () => css`
	overflow: hidden;
	border-collapse: collapse;
	background-color: ${colors.white};
	width: 100%;
	height: 40vh;
	max-height: 300px;
	min-height: 150px;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.07);
	position: ${isMobile ? 'fixed' : 'absolute'};
	margin-top: ${isMobile ? '15' : '0'}px;
	bottom: 0;

	@media screen and (min-width: 1000px) {
		width: 800px;
		margin: 0 auto 1rem auto;
		border: 1px ${colors.lightGrey} solid;
		border-radius: 4px;
		box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.1);
	}

	&.numpad-enter {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
	&.numpad-enter-active {
		opacity: 1;
		transform: translate3d(0, 0px, 0);
		transition: opacity ${durations.normal}ms, transform ${durations.normal}ms;
	}
	&.numpad-exit {
		opacity: 1;
		transform: translate3d(0, 0px, 0);
	}
	&.numpad-exit-active {
		opacity: 0;

		transform: translate3d(0, 100%, 0);
		transition: opacity ${durations.normal}ms, transform ${durations.normal}ms;
	}

	td {
		border: 1px ${colors.lightGrey} solid;
		border-top: 1px ${colors.midLightGrey} solid;
		width: 33%;
		height: 25%;
		text-align: center;
		font-family: 'Open Sans', sans-serif;
		font-weight: 700;
		color: ${colors.black};
		font-size: 1rem;
		transition: background-color 100ms ease;
		transition-delay: 100ms;

		&:active {
			background-color: ${colors.ultraLightGrey};
			transition: background-color 60ms ease;
		}
	}

	svg {
		color: ${colors.darkGrey};
	}
`;

export default Numpad;
