import React, { useState, useEffect } from "react";
import { css } from "emotion";
import ScrollView from "./ScrollView";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { durations } from "../../config/animations";

/** Used in conjunction with tabBar component. This component shows whatever content, that is in the current tab  */
const TabView = props => {
  const { activeTabIndex, tabs, useScrollView, style } = props;
  const [animationDirection, setAnimationDirection] = useState("");
  const [currActiveTabIndex, setCurrActiveTabIndex] = useState(0);
  const [scrollMap, setScrollMap] = useState({});

  useEffect(() => {
    // Set direction
    if (currActiveTabIndex >= activeTabIndex) {
      setAnimationDirection("left");
    } else {
      setAnimationDirection("right");
    }

    // Set state variable to actually trigger tab change
    setCurrActiveTabIndex(activeTabIndex);

    // Restores scroll-position
    if (scrollMap[activeTabIndex]) {
      // Wait for next frame (div will not be mounted before repaint)
      requestAnimationFrame(() => {
        // Update scroll pos
        document.getElementById(`scroll-view-${activeTabIndex}`).scrollTo(0, scrollMap[activeTabIndex]);
      });
    }

    // eslint-disable-next-line
  }, [activeTabIndex]);

  // Updates map of stored scroll positions. Consider debouncing this. I saw no perfomance issues though
  function updateScrollMap(index, scrollTop) {
    setScrollMap({
      ...scrollMap,
      [index]: scrollTop
    });
  }

  return (
    <div style={style} className={`tab-view ${componentStyle(activeTabIndex)}`} onScroll={props.onScroll}>
      {tabs.map((tab, index) => (
        <CSSTransition
          key={index}
          timeout={durations.normal}
          classNames={`tab-${animationDirection}`}
          in={index === currActiveTabIndex}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          {useScrollView === false ? (
            <div style={props.tabStyle} className="tab" key={index}>
              {tab}
            </div>
          ) : (
            <ScrollView
              style={props.tabStyle}
              className="tab"
              key={index}
              id={`scroll-view-${index}`}
              onScroll={e => updateScrollMap(index, e.target.scrollTop)}
              onScrollEnd={() => (props.onScrollEnd ? props.onScrollEnd(index) : null)}
              data-test-id="scroll-view"
            >
              {tab}
            </ScrollView>
          )}
        </CSSTransition>
      ))}
    </div>
  );
};

const componentStyle = tabIndex => css`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  .tab {
    position: absolute;
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  .tab-left-enter {
    opacity: 0;
    transform: translateX(-200px);
  }
  .tab-left-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }
  .tab-left-exit {
    opacity: 1;
    transform: translateX(0px);
  }
  .tab-left-exit-active {
    opacity: 0;
    transform: translateX(200px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }

  .tab-right-enter {
    opacity: 0;
    transform: translateX(200px);
  }
  .tab-right-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }
  .tab-right-exit {
    opacity: 1;
    transform: translateX(0px);
  }
  .tab-right-exit-active {
    opacity: 0;
    transform: translateX(-200px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }
`;

TabView.propTypes = {
  /** Array of all possible tabs */
  tabs: PropTypes.array,
  /** index of current active Tab */
  activeTabIndex: PropTypes.number,
  /** Function to be executed when the user scrolls on the current tab, can be used to enable lazyloading */
  onScroll: PropTypes.func,
  /** Boolean to indicate if the current implementation of tabView uses Scrollview component */
  useScrollView: PropTypes.bool,
  /** Object to override style on tabs */
  tabStyle: PropTypes.object
};
export default TabView;
