import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { format, subDays } from "date-fns";
import params from "jquery-param";

// css
import { ArrowBackIcon, AlertDecagramIcon, ThumbsUpOutlineIcon } from "mdi-react";

// utilities
import pageNavigator from "../../utilities/page-navigator";
import req from "../../utilities/request-utility";
import usePageAndBackButtonURL from "../../hooks/usePageAndBackButtonURL";

// ui-components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import CorrectionListItem from "./CorrectionListItem";

function CorrectionsOverview(props) {
  const lang = useSelector((s) => s.language.language);
  const selectedUser = useSelector((s) => s.users.selectedUser);

  const { page, backButtonURL } = usePageAndBackButtonURL(props);
  const [state, setState] = useState({
    loading: true,
    error: false,
    registrations: {},
  });

  useEffect(() => {
    const toDate = format(new Date(), "yyyyMMdd");
    const fromDate = format(subDays(new Date(), 90), "yyyyMMdd");

    req()(`semcotime/corrections/v3?${params({ externalId: selectedUser, fromDate, toDate })}`)
      .then(({ data }) => setState({ ...state, registrations: data, loading: false, error: false }))
      .catch((err) => {
        setState({ ...state, loading: false, error: true });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <TopBar title={page.title} actionLeft={<ArrowBackIcon onClick={() => pageNavigator(backButtonURL, "backward")} />} />
      <ScrollView style={{ padding: "1rem 0" }}>
        {/* Loading */}
        {state.loading && !state.error && <InlineSpinner />}

        {/* Error */}
        {state.error && !state.loading && (
          <StatusBox
            style={{ marginTop: "2rem" }}
            icon={<AlertDecagramIcon />}
            title={lang.errorLoadingRegistrationToastTitle}
            content={lang.errorLoadingRegistrationToastContent}
          />
        )}

        {/* No data */}
        {!state.error && !state.loading && Object.keys(state.registrations).length === 0 && (
          <StatusBox
            style={{ marginTop: "2rem" }}
            icon={<ThumbsUpOutlineIcon />}
            // title={"Du må være god til at registrere dine timer!"}
            title={lang.noCorrectionsTitle}
            content={lang.noCorrectionsContent}
            // content={"Du har ingen ulæste corrections for de seneste 90 dage"}
          />
        )}

        {/* Load registrations   */}
        {!state.error &&
          !state.loading &&
          Object.keys(state.registrations).map((key) => (
            <CorrectionListItem key={key} title={key} registration={state.registrations[key]} />
          ))}
      </ScrollView>
    </Page>
  );
}

export default CorrectionsOverview;
