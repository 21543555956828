import React, { useEffect, useState } from 'react';
import { ArrowBackIcon, InfoOutlineIcon, MagnifyIcon, CircleMediumIcon } from 'mdi-react';
import { Button, Col, Collapse, Descriptions, notification, Row, Table } from 'antd';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { css } from 'emotion';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import param from 'jquery-param';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';

import { useMediaQuery } from 'react-responsive';

import usePageAndBackButtonURL from '../../../hooks/usePageAndBackButtonURL';
import pageNavigator from '../../../utilities/page-navigator';
import req from '../../../utilities/request-utility';

import colors from '../../../style/colors';
import InlineSpinner from '../../ui/InlineSpinner';
import StatusBox from '../../ui/StatusBox';
import DropDown from '../../ui/DropDown';
import ActionWrapper from '../../ui/ActionWrapper';
import ComponentPage from '../../ui/Page';
import TopBar from '../../ui/TopBar';

function WorkTimeCorrectionsTimeAppSide(props) {
	const { user = {} } = props;
	const { id: userAppId = '', loggedUserTypeDK = 'user' } = user;
	const { page, backButtonURL } = usePageAndBackButtonURL(props);
	const [getAllUnreadCorrections, setGetAllUnreadCorrections] = useState(true);

	const { Panel } = Collapse;
	const isMobile = useMediaQuery({ maxWidth: '414px' });
	const queryClient = useQueryClient();

	const defaultValues = {
		blueCollarId: userAppId,
		fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
		toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd')
	};

	const {
		control,
		formState: { errors },
		getValues,
		reset,
		setValue,
		handleSubmit,
		watch
	} = useForm({
		defaultValues,
		resolver: yupResolver(schema)
	});

	const {
		data: { data: foremanTeamMembers = {} } = {},
		isFetching: foremanTeamMembersIsFetching,
		isLoading: foremanTeamMembersIsLoading,
		refetch: foremanTeamMembersRefetch
	} = useQuery(
		['GetForemanTeamMembers'],
		() => {
			if (loggedUserTypeDK === 'admin') return req()(`semcotime/foremen-teams/my-team/all`);

			return req()(`semcotime/foremen-teams/my-team`);
		},
		{
			staleTime: 30 * 1000
		}
	);

	const onSubmit = async () => {
		try {
			const { blueCollarId = '', fromDate = '', toDate = '' } = watch();

			const { data } = await req()(
				`semcotime/corrections/time-app?${param({
					blueCollarId,
					fromDate,
					toDate,
					getAllUnreadCorrections
				})}`,
				{
					responseType: 'application/json'
				}
			);

			return data;
		} catch (err) {
			const { status } = err.response;
			console.log('status: ', status);
		}
	};

	const {
		data: correctionsData = [],
		isFetching: correctionsDataIsFetching,
		isLoading: correctionsDataIsLoading,
		refetch: correctionsDataRefetch,
		error: correctionsDataError
	} = useQuery(['getCorrectionsTimeApp'], onSubmit, {
		// enabled: false,
		retry: false,
		refetchOnWindowFocus: false
	});

	const markCorrectionRead = async id => await req().put(`semcotime/corrections/time-app/markAsRead/${id}`);

	const { mutateAsync: updateSavedJobMutate } = useMutation(markCorrectionRead, {
		onSuccess: data => {
			queryClient.setQueryData('getCorrectionsTimeApp', old =>
				old.map(d =>
					d.originalData.id == data.data.recordId
						? {
								...d,
								isCorrectionRead: true
						  }
						: d
				)
			);
		}
	});

	const schema = yup.object().shape({
		blueCollarId: yup.string().required('User is required'),
		foremanId: yup.string(),
		fromDate: yup.string().required('From date is required'),
		toDate: yup.string().required('To date is required')
	});
	// crude work around
	const timeRegistrationsTypes = {
		1: 'Work Hours',
		2: 'Standby',
		3: 'Paid Rest',
		4: 'Driving Hours',
		5: 'Heli Time Out',
		6: 'Heli Time Home',
		7: 'Waiting Hours'
	};

	const renderCorrectedRegistrations = (key, value, record) => {
		const { data, status } = record;

		return (
			<span
				style={{
					fontWeight: status === 'updated' && data[key] ? 'bold' : ''
				}}>
				{key == 'type' ? timeRegistrationsTypes[value] : value}
			</span>
		);
	};

	const renderCorrectedFormFields = (key, formChanges, originalData) => {
		const isUpdated = formChanges[key];
		const fieldValue = isUpdated ? formChanges[key] : originalData[key];

		return (
			<p
				style={{
					color: isUpdated ? '#ffa500' : 'black'
				}}>
				{fieldValue}
			</p>
		);
	};

	useEffect(() => {
		(async () => {
			await correctionsDataRefetch();
			setGetAllUnreadCorrections(false);
		})();
	}, []);

	return (
		<ComponentPage className={componentStyles(loggedUserTypeDK === 'user' ? true : false)}>
			<TopBar
				title={page.title}
				actionLeft={
					<ActionWrapper onClick={() => pageNavigator(backButtonURL, 'backward')}>
						<ArrowBackIcon />
					</ActionWrapper>
				}
			/>
			<div className="date-selector-bar">
				{loggedUserTypeDK != 'user' && (
					<div className="dropdown-fields">
						<div>
							<Controller
								name="blueCollarId"
								control={control}
								render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
									return (
										<DropDown
											name="blueCollarId"
											placeholder="Select a User"
											onChange={e => onChange(e)}
											loading={foremanTeamMembersIsLoading}
											options={
												!foremanTeamMembersIsLoading
													? foremanTeamMembers
															.filter(d => d.externalId)
															.map(d => ({
																label: `${d.name} | Ticket No. ${
																	d.ticketNumber ? d.ticketNumber : 'N/A'
																}`,
																value: d.id
															}))
													: []
											}
											value={value}
										/>
									);
								}}
							/>
						</div>
					</div>
				)}
				<div className="right">
					<div className="date-inputs">
						<p>From: </p>
						<Controller
							name="fromDate"
							control={control}
							render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
								return <input value={value} type="date" name="fromDate" onChange={e => onChange(e)} />;
							}}
						/>
						<p>To: </p>
						<Controller
							name="toDate"
							control={control}
							render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
								return <input value={value} type="date" name="toDate" onChange={e => onChange(e)} />;
							}}
						/>
					</div>
					<div className="buttons">
						<Button disabled={correctionsData.loading} onClick={correctionsDataRefetch}>
							<MagnifyIcon />
						</Button>
					</div>
				</div>
			</div>
			{correctionsDataIsFetching && (
				<InlineSpinner style={{ marginTop: '2rem' }} title="Loading Corrections..." />
			)}
			{!correctionsDataIsFetching && correctionsData.length === 0 && (
				<StatusBox
					style={{ marginTop: '2rem' }}
					title="No results"
					content="No results found. Try with different dates"
					icon={<InfoOutlineIcon />}></StatusBox>
			)}
			{!correctionsDataIsFetching && correctionsDataError && (
				<StatusBox
					style={{ marginTop: '2rem' }}
					title="Something went wrong"
					icon={<InfoOutlineIcon />}></StatusBox>
			)}

			<br />
			{!correctionsDataIsFetching && !isEmpty(correctionsData) && (
				<div className="inline-scroll-wrapper" id="custom-scroll-wrapper-kasd872jhKsd72">
					<Collapse
						accordion={true}
						onChange={e => {
							if (e) updateSavedJobMutate(e);
						}}>
						{correctionsData.map((d, i) => {
							const {
								date,
								isCorrectionRead = false,
								originalData,
								formChanges,
								foremanLastEditedBy,
								formattedRegistrations
							} = d;

							return (
								<Panel
									header={`${date} - Corrected by ${foremanLastEditedBy}`}
									key={originalData.id}
									extra={!isCorrectionRead ? <CircleMediumIcon color="red" /> : null}>
									<Row gutter={[16, 16]}>
										<Col xs={24} md={24} lg={12}>
											<Descriptions
												layout={isMobile ? 'vertical' : 'horizontal'}
												title="Original"
												bordered={true}
												size="small">
												<Descriptions.Item label="Project">
													{originalData.project}
												</Descriptions.Item>
												<Descriptions.Item label="Sub Project">
													{originalData.subProject}
												</Descriptions.Item>
												<Descriptions.Item label="Activity">
													{originalData.activity}
												</Descriptions.Item>
												<Descriptions.Item label="Location">
													{originalData.location}
												</Descriptions.Item>
												<Descriptions.Item label="Trade">
													{originalData.trade}
												</Descriptions.Item>
												<Descriptions.Item label="Foreman">
													{originalData.foreman}
												</Descriptions.Item>
												<Descriptions.Item label="Time Registrations">
													<Table
														bordered={true}
														columns={[
															{
																title: 'Type',
																dataIndex: 'type',
																key: 'type',
																render: type => (
																	<span>{timeRegistrationsTypes[type]}</span>
																)
															},
															{
																title: 'From',
																dataIndex: 'startTime',
																key: 'startTime'
															},
															{
																title: 'To',
																dataIndex: 'endTime',
																key: 'endTime'
															}
														]}
														dataSource={originalData.timeRegistrations}
														size="small"
														pagination={false}
													/>
												</Descriptions.Item>
											</Descriptions>
										</Col>

										<Col xs={24} md={24} lg={12}>
											<Descriptions
												layout={isMobile ? 'vertical' : 'horizontal'}
												title="Corrected"
												bordered={true}
												size="small">
												<Descriptions.Item label="Project">
													{renderCorrectedFormFields('project', formChanges, originalData)}
												</Descriptions.Item>
												<Descriptions.Item label="Sub Project">
													{renderCorrectedFormFields('subProject', formChanges, originalData)}
												</Descriptions.Item>
												<Descriptions.Item label="Activity">
													{renderCorrectedFormFields('activity', formChanges, originalData)}
												</Descriptions.Item>
												<Descriptions.Item label="Location">
													{renderCorrectedFormFields('location', formChanges, originalData)}
												</Descriptions.Item>
												<Descriptions.Item label="Trade">
													{renderCorrectedFormFields('trade', formChanges, originalData)}
												</Descriptions.Item>
												<Descriptions.Item label="Foreman">
													{renderCorrectedFormFields('foreman', formChanges, originalData)}
												</Descriptions.Item>
												<Descriptions.Item label="Time Registrations">
													<Table
														bordered={true}
														columns={[
															{
																title: 'Type',
																dataIndex: 'type',
																key: 'type',
																render: (value, record) =>
																	renderCorrectedRegistrations('type', value, record)
															},
															{
																title: 'From',
																dataIndex: 'startTime',
																key: 'startTime',
																render: (value, record) =>
																	renderCorrectedRegistrations(
																		'startTime',
																		value,
																		record
																	)
															},
															{
																title: 'To',
																dataIndex: 'endTime',
																key: 'endTime',
																render: (value, record) =>
																	renderCorrectedRegistrations(
																		'endTime',
																		value,
																		record
																	)
															}
														]}
														dataSource={formattedRegistrations}
														rowClassName={row => {
															if (row.status === 'added') return 'added-row';
															if (row.status === 'updated') return 'updated-row';
														}}
														size="small"
														pagination={false}
													/>
												</Descriptions.Item>
											</Descriptions>
										</Col>
									</Row>
								</Panel>
							);
						})}
					</Collapse>
				</div>
			)}

			<br />
		</ComponentPage>
	);
}

const componentStyles = isUser => css`
	.inline-scroll-wrapper {
		overflow-x: auto;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		.react-pdf__Page__canvas {
			margin: 0 auto !important;
		}

		.ant-descriptions-item-label {
			background: #103447;
			color: white;
			font-weight: 600;
		}

		.added-row {
			background-color: #32cd32;
			font-weight: bold;
		}

		.updated-row {
			background-color: #ffa500;
		}
	}

	.date-selector-bar {
		height: 55px;
		border-bottom: 1px ${colors.midGrey} solid;
		background-color: ${colors.white};
		display: flex;
		align-items: center;
		justify-content: ${isUser ? 'end' : 'space-between'};
		font-size: 1rem;
		padding-right: 15px;
		padding-left: 15px;

		.dropdown-fields {
			width: 35%;
			display: flex;
			justify-content: space-evenly;

			& > div {
				flex-grow: 1;
			}
		}

		.right {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.date-inputs {
			display: contents;
		}

		input {
			margin: 0 0.5rem;
			color: ${colors.darkGrey};
			border: 1px ${colors.darkGrey} dashed;
			border-width: 0 0 1px 0;
			font-size: 1rem;
		}
	}

	@media screen and (max-width: 769px) {
		.date-selector-bar {
			display: flex;
			flex-direction: column;
			height: initial;
			gap: 15px;
			// justify-content: ${isUser ? 'start' : 'space-between'};

			.dropdown-fields {
				margin-top: 10px;
				width: 100%;
			}

			.right {
				display: block;

				.date-inputs {
					display: inline-flex;
				}

				.buttons {
					margin-top: 10px;
					display: flex;
					justify-content: center;
					margin-bottom: 10px;
				}
			}
		}
	}
`;

export default WorkTimeCorrectionsTimeAppSide;
