import React, { Component } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import TinyAnimate from "TinyAnimate";

import colors from "../../style/colors";
import common from "../../style/common";
import PropTypes from "prop-types";
import { disableBodyScroll } from "body-scroll-lock";

/** used for adding tab functionality to a component, should always be played just under Topbar component
 * Remember to add Tabview component, if you want to make this work
 * 
 * ```jsx
 * 
        <TabBar
          activeTabIndex={activeTab}
          tabs={tabs.map((tab, tabIndex) => ({
            title: tab,
            onClick: () => {
              this.setState({ activeTab: tabIndex });
            }
          }))}
        />
        SOME CODE LATER
        <TabView
          tabStyle={{ padding: "1rem 0" }}
          activeTabIndex={activeTab}
          tabs={[
            
            <>
              {this.state.contacts
                .filter(contact => contact.type === "customGroup")
                .map(contact => (
                  <ListItem
                    onClick={() => pageNavigator(match.url + "/" + contact.id)}
                    title={contact.title}
                    iconRight={<ChevronRightIcon />}
                    key={contact.id}
                  />
                ))}
            </>,
            
            <>
              {this.state.contacts
                .filter(contact => contact.type !== "customGroup")
                .map(contact => (
                  <ListItem
                    onClick={() => pageNavigator(match.url + "/" + contact.id)}
                    title={contact.title}
                    iconRight={<ChevronRightIcon />}
                    key={contact.id}
                  />
                ))}
            </>
          ]}
        />
 * ```
 */
class TabBar extends Component {
  constructor(props) {
    super(props);
    this.targetRef = React.createRef();
  }

  componentDidMount() {
    disableBodyScroll(this.targetRef.current);
  }

  onClickProxy = (next, e) => {
    // Continue with actual navigation
    next();

    // Scroll into view
    this.scrollActiveTabIntoView(e.target);
  };

  scrollActiveTabIntoView = (activeTab, duration = 240) => {
    // Get clicked element position
    let clickedElPosition = activeTab.getBoundingClientRect();
    let windowWidth = window.innerWidth;
    let { current: el } = this.targetRef;

    // Scroll into view from right
    if (windowWidth - clickedElPosition.right < 0) {
      let newScrollDeltaX = clickedElPosition.width - (windowWidth - clickedElPosition.x);
      TinyAnimate.animate(
        el.scrollLeft,
        el.scrollLeft + newScrollDeltaX,
        duration,
        (x) => {
          el.scrollLeft = x;
        },
        "easeInOutQuart"
      );
    }
    // Scroll into view from left
    if (clickedElPosition.x < 0) {
      TinyAnimate.animate(
        el.scrollLeft,
        el.scrollLeft + clickedElPosition.x,
        duration,
        (x) => {
          el.scrollLeft = x;
        },
        "easeInOutQuart"
      );
    }
  };

  render() {
    let { primaryColor, activeTabIndex, tabs, customColor } = this.props;

    return (
      <div className={style(primaryColor, customColor)} style={this.props.style}>
        <div className="scroll-hider disable-body-scroll" ref={this.targetRef}>
          {tabs.map((tab, tabIndex) => (
            <div
              data-test-id={`tab-${tabIndex}`}
              className={Number(tabIndex) === Number(activeTabIndex) ? "active tab" : "tab"}
              onClick={(e) => this.onClickProxy(tab.onClick, e)}
              key={tabIndex}
              style={{ borderBottomColor: tab.color }}
            >
              {tab.title}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const style = (primaryColor, customColor) => css`
  background-color: ${colors.white};
  border-bottom: 1px ${colors.midGrey} solid;
  overflow: hidden;
  flex: ${common.topBarHeight + 1}px 0 0;

  .scroll-hider {
    // This is added because of x-axes scrolling lagging on IOS 11.3
    -webkit-overflow-scrolling: touch;

    overflow-x: auto;
    white-space: nowrap;
  }

  svg {
    vertical-align: sub;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.15rem;
  }

  div.tab {
    line-height: ${common.topBarHeight - 3}px;
    padding: 0 2rem;
    display: inline-block;
    color: ${colors.darkGrey};

    &.active {
      border-bottom: 3px ${primaryColor} solid;
      color: ${primaryColor};
    }
  }
`;

TabBar.propTypes = {
  /** Numerical value which determines current active tab */
  activeTabIndex: PropTypes.number,
  /** Array which holds tab title */
  tabs: PropTypes.array,
  /** Override component style with this */
  style: PropTypes.object,
};

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});
export default connect(mapStateToProps)(TabBar);
