import React, { memo, useEffect, useState } from "react";
import { Card, Col, notification, Row, Button, InputNumber, Collapse, Result } from "antd";

import { useMediaQuery } from "react-responsive";
import "rc-time-picker/assets/index.css";
import TextField from "@mui/material/TextField";

import { groupBy } from "lodash";

// css
import { css } from "emotion";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";

//utitlities
import computeTotalHours from "../../utilities/compute-total-time-registartion-hours";

const TimeRegistrationList = ({
  disabled = false,
  error,
  isForeman = false,
  nptHottLabels = [],
  onChange = () => {},
  timeRegistrations = [],
}) => {
  const isMobile = useMediaQuery({ maxWidth: "800px" });
  const labelings = groupBy(nptHottLabels, "Category");

  const [showLabelings, setShowLabelings] = useState(false);
  const [hoursToLabel, setHoursToLabel] = useState(0);
  const [totalHoursLabel, setTotalHoursLabel] = useState(0);

  const handleLabelInputChanges = (field, label, val) => {
    const { id } = label;
    // dynamically changes object values by key
    let newLabel = nptHottLabels;
    newLabel.map((d, i) => {
      if (d.id === id) {
        newLabel[i] = { ...newLabel[i], [field]: val };
      }
      return newLabel;
    });
    onChange(newLabel);
  };

  useEffect(() => {
    let labelHours = 0;
    nptHottLabels.forEach((d) => {
      labelHours += d.Hours;
    });

    setTotalHoursLabel(labelHours);
    labelHours = computeTotalHours(timeRegistrations.filter((d) => d.type === 1 && d.savedByBlueCollar)) - labelHours;
    setHoursToLabel(labelHours < 0 ? 0 : labelHours);
  }, [JSON.stringify(timeRegistrations), JSON.stringify(nptHottLabels)]);

  if (nptHottLabels.length === 0)
    return (
      <Result
        status="warning"
        title="NO NPT AND HOTT LABELS FOUND"
        subTitle="The selected project has NPT/HOTT enabled but no labels found."
      />
    );

  const fields = (item) => ({
    workOrder: (
      <TextField
        disabled={disabled}
        error={item.Hours > 0 && item.WorkOrderNo === ""}
        size="small"
        label="Work Order No."
        variant="outlined"
        value={item.WorkOrderNo}
        onChange={(e) => handleLabelInputChanges("WorkOrderNo", item, e.target.value)}
      />
    ),
    operationNumber: (
      <TextField
        disabled={disabled}
        error={item.Hours > 0 && item.OperationNo === ""}
        size="small"
        label={"Operation No."}
        variant="outlined"
        value={item.OperationNo}
        onChange={(e) => handleLabelInputChanges("OperationNo", item, e.target.value)}
      />
    ),
    comments: (
      <TextField
        disabled={disabled}
        error={
          // removed special characters to ensure that the description is only "Custom"
          item.Hours > 0 && item.Description.replace(/[^a-zA-Z ]/g, "") === "Custom" && item.Comment === ""
        }
        size="small"
        label={"Description"}
        variant="outlined"
        value={item.Comment}
        onChange={(e) => handleLabelInputChanges("Comment", item, e.target.value)}
      />
    ),
    hours: (
      <InputNumber
        disabled={disabled}
        onStep={(value, info) => {
          if (info.type === "up") {
            if (!Math.fround(hoursToLabel) >= Math.fround(info.offset)) {
              return notification.error({
                duration: 1,
                message: "FAILED",
                description: "Insufficient hours to label",
              });
            }
          }
          handleLabelInputChanges("Hours", item, value);
        }}
        onBlur={(e) => {
          // Workaround, onChange will also trigger onBlur so it will trigger the validation twice

          // if the incoming value is below current value, just add (automatically deducted)
          if (item.Hours > parseFloat(e.currentTarget.value))
            return handleLabelInputChanges("Hours", item, parseFloat(e.currentTarget.value));

          //if the incoming value is greater than the current value
          if (item.Hours !== 0 && hoursToLabel !== 0 && item.Hours < parseFloat(e.currentTarget.value)) {
            //which means the computation should be (original worktime hours - total hours without the current value)
            if (
              Math.fround(
                computeTotalHours(timeRegistrations.filter((d) => d.type === 1 && d.savedByBlueCollar)) -
                  (totalHoursLabel - item.Hours)
              ) >= Math.fround(parseFloat(e.currentTarget.value))
            ) {
              //if the incoming (greater) value is less than or equal total without the current value
              return handleLabelInputChanges("Hours", item, parseFloat(e.currentTarget.value));
            }
          }

          //if the incoming value is within the remaining work time hours
          if (Math.fround(hoursToLabel) >= Math.fround(parseFloat(e.currentTarget.value)))
            return handleLabelInputChanges("Hours", item, parseFloat(e.currentTarget.value));

          //conditioned just in case the input got focused but user did not changed the value
          if (item.Hours !== parseFloat(e.currentTarget.value))
            notification.error({
              duration: 1,
              message: "FAILED",
              description: "Insufficient hours to label",
            });
        }}
        placeholder={"Hours"}
        value={item.Hours}
        min={0}
        defaultValue={0}
        precision={2}
        step={0.25}
      />
    ),
  });

  const renderListItems = (type) => {
    return labelings[type].map((item) => (
      <Row className="label-container" gutter={[20, 8]}>
        <Col span={4}>{fields(item).workOrder}</Col>
        <Col span={4}>{item.Category === "HOTT" ? fields(item).operationNumber : <div></div>}</Col>
        <Col span={6}>{item.Description}</Col>
        <Col span={6}>{fields(item).comments}</Col>
        <Col span={2}>{fields(item).hours}</Col>
      </Row>
    ));
  };

  const renderCardItems = (type) => {
    return (
      <div className="mobile-label-cards">
        {labelings[type].map((item) => (
          <Card size="small" title={<b>{item.Description}</b>}>
            <Row gutter={[8, 16]}>
              <Col span={12}>{fields(item).workOrder}</Col>
              <Col span={12}>{item.Category === "HOTT" ? fields(item).operationNumber : <div></div>}</Col>
              <Col span={24}>{fields(item).comments}</Col>
              <Col span={12}>{fields(item).hours}</Col>
            </Row>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <div className={componentStyles(isForeman)}>
      <div className="top">
        <div className="title">
          <span>
            <b>Labelling of NPT and HOTT: </b>
            <Button
              onClick={() => setShowLabelings(!showLabelings)}
              style={{ marginLeft: "5px" }}
              type="primary"
              icon={showLabelings ? <MinusCircleFilled /> : <PlusCircleFilled />}
            />
          </span>
        </div>
        {showLabelings && (
          <div>
            <span>
              <b>Hours to Label: </b>
              <InputNumber readOnly value={hoursToLabel.toFixed(2)} />
            </span>
          </div>
        )}
        {error && <h6 className="fieldError">{error.message}</h6>}
      </div>
      {showLabelings && (
        <div className="labelsDropDown">
          <Collapse defaultActiveKey={["1", "2"]}>
            <Collapse.Panel header="HOTT" key="1">
              {isMobile ? renderCardItems("HOTT") : renderListItems("HOTT")}
            </Collapse.Panel>
            <Collapse.Panel header="NPT" key="2">
              {isMobile ? renderCardItems("NPT") : renderListItems("NPT")}
            </Collapse.Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};

const componentStyles = (isForeman) => css`
  margin-top: 10px;

  .top {
    .title {
      font-size: 17px;
    }
    .ant-input-number {
      width: 65px;
    }
  }

  .label-container {
    padding: 10px;
    // display: flex;
    // justify-content: space-between;
  }

  .labelsDropDown {
    margin-top: 15px;

    .ant-collapse-content-box {
      padding: 0px;
    }
  }

  .mobile-label-cards {
    .ant-card-bordered {
      border: 1px solid #0f3447;
    }
  }
`;

export default memo(TimeRegistrationList);
