import React, { useState } from 'react';
import { ArrowBackIcon, InfoOutlineIcon, DownloadIcon, MagnifyIcon } from 'mdi-react';
import { Button, notification } from 'antd';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { css } from 'emotion';
import { useQuery } from 'react-query';
import param from 'jquery-param';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FileSaver from 'file-saver';

import usePageAndBackButtonURL from '../../../hooks/usePageAndBackButtonURL';
import pageNavigator from '../../../utilities/page-navigator';
import req from '../../../utilities/request-utility';

import colors from '../../../style/colors';
import InlineSpinner from '../../ui/InlineSpinner';
import StatusBox from '../../ui/StatusBox';
import DropDown from '../../ui/DropDown';
import ActionWrapper from '../../ui/ActionWrapper';
import ComponentPage from '../../ui/Page';
import TopBar from '../../ui/TopBar';
import PDFRenderComponent from '../../ui/PDFRenderComponent';

function TimeSheetSemsupport(props) {
	const { user = {} } = props;
	const { externalId = '', loggedUserTypeDK = 'user' } = user;
	const { page, backButtonURL } = usePageAndBackButtonURL(props);
	const [tableData, setTableData] = useState({
		pdfFile: '',
		error: false,
		loading: false,
		empty: false
	});

	const {
		data: { data: foremen = {} } = {},
		isFetching: foremenIsFetching,
		isLoading: foremenIsLoading,
		refetch: foremenRefetch
	} = useQuery(
		['GetForemen'],
		() => {
			return req()(`semcotime/foremen/`);
		},
		{
			staleTime: 30 * 1000
		}
	);

	const {
		data: { data: foremanTeamMembers = {} } = {},
		isFetching: foremanTeamMembersIsFetching,
		isLoading: foremanTeamMembersIsLoading,
		refetch: foremanTeamMembersRefetch
	} = useQuery(
		['GetForemanTeamMembers'],
		() => {
			if (loggedUserTypeDK === 'admin') return req()(`semcotime/foremen-teams/my-team/all`);

			return req()(`semcotime/foremen-teams/my-team`);
		},
		{
			staleTime: 30 * 1000
		}
	);

	const schema = yup.object().shape({
		blueCollarId: yup.string().required('User is required'),
		foremanId: yup.string(),
		fromDate: yup.string().required('From date is required'),
		toDate: yup.string().required('To date is required'),
		location: yup.string().required('Location is required')
	});

	const defaultValues = {
		blueCollarId: loggedUserTypeDK === 'user' ? externalId : '',
		foremanId: '',
		fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
		toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
		location: ''
	};

	const {
		control,
		formState: { errors },
		getValues,
		reset,
		setValue,
		handleSubmit,
		watch
	} = useForm({
		defaultValues,
		resolver: yupResolver(schema)
	});

	const onSubmit = async formData => {
		try {
			const { blueCollarId = '', foremanId = '', fromDate = '', toDate = '', location = '' } = formData;

			setTableData({ pdfFile: '', loading: true, error: false, empty: false });

			const { data } = await req()(
				`semcotime/customer-approval/semsupport?${param({
					blueCollarId,
					foremanId,
					fromDate,
					toDate,
					location
				})}`,
				{
					responseType: 'blob'
				}
			);

			setTableData({ loading: false, pdfFile: window.URL.createObjectURL(data), error: false, empty: false });
		} catch (err) {
			const { status } = err.response;
			if (status === 404) return setTableData({ loading: false, pdfFile: '', error: false, empty: true });

			setTableData({ loading: false, pdfFile: '', error: true, empty: false });
		}
	};

	const onDownload = () => {
		const { blueCollarId = '', foremanId = '', fromDate = '', toDate = '' } = watch();
		const { name } = foremanTeamMembers.filter(d => d.externalId === blueCollarId)[0];

		FileSaver.saveAs(tableData.pdfFile, `${name}-${fromDate}-${toDate}`);
	};

	return (
		<ComponentPage className={componentStyles(loggedUserTypeDK === 'user' ? true : false)}>
			<TopBar
				title={page.title}
				actionLeft={
					<ActionWrapper onClick={() => pageNavigator(backButtonURL, 'backward')}>
						<ArrowBackIcon />
					</ActionWrapper>
				}
			/>
			<div className="date-selector-bar">
				<div className="dropdown-fields">
					{loggedUserTypeDK != 'user' && (
						<div>
							<Controller
								name="blueCollarId"
								control={control}
								render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
									return (
										<DropDown
											name="blueCollarId"
											placeholder="Select a User"
											onChange={e => onChange(e)}
											loading={foremanTeamMembersIsLoading}
											options={
												!foremanTeamMembersIsLoading
													? foremanTeamMembers
															.filter(d => d.externalId && d.isTemporaryUser)
															.map(d => ({
																label: `${d.name} | Ticket No. ${
																	d.ticketNumber ? d.ticketNumber : 'N/A'
																}`,
																value: d.externalId
															}))
													: []
											}
											value={value}
										/>
									);
								}}
							/>
						</div>
					)}
					<div>
						<Controller
							name="location"
							control={control}
							render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
								return (
									<DropDown
										name="location"
										placeholder="Location"
										onChange={e => onChange(e)}
										options={[
											{ label: 'Onshore', value: 'onshore' },
											{ label: 'Offshore', value: 'offshore' }
										]}
										value={value}
									/>
								);
							}}
						/>
					</div>
				</div>
				<div className="right">
					<div className="date-inputs">
						<p>From: </p>
						<Controller
							name="fromDate"
							control={control}
							render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
								return <input value={value} type="date" name="fromDate" onChange={e => onChange(e)} />;
							}}
						/>
						<p>To: </p>
						<Controller
							name="toDate"
							control={control}
							render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
								return <input value={value} type="date" name="toDate" onChange={e => onChange(e)} />;
							}}
						/>
					</div>
					<div className="buttons">
						<Button
							disabled={tableData.loading}
							onClick={handleSubmit(
								e => onSubmit(e),
								e => {
									notification.error({
										duration: 1,
										message: 'FAILED',
										description: Object.keys(e).map(d => e[d].message)[0]
									});
								}
							)}>
							<MagnifyIcon />
						</Button>
						<Button onClick={onDownload} disabled={!tableData.pdfFile || tableData.loading}>
							<DownloadIcon />
						</Button>
					</div>
				</div>
			</div>
			{tableData.loading && <InlineSpinner style={{ marginTop: '2rem' }} title="Loading timesheet..." />}

			{!tableData.loading && tableData.empty && (
				<StatusBox
					style={{ marginTop: '2rem' }}
					title="No results"
					content="No results found. Try with different dates"
					icon={<InfoOutlineIcon />}></StatusBox>
			)}

			{!tableData.loading && tableData.error && (
				<StatusBox
					style={{ marginTop: '2rem' }}
					title="Something went wrong"
					icon={<InfoOutlineIcon />}></StatusBox>
			)}

			{!tableData.loading && !tableData.error && tableData.pdfFile && (
				<div className="inline-scroll-wrapper" id="custom-scroll-wrapper-kasd872jhKsd72">
					<PDFRenderComponent pdfData={tableData.pdfFile} />
				</div>
			)}
		</ComponentPage>
	);
}

const componentStyles = isUser => css`
	.inline-scroll-wrapper {
		overflow-x: auto;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		.react-pdf__Page__canvas {
			margin: 0 auto !important;
		}
	}

	.date-selector-bar {
		height: 55px;
		border-bottom: 1px ${colors.midGrey} solid;
		background-color: ${colors.white};
		display: flex;
		align-items: center;
		justify-content: ${isUser ? 'end' : 'space-between'};
		font-size: 1rem;
		padding-right: 15px;
		padding-left: 15px;

		.dropdown-fields {
			width: 35%;
			display: flex;
			justify-content: space-evenly;

			& > div:first-child {
				flex-grow: 2; /* Make the first child wider */
			}
			& > div {
				flex-grow: 1;
			}
		}

		.right {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.date-inputs {
			display: contents;
		}

		input {
			margin: 0 0.5rem;
			color: ${colors.darkGrey};
			border: 1px ${colors.darkGrey} dashed;
			border-width: 0 0 1px 0;
			font-size: 1rem;
		}
	}

	@media screen and (max-width: 769px) {
		.date-selector-bar {
			display: flex;
			flex-direction: column;
			height: initial;
			gap: 15px;
			// justify-content: ${isUser ? 'start' : 'space-between'};

			.dropdown-fields {
				margin-top: 10px;
				width: 100%;
			}

			.right {
				display: block;

				.date-inputs {
					display: inline-flex;
				}

				.buttons {
					margin-top: 10px;
					display: flex;
					justify-content: center;
					margin-bottom: 10px;
				}
			}
		}
	}

	table {
		position: relative;
		border-collapse: collapse;
		min-width: 100%;
		min-width: calc(100% + 1px);
		min-height: calc(100% + 1px);

		.fake-total-column {
			width: 40px;
			text-align: center;
			margin-left: 1rem;
			padding-left: 0.3rem;
			height: 100%;
			flex-shrink: 0;
		}

		th.no-data {
			color: ${colors.midGrey};
		}

		.body-label {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.show-more-button {
			display: flex;
			justify-content: space-between;
			white-space: nowrap;

			svg {
				margin-right: 0.15rem;
			}

			button {
				display: flex;
				align-items: center;
			}
		}

		&.show-details {
			.meta-info-wrapper {
				margin-bottom: 0.3rem;
			}
		}

		&.hide-details {
			.meta-info-wrapper {
				margin-bottom: 0.15rem;
				/* white-space: nowrap; */
			}
		}

		/* All cells */
		td,
		th {
			padding: 0.5em;
			font-size: 0.85rem;
			font-weight: 400;
			color: ${colors.black};
			min-width: 50px;
		}

		/* Cells in header */
		thead th {
			position: -webkit-sticky; /* for Safari */
			position: sticky;
			top: 0;
			z-index: 1;
			background-color: ${colors.ultraLightGrey};
			color: ${colors.darkGrey};
			border-bottom: 1px ${colors.midGrey} solid;
			border-right: 1px ${colors.midGrey} solid;
			box-shadow: 1px 1px 0 0 ${colors.midGrey};

			&:first-child {
				left: 0;
				z-index: 2;
			}
		}

		/* Cells in header */

		tbody th {
			position: -webkit-sticky; /* for Safari */
			position: sticky;
			left: 0;
			background: #fff;
			border-bottom: 1px ${colors.midGrey} solid;
			border-right: 1px ${colors.midGrey} solid;

			&:first-of-type {
				z-index: 1;
				text-align: left;
				background-color: ${colors.white};
				box-shadow: 1px 0 0 0 ${colors.midGrey};

				span.meta {
					color: ${colors.darkGrey};
				}
			}
		}
	}
`;

export default TimeSheetSemsupport;
