import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import param from 'jquery-param';
import { useQuery } from 'react-query';
import { notification } from 'antd';
import { format } from 'date-fns';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

// css
import { css } from 'emotion';
import { AlertDecagramIcon, ArrowBackIcon, PlusIcon, SelectAllIcon } from 'mdi-react';
import colors from '../../style/colors';
import common from '../../style/common';

import TopBar from '../ui/TopBar';
import Page from '../ui/Page';
import getBackButtonUrl from '../../utilities/get-back-button-url';
import getPageFromId from '../../utilities/get-page-from-id';
import ActionWrapper from '../ui/ActionWrapper';
import pageNavigator from '../../utilities/page-navigator';
import DropDown from '../ui/DropDown';
import ForemanListItem from './ForemanListItem';
import InlineSpinner from '../ui/InlineSpinner';
import TabView from '../ui/TabView';
import TabBar from '../ui/TabBar';
import ForemanWorkHourForm2 from './ForemanTeam/ForemanWorkHourForm2';
import ForemanWorkHourForm from './ForemanTeam/ForemanWorkHourForm';
import ForemanAbsenceForm from './ForemanTeam/ForemanAbsenceForm';
import SubmitAllComponent from './SubmitAllComponent';

// actions
import { showDialog, toggleWorkHourModal } from '../../actions/uiActions';

// utilities
import req from '../../utilities/request-utility';

//components
import WorkTimeAlertDK from '../WorkTimeDK/WorkTimeAlertDK';

function ForemanRegistration(props) {
	const pages = useSelector(s => s.pages.pages);
	const loggedInUser = useSelector(s => s.auth.user);

	const page = getPageFromId(pages, props.match.params.pageId);
	const backButtonURL = getBackButtonUrl(page);

	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const dispatch = useDispatch();
	const foremanWorkHourFormRef = useRef();
	const foremanAbsenceFormRef = useRef();

	// State
	const [myTeam, setMyteam] = useState({
		data: [],
		loading: true,
		error: false,
		selectedUser: undefined,
		externalId: undefined
	});

	const [selectedRegistration, setSelectedRegistration] = useState('');
	const [selectedAbsence, setSelectedAbsence] = useState('');
	const [timeSchedInfo, setTimeSchedInfo] = useState({ data: [], loading: true });
	const [afterOnSubmit, setAfterOnSubmit] = useState(false);
	const [isSelectedUserTemporary, setIsSelectedUserTemporary] = useState(loggedInUser.isTemporaryUser); //defaults from the current logged in user ie: foreman
	const [isSelectedUserResignedOnHold, setIsSelectedUserResignedOnHold] = useState(false);
	const [approvedTimeRecords, setApprovedTimeRecords] = useState([]);

	const isLoggedUserAuthorizedForeman = loggedInUser.loggedUserAuthorizedForeman;

	const [showSubmitAllComponent, setShowSubmitAllComponent] = useState(false);
	const [isSubmittingAll, setIsSubmittingAll] = useState(false);

	const lang = useSelector(({ language }) => language.language);

	const {
		data: { data: needsProcessing = [] } = {},
		isFetching: needsProcessingIsFetching,
		isLoading: needsProcessingIsLoading,
		refetch: needsProcessingRefetch
	} = useQuery(
		['WorkHoursNeedsProcessing', myTeam.selectedUser],
		({ queryKey: [key, userId] = [] }) => {
			if (!userId) return;
			return req()(
				`semcotime/foremen-teams/users/${userId}/work-hours?${param({
					type: 'needsProcessing',
					externalId: myTeam.externalId
				})}`
			);
		},
		{
			staleTime: 30 * 1000
		}
	);

	const {
		data: { data: beingProcessed = [] } = {},
		isFetching: beingProcessedIsFetching,
		isLoading: beingProcessedIsLoading,
		refetch: beingProcessedRefetch
	} = useQuery(
		['WorkHoursBeingProcessed', myTeam.selectedUser],
		({ queryKey: [key, userId] = [] }) => {
			if (!userId) return;
			return req()(
				`semcotime/foremen-teams/users/${userId}/work-hours?${param({
					type: 'beingProcessed',
					externalId: myTeam.externalId
				})}`
			);
		},
		{
			staleTime: 30 * 1000
		}
	);

	const {
		data: { data: archived = [] } = {},
		isFetching: archivedIsFetching,
		isLoading: archivedIsLoading,
		refetch: archivedRefetch
	} = useQuery(
		['WorkHoursArchived', myTeam.selectedUser],
		({ queryKey: [key, userId] = [] }) => {
			if (!userId) return;
			return req()(
				`semcotime/foremen-teams/users/${userId}/work-hours?${param({
					type: 'archived',
					externalId: myTeam.externalId
				})}`
			);
		},
		{
			staleTime: 30 * 1000
		}
	);

	useEffect(() => {
		getSchedInfo();
	}, [myTeam.externalId]);

	function dates(current, formatted = false) {
		var week = new Array();
		var normalDate = new Array();
		current.setDate(current.getDate() - current.getDay() + 1);
		for (var i = 0; i < 7; i++) {
			if (formatted === true) {
				week.push(format(new Date(current), 'yyyyMMdd'));
			} else {
				normalDate.push(moment(new Date(current)).format('YYYY-MM-DD'));
			}

			current.setDate(current.getDate() + 1);
		}
		return {
			week: week,
			normalDate: normalDate,
			fromDate: week[0],
			toDate: week[6]
		};
	}

	async function getSchedInfo() {
		try {
			const { data } = await req()(
				`semcotime/work-hours-dk/EmpSchedInfo?CompanyIDs=DK10&EmpNos=${myTeam.externalId}`
			);
			setTimeSchedInfo({ data: data, loading: false });
		} catch (error) {
			console.log(error);
		}
	}

	const getTeamMembers = async () => {
		try {
			const { data } = await req()(`semcotime/foremen-teams/my-team`);
			setMyteam({ ...myTeam, data, error: false, loading: false });
			return data;
		} catch (error) {
			setMyteam({ ...myTeam, error: true, loading: false });
		}
	};

	const setRegistration = (registration, withCheck = true) => {
		if (foremanWorkHourFormRef.current) {
			const isFormDataChanged = foremanWorkHourFormRef.current.isFormDataChanged();

			if (withCheck && isFormDataChanged) {
				return dispatch(
					showDialog({
						icon: <AlertDecagramIcon />,
						title: lang.unsavedChanges,
						content: lang.closeRegistrationFormWarningContent,
						primaryActionTitle: lang.discardChanges,
						primaryAction: () => {
							setSelectedAbsence('');
							setSelectedRegistration(registration);
						},
						secondaryActionTitle: lang.keepChanges
					})
				);
			}
		}

		setSelectedAbsence('');
		setSelectedRegistration(registration);
	};

	const setAbsence = (absence, withCheck = true) => {
		if (foremanAbsenceFormRef.current) {
			const isFormDataChanged = foremanAbsenceFormRef.current.isFormDataChanged();

			if (withCheck && isFormDataChanged) {
				return dispatch(
					showDialog({
						icon: <AlertDecagramIcon />,
						title: lang.unsavedChanges,
						content:
							'You have made changes to the current registration. Do you want to discard your changes',
						primaryActionTitle: lang.discardChanges,
						primaryAction: () => {
							setSelectedRegistration('');
							setSelectedAbsence(absence);
						},
						secondaryActionTitle: lang.keepChanges
					})
				);
			}
		}

		setSelectedRegistration('');
		setSelectedAbsence(absence);
	};

	const changeTab = index => {
		setSelectedRegistration('');
		setActiveTabIndex(index);
	};

	const isFormValid = form => {
		// IF PROJECT IS PROVIDED, THEN SUB PROJECT AND ACTIVITY ARE REQUIRED
		if (form.type === 'time' && form.project) {
			if (!form.subProject || !form.projectActivity) {
				notification.error({
					duration: 7,
					message: 'FAILED',
					description: lang.projectSubProjectActivityAreRequired
				});
				return false;
			}
		}

		// DAB Trade IS REQUIRE
		if (form.type === 'time' && !form.dabTrade) {
			notification.error({ duration: 7, message: 'FAILED', description: lang.dabTradeIsRequired });
			return false;
		}

		// DAB Location IS REQUIRE
		if (form.type === 'time' && !form.dabLocation) {
			notification.error({ duration: 7, message: 'FAILED', description: lang.dabLocationIsRequired });
			return false;
		}

		// DATE IS REQUIRED
		if (form.type === 'time' && !form.date) {
			notification.error({ duration: 7, message: 'FAILED', description: lang.dateIsRequired });
			return false;
		}

		if (
			form.type === 'time' &&
			(!form.startTimeHours || !form.startTimeMinutes || !form.endTimeHours || !form.endTimeMinutes)
		) {
			notification.error({ duration: 7, message: 'FAILED', description: lang.missingStartOrEndTime });
			return false;
		}

		return true;
	};

	// DEPRECATED AFTER UI REVAMP
	const onSubmit = async form => {
		const { selectedUser } = myTeam;

		try {
			if (isFormValid(form)) {
				if (form.id) {
					await req().put(`semcotime/foremen-teams/users/${selectedUser}/work-hours/${form.id}`, form);
				} else {
					//add property to for new record , for temporary users
					const newForm = {
						...form,
						FullTimeAppUser: isSelectedUserTemporary ? false : true
					};

					await req().post(`semcotime/foremen-teams/users/${selectedUser}/work-hours`, newForm);
				}

				// Saves last used item in local storage
				localStorage.setItem(
					`semcotime-last-time-input-${selectedUser}`,
					JSON.stringify({
						startTimeHours: form.startTimeHours,
						startTimeMinutes: form.startTimeMinutes,
						endTimeHours: form.endTimeHours,
						endTimeMinutes: form.endTimeMinutes,
						project: form.project,
						subProject: form.subProject,
						activity: form.projectActivity,
						dabLocation: form.dabLocation,
						dabTrade: form.dabTrade
					})
				);

				needsProcessingRefetch();
				beingProcessedRefetch();
				archivedRefetch();

				notification.success({
					duration: 7,
					message: 'SUCCESS',
					description: lang.savedRegistrationToastContent
				});

				setRegistration('', false);
				setAbsence('', false);
			}
		} catch (error) {
			let errorMessage = 'Unspecified error';
			if (error && error.response && error.response.data) {
				if (error.response.data.message) errorMessage = error.response.data.message;
				else errorMessage = error.response.statusText;
			}

			notification.error({
				duration: 7,
				message: 'FAILED',
				description: `${lang.errorSavingRegistrationToastContent}. Fejlkode: ${errorMessage}`
			});
		}
	};

	const onSubmit2 = async form => {
		const { action = '' } = form;
		const { selectedUser } = myTeam;

		try {
			if (form.id) {
				await req().put(`semcotime/foremen-teams/users/${selectedUser}/work-hours/${form.id}`, form);
			} else {
				//add property to for new record , for temporary users
				const newForm = { ...form, FullTimeAppUser: isSelectedUserTemporary ? false : true };

				await req().post(`semcotime/foremen-teams/users/${selectedUser}/work-hours`, newForm);
			}

			needsProcessingRefetch();
			beingProcessedRefetch();
			archivedRefetch();
			const nextRecordIndex = needsProcessing.findIndex(d => d.id === selectedRegistration);

			if (nextRecordIndex !== 0 && (action === 'updateAndMoveToNextDay' || action === 'submitAndNext')) {
				if (needsProcessing[nextRecordIndex - 1].data.type === 'time') {
					setRegistration(needsProcessing[nextRecordIndex - 1].id, false);
				} else {
					setAbsence(needsProcessing[nextRecordIndex - 1].id, false);
				}
			} else {
				if (action !== 'draftAndNextDay') {
					setRegistration('', false);
					setAbsence('', false);
				}
			}

			let nextRecordMessage = '';

			if (action === 'updateAndMoveToNextDay' || action === 'submitAndNext')
				nextRecordMessage =
					nextRecordIndex !== 0 && needsProcessing[nextRecordIndex - 1].data.type === 'time'
						? `. The record date is now : ${needsProcessing[nextRecordIndex - 1].data.date}`
						: '';

			notification.success({
				duration: 7,
				message: 'SUCCESS',
				description:
					'Sucessfuly Saved' +
					((action === 'updateAndMoveToNextDay' || action === 'submitAndNext') && nextRecordIndex
						? nextRecordMessage
						: '')
			});

			if (action === 'submit' || action === 'submitAndNext') setAfterOnSubmit(true);
			// setAbsence("", false);
		} catch (error) {
			console.log('error: ', error);
			let errorMessage = 'Unspecified error';
			if (error && error.response && error.response.data) {
				if (error.response.data.message) errorMessage = error.response.data.message;
				else errorMessage = error.response.statusText;
			}

			notification.error({
				duration: 7,
				message: 'FAILED',
				description: `${lang.errorSavingRegistrationToastContent}. Fejlkode: ${errorMessage}`
			});
		}
	};

	const onDelete = async () => {
		const { selectedUser } = myTeam;

		try {
			const { id } = selected;

			await req().delete(`semcotime/foremen-teams/users/${selectedUser}/work-hours/${id}`);
			needsProcessingRefetch();

			notification.success({
				duration: 7,
				message: 'SUCCESS',
				description: lang.deletedRegistrationToastContent
			});

			setRegistration('', false);
			setAbsence('', false);
		} catch (error) {
			let errorMessage = 'Unspecified error';
			if (error && error.response && error.response.data) {
				if (error.response.data.message) errorMessage = error.response.data.message;
				else errorMessage = error.response.statusText;
			}

			notification.error({
				duration: 7,
				message: 'FAILED',
				description: `${lang.errorDeletingRegistrationToastContent}. Fejlkode: ${errorMessage}`
			});
		}
	};

	useEffect(() => {
		(async () => {
			const users = await getTeamMembers();
			const id = users && users.length !== 0 ? users[0].id : null;
			const externalId = users && users.length !== 0 ? users[0].externalId : null;
			if (id) setMyteam(myTeam => ({ ...myTeam, selectedUser: id, externalId: externalId }));
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (needsProcessing.length === 0 && needsProcessingIsFetching === false && afterOnSubmit) {
			setRegistration('', false);
			setAbsence('', false);
			setMyteam(myTeam => ({
				...myTeam,
				selectedUser: ''
			}));
			//crude workaround to avoid skipping users that have empty needprocessing
			setAfterOnSubmit(false);
		}
	}, [needsProcessingIsFetching]);

	const archivedCount = archived.length,
		needsProcessingCount = needsProcessing.length,
		beingProcessedCount = beingProcessed.length;
	let selected = null;

	if (selectedRegistration) {
		if (activeTabIndex === 0)
			selected = selectedRegistration === 'new' ? {} : needsProcessing.find(d => d.id === selectedRegistration);
		if (activeTabIndex === 1)
			selected = selectedRegistration === 'new' ? {} : beingProcessed.find(d => d.id === selectedRegistration);
		if (activeTabIndex === 2)
			selected = selectedRegistration === 'new' ? {} : archived.find(d => d.id === selectedRegistration);
	}
	if (selectedAbsence) {
		if (activeTabIndex === 0)
			selected = selectedAbsence === 'new' ? {} : needsProcessing.find(d => d.id === selectedAbsence);
		if (activeTabIndex === 1)
			selected = selectedAbsence === 'new' ? {} : beingProcessed.find(d => d.id === selectedAbsence);
		if (activeTabIndex === 2)
			selected = selectedAbsence === 'new' ? {} : archived.find(d => d.id === selectedAbsence);
	}

	const isMobile = useMediaQuery({ maxWidth: '824px' });
	const username = myTeam.data.find(team => team.id === myTeam.selectedUser);

	const registeredTimeForWholeWeek = needsProcessing
		.concat(beingProcessed)
		.concat(archived)
		.filter(reg => {
			let date = reg.timeKey.split('__')[0];
			if (date.length !== 10) {
				date = date.substr(0, 11);
			}

			return dates(new Date(), false).normalDate.includes(date.trim());
		});

	const handleSubmitAll = () => {
		//make sure that form is clear
		setSelectedRegistration('');
		setSelectedAbsence('');

		dispatch(toggleWorkHourModal(true));

		//filter records that are approved by foreman (all time registration types), type = time and locked by foreman
		const filteredRecords = needsProcessing
			.filter(record => record.type === 'time' && record.lockedByForeman)
			.filter(
				r =>
					r.data.timeRegistrations.filter(tr => tr.approvedByForeman).length ===
					r.data.timeRegistrations.length
			);

		setApprovedTimeRecords(filteredRecords);

		setShowSubmitAllComponent(!showSubmitAllComponent);
	};

	const registeredTime = registeredTimeForWholeWeek.filter(regTime => regTime.type === 'time');
	const registeredAbsence = registeredTimeForWholeWeek.filter(regTime => regTime.type === 'absence');

	return (
		<Page className={componentStyles(props, isSubmittingAll)}>
			<TopBar
				actionLeft={
					<ActionWrapper onClick={() => pageNavigator(backButtonURL, 'backward')}>
						<ArrowBackIcon />
					</ActionWrapper>
				}
				title={page.title}
			/>
			<div className="main-content">
				<div className="col col-left">
					{/* Show alert when time is not submitted */}
					{myTeam.selectedUser && (
						<WorkTimeAlertDK
							loading={
								needsProcessingIsLoading ||
								beingProcessedIsLoading ||
								archivedIsLoading ||
								timeSchedInfo.loading ||
								myTeam.loading
							}
							name={username ? username.name : ''}
							foreman={true}
							time={registeredTime}
							absences={registeredAbsence}
							schedInfo={timeSchedInfo.data}
						/>
					)}
					<div style={isMobile ? { padding: '1rem', width: '100%', margin: 0 } : { margin: '1rem' }}>
						<DropDown
							name="user"
							onChange={e => {
								const selectedUser = myTeam.data.find(d => d.id === e.target.value);

								getSchedInfo();
								setMyteam({
									...myTeam,
									selectedUser: e.target.value,
									externalId: selectedUser.externalId
								});
								setIsSelectedUserTemporary(selectedUser.isTemporaryUser);
								setIsSelectedUserResignedOnHold(selectedUser.isResignedOnHold);
								setSelectedRegistration('');
								setSelectedAbsence('');
								setShowSubmitAllComponent(false);
							}}
							options={myTeam.data.map(d => ({
								label: `${d.name} | Ticket No. ${
									d.ticketNumber ? d.ticketNumber : 'N/A'
								} | Employee No. ${d.externalId}`,
								value: d.id
							}))}
							value={myTeam.selectedUser}
						/>
					</div>
					<TabBar
						activeTabIndex={activeTabIndex}
						tabs={[
							{
								title: (
									<>
										{lang.needsProcessing}{' '}
										{needsProcessingCount > 0 ? <>{needsProcessingCount}</> : ''}
										{!needsProcessingIsLoading && needsProcessingIsFetching && (
											<InlineSpinner
												size="14"
												style={{ display: 'inline-block', margin: '0 0rem -0.6rem 0.5rem' }}
											/>
										)}
									</>
								),
								onClick: () => changeTab(0)
							},
							!isSelectedUserTemporary && {
								title: (
									<>
										{lang.beingProcessed}{' '}
										{beingProcessedCount > 0 ? <>{beingProcessedCount}</> : ''}{' '}
										{!beingProcessedIsLoading && beingProcessedIsFetching && (
											<InlineSpinner
												size="14"
												style={{ display: 'inline-block', margin: '0 0rem -0.6rem 0.5rem' }}
											/>
										)}
									</>
								),
								onClick: () => changeTab(1)
							},
							{
								title: (
									<>
										{lang.archive} {archivedCount > 0 ? <>{archivedCount}</> : ''}{' '}
										{!archivedIsLoading && archivedIsFetching && (
											<InlineSpinner
												size="14"
												style={{ display: 'inline-block', margin: '0 0rem -0.6rem 0.5rem' }}
											/>
										)}
									</>
								),
								onClick: () => changeTab(2)
							}
						]}
					/>
					<TabView
						activeTabIndex={activeTabIndex}
						tabStyle={{ padding: '1rem' }}
						tabs={[
							<>
								{(myTeam.loading || needsProcessingIsLoading) && <InlineSpinner />}
								{!myTeam.loading && !needsProcessingIsLoading && needsProcessingCount === 0 && (
									<p className="meta">{lang.noRecordsFound}</p>
								)}
								{!myTeam.loading &&
									!needsProcessingIsLoading &&
									needsProcessing
										.filter(registration =>
											showSubmitAllComponent
												? registration.type === 'time' && registration.lockedByForeman
												: true
										)
										.map(registration => (
											<ForemanListItem
												approvedTimeRecords={approvedTimeRecords}
												data={registration}
												key={`need-processing-${registration.id}`}
												selected={[selectedRegistration, selectedAbsence].includes(
													registration.id
												)}
												selectAllApproved={showSubmitAllComponent}
												onClick={() => {
													if (showSubmitAllComponent) {
														// if included, remove from array, if not, add to array
														if (
															approvedTimeRecords.find(
																approved => approved.id === registration.id
															)
														) {
															setApprovedTimeRecords(
																approvedTimeRecords.filter(
																	approved => approved.id !== registration.id
																)
															);
														} else {
															setApprovedTimeRecords([
																...approvedTimeRecords,
																registration
															]);
														}
													} else {
														registration.type === 'time'
															? setRegistration(registration.id)
															: setAbsence(registration.id);
														dispatch(toggleWorkHourModal(true));
													}
												}}
											/>
										))}

								{!myTeam.loading && !needsProcessingIsLoading && (
									<>
										{/* only show register buttons when: selected  user is not resigned and the submit all component is false */}
										{!showSubmitAllComponent && (
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												<ActionWrapper
													onClick={() => {
														setRegistration('new');
														setShowSubmitAllComponent(false);
														dispatch(toggleWorkHourModal(true));
													}}
													style={{
														lineHeight: `${common.topBarHeight}px`,
														padding: '0 0.75rem'
													}}>
													<PlusIcon style={{ marginBottom: -5 }} /> {lang.addRegistration}
												</ActionWrapper>
												<ActionWrapper
													onClick={() => {
														setAbsence('new');
														setShowSubmitAllComponent(false);
														dispatch(toggleWorkHourModal(true));
													}}
													style={{
														lineHeight: `${common.topBarHeight}px`,
														padding: '0 0.75rem'
													}}>
													<PlusIcon style={{ marginBottom: -5 }} />{' '}
													{lang.foremanRegistrationAbsenceAdd}
												</ActionWrapper>
											</div>
										)}
										{isLoggedUserAuthorizedForeman && (
											<div style={{ display: 'flex', justifyContent: 'space-between' }}>
												<ActionWrapper
													onClick={handleSubmitAll}
													style={{
														lineHeight: `${common.topBarHeight}px`,
														padding: '0 0.75rem'
													}}>
													<SelectAllIcon style={{ marginBottom: -5 }} />{' '}
													{showSubmitAllComponent ? 'Close Submit All' : 'Submit All'}
												</ActionWrapper>
											</div>
										)}
									</>
								)}
							</>,
							<>
								{(myTeam.loading || beingProcessedIsLoading) && <InlineSpinner />}
								{!myTeam.loading && !beingProcessedIsLoading && beingProcessedCount === 0 && (
									<p className="meta">{lang.noRecordsFound}</p>
								)}
								{!myTeam.loading &&
									!needsProcessingIsLoading &&
									beingProcessed.map(registration => (
										<ForemanListItem
											approvedTimeRecords={approvedTimeRecords}
											data={registration}
											key={`being-processed-${registration.id}`}
											selected={[selectedRegistration, selectedAbsence].includes(registration.id)}
											onClick={() => {
												registration.type === 'time'
													? setRegistration(registration.id)
													: setAbsence(registration.id);
												dispatch(toggleWorkHourModal(true));
											}}
										/>
									))}
							</>,
							<>
								{(myTeam.loading || archivedIsLoading) && <InlineSpinner />}
								{!myTeam.loading && !archivedIsLoading && archivedCount === 0 && (
									<p className="meta">{lang.noRecordsFound}</p>
								)}
								{!myTeam.loading &&
									!archivedIsLoading &&
									archived.map(registration => (
										<ForemanListItem
											approvedTimeRecords={approvedTimeRecords}
											data={registration.lockedByIFS ? registration : []}
											key={`being-processed-${registration.id}`}
											selected={[selectedRegistration, selectedAbsence].includes(registration.id)}
											onClick={() => {
												registration.type === 'time'
													? setRegistration(registration.id)
													: setAbsence(registration.id);
												dispatch(toggleWorkHourModal(true));
											}}
										/>
									))}
							</>
						]}
					/>
				</div>
				<div className="col col-right">
					{!selectedRegistration && !selectedAbsence && showSubmitAllComponent == false && (
						<p className="meta">{lang.selectDateToStart}</p>
					)}
					{selectedRegistration && (
						<>
							{selected.isNewType || selectedRegistration === 'new' ? (
								<ForemanWorkHourForm2
									data={selected}
									externalId={myTeam.externalId}
									onDelete={onDelete}
									onSubmit={onSubmit2}
									ref={foremanWorkHourFormRef}
									lang={lang}
									userId={myTeam.selectedUser}
									isSelectedUserTemporary={isSelectedUserTemporary}
								/>
							) : (
								<ForemanWorkHourForm
									data={selected}
									externalId={myTeam.externalId}
									onDelete={onDelete}
									onSubmit={onSubmit}
									ref={foremanWorkHourFormRef}
									lang={lang}
									userId={myTeam.selectedUser}
								/>
							)}
						</>
					)}
					{selectedAbsence && (
						<ForemanAbsenceForm
							blueCollarExternalId={myTeam.externalId}
							data={selected}
							onSubmit={onSubmit}
							onDelete={onDelete}
							ref={foremanAbsenceFormRef}
							lang={lang}
						/>
					)}
					{showSubmitAllComponent && (
						<SubmitAllComponent
							approvedTimeRecords={approvedTimeRecords}
							setApprovedTimeRecords={setApprovedTimeRecords}
							setIsSubmittingAll={setIsSubmittingAll}
							selectedUser={myTeam.selectedUser}
							setShowSubmitAllComponent={setShowSubmitAllComponent}
							needsProcessingIsFetching={needsProcessingIsFetching}
							needsProcessingRefetch={needsProcessingRefetch}
						/>
					)}
				</div>
			</div>
		</Page>
	);
}

const componentStyles = (props, isSubmittingAll) => css`
	.main-content {
		display: flex;
		justify-content: space-between;
		height: 100%;
		overflow: hidden;

		.col {
			flex: 50% 0 1;
			background-color: ${colors.white};
		}

		.col-left {
			background-color: ${isSubmittingAll ? '#f2f2f2' : colors.white};
			cursor: ${isSubmittingAll ? 'not-allowed' : 'auto'};
			pointer-events: ${isSubmittingAll ? 'none' : 'auto'};
			border-right: 1px ${colors.midGrey} solid;
			box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
			position: relative;
			display: flex;
			flex-direction: column;
		}

		.col-right {
			position: relative;
		}
	}

	.meta {
		color: ${colors.darkGrey};
		text-align: center;
	}

	// @media (max-width: 800px) {
	//   .main-content {
	//     flex-direction: column;

	//     .col-left {
	//       height: 50vh;
	//     }

	//     .col-right {
	//       display: flex;
	//       flex-direction: column;
	//       height: 50vh;
	//     }
	//   }
	// }

	@media (max-width: 1024px) {
		.main-content {
			flex-direction: unset;

			.scroll-hider {
				font-size: 10px !important;
			}

			.col {
				flex: unset;
			}

			.col-left {
				align-items: flex-start;
				width: 100% !important;
				height: 100% !important;
			}

			.col-right {
				display: none;
				flex-direction: unset;
				height: unset;
			}
		}
	}
`;

export default ForemanRegistration;
