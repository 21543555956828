import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { notification } from "antd";

import { hideModalPage, showDialog } from "../../actions/uiActions";
import { updateUserData } from "../../actions/authActions";
import req from "../../utilities/request-utility";

import Button from "../ui/Button";
import ImageUploadSingle from "../ui/ImageUploadSingle";
import TextInput from "../ui/TextInput";
import TextareaInput from "../ui/TextareaInput";
import ScrollView from "../ui/ScrollView";
import { TrashIcon, CheckCircleOutlineIcon, AlertCircleIcon } from "mdi-react";

import colors from "../../style/colors";
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";

class UserProfileEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { ...this.props.user },
      submittingForm: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  submitForm = () => {
    this.setState({ submittingForm: true });

    req()
      .put(`/users/${this.props.user.id}/`, this.state.formData)
      .then((res) => {
        this.setState({ submittingForm: false });
        this.props.updateUserData(res.data);
        notification.success({ duration: 7, message: "SUCCESS", description: "Your changes were saved" });
        this.props.hideModalPage();
      })
      .catch((err) => {
        this.setState({ submittingForm: false });
        notification.error({
          duration: 7,
          message: "FAILED",
          description: "Could not edit your profile. Try again and if the problem persists contact support@toecho.dk",
        });
      });
  };

  closeForm = () => {
    let { showDialog, lang, hideModalPage } = this.props;

    showDialog({
      title: lang.heyThere,
      content: lang.closeFormWarningContent,
      primaryActionTitle: lang.yesCloseForm,
      primaryAction: hideModalPage,
      secondaryActionTitle: lang.noDontCloseForm,
    });
    // this.props.hideModalPage();
  };

  render() {
    let { user, appConfig, lang } = this.props;
    let { formData, submittingForm } = this.state;
    let marginBottom = 1;

    return (
      <ScrollView
        style={{ backgroundColor: colors.white, padding: "1rem", flexShrink: 1 }}
        className={componentStyles(this.props)}
      >
        <div className="user-header">
          {getProfilePicture(formData, 48)}
          <div style={{ paddingLeft: "1rem" }}>
            <h2>{user.name}</h2>
            <p style={{ marginBottom: "0.5rem" }}>
              {user.masterGroup.title}, {user.userGroup.title}
            </p>

            {/* TODO: Currently we are not checking for baseURL too, but we should! Once every profile picture has one... */}
            {formData.profilePicture.image && (
              <p className="fake-link" onClick={() => this.setState({ formData: { ...formData, profilePicture: {} } })}>
                <TrashIcon /> {lang.deleteProfilePicture}
              </p>
            )}
          </div>
        </div>

        {/* TODO: Currently we are not checking for baseURL too, but we should! Once every profile picture has one... */}
        {!formData.profilePicture.image && (
          <Fragment>
            <label style={{ marginBottom: marginBottom * 0.5 + "rem" }}>Profilbillede</label>
            <ImageUploadSingle
              style={{ marginBottom: marginBottom + "rem" }}
              onFile={(file) =>
                this.setState({ formData: { ...formData, profilePicture: { image: file.file, baseURL: file.baseURL } } })
              }
            />
          </Fragment>
        )}

        <label style={{ marginBottom: marginBottom * 0.5 + "rem" }}>{lang.phone}</label>
        <TextInput
          onChange={this.handleChange}
          name="phone"
          style={{ marginBottom: marginBottom + "rem" }}
          value={formData.phone}
          backgroundColor={colors.ultraLightGrey}
        />

        <label style={{ marginBottom: marginBottom * 0.5 + "rem" }}>{lang.email}</label>
        <TextInput
          onChange={this.handleChange}
          name="email"
          style={{ marginBottom: marginBottom + "rem" }}
          value={formData.email}
          backgroundColor={colors.ultraLightGrey}
        />

        <label style={{ marginBottom: marginBottom * 0.5 + "rem" }}>{lang.aboutMe}</label>
        <TextareaInput
          onChange={this.handleChange}
          name="about"
          style={{ marginBottom: marginBottom + "rem" }}
          value={formData.about}
          backgroundColor={colors.ultraLightGrey}
        />

        <label style={{ marginBottom: marginBottom * 0.5 + "rem" }}>{lang.myCompetencies}</label>
        <TextareaInput
          onChange={this.handleChange}
          name="skills"
          style={{ marginBottom: marginBottom * 2 + "rem" }}
          value={formData.skills}
          backgroundColor={colors.ultraLightGrey}
        />

        <Button
          buttonType="primary"
          primaryColor={appConfig.primaryColor}
          title={lang.saveChanges}
          style={{ marginBottom: marginBottom * 0.5 + "rem" }}
          active={submittingForm}
          onClick={this.submitForm}
        />

        <Button
          buttonType="secondary"
          title={lang.cancel}
          style={{ marginBottom: marginBottom + "rem" }}
          onClick={this.closeForm}
        />
      </ScrollView>
    );
  }
}

const componentStyles = (props) => css`
  /* All first level labels, input and textareas */
  & > input,
  & > textarea,
  & > label {
    display: block;
  }

  div.user-header {
    display: flex;
    margin-bottom: 1rem;

    p.fake-link {
      color: ${props.appConfig.primaryColor};
      svg {
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
        margin-bottom: 3px;
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  updateUserData: bindActionCreators(updateUserData, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileEditModal);
