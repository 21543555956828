import React, { memo, useState, useEffect } from 'react';
import { Button, Col, Modal, Row, Radio, Space } from 'antd';
import NotificationImportant from '@mui/icons-material/NotificationImportant';
import { useQuery } from 'react-query';

import req from '../../utilities/request-utility';

const CustomMessageModal = ({ appConfig = {} }) => {
	const [hideMessage, setHideMessage] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [announcement, setAnnouncement] = useState({});
	const { title: appTitle = '' } = appConfig;
	let companyIdToFind = null;

	if (appTitle === 'SemcoTime DK') companyIdToFind = 'DK10';
	if (appTitle === 'SemcoTime') companyIdToFind = 'NO11';

	const {
		data: { data: announcements = [] } = {},
		isFetching,
		isLoading
	} = useQuery(
		['ifs-announcements'],
		({ queryKey }) => {
			return req()(`semcotime/ifs/announcements`);
		},
		{
			staleTime: 30 * 1000
		}
	);

	useEffect(() => {
		if (!isFetching && !isLoading) {
			const announcementByCompanyId = companyIdToFind
				? announcements.find(announcement => announcement.CompanyId === companyIdToFind)
				: null;

			if (announcementByCompanyId) {
				const { MessageCode, PopUpMessage } = announcementByCompanyId || {};
				const lastHiddenAnnouncement = localStorage.getItem(`MessageCode-${companyIdToFind}`);

				if (PopUpMessage && lastHiddenAnnouncement !== MessageCode) {
					setShowModal(true);
					setAnnouncement({
						message: PopUpMessage.replace(/(?:\r\n|\r|\n)/g, '<br>'),
						messageCode: MessageCode
					});
				}
			}
		}
	}, [announcements]);

	return (
		<Modal
			className={'contact-it-support-modal'}
			title={
				<Row style={{ background: '#103447', padding: '0' }}>
					<Col span={24}>
						<Row justify="center">
							<NotificationImportant style={{ fontSize: '50px', color: 'white', marginTop: '15px' }} />
						</Row>
					</Col>

					<Col span={24}>
						<Row justify="center">
							<p
								style={{
									color: 'white',
									fontSize: '15px',
									fontWeight: '600',
									marginBottom: '15px'
								}}>
								NOTICE
							</p>
						</Row>
					</Col>
				</Row>
			}
			closable={false}
			closeIcon={null}
			visible={showModal}
			footer={
				<Button
					type="primary"
					onClick={() => {
						if (hideMessage)
							localStorage.setItem(`MessageCode-${companyIdToFind}`, announcement.messageCode);

						setShowModal(!showModal);
					}}>
					OK
				</Button>
			}>
			<Space direction="vertical">
				<div
					dangerouslySetInnerHTML={{
						__html: announcement.message
					}}
				/>
				<br />
				<Radio
					checked={hideMessage}
					onClick={() => {
						setHideMessage(!hideMessage);
					}}>
					Don't show this message again
				</Radio>
			</Space>
		</Modal>
	);
};

export default memo(CustomMessageModal);
