import { addDays, format, parse } from "date-fns";
import { intervalToDuration } from "date-fns/esm";

import zeroPad from "./zero-pad";
import getQuarterTitleFromHourValue from "./get-quarter-title-from-hour-value";

export default ({ startTimeHours, startTimeMinutes, endTimeHours, endTimeMinutes, date }) => {
  const z = zeroPad;
  let endDate;

  // Adds a day to endDate if registration spans midnight
  if (parseFloat(`${startTimeHours}${startTimeMinutes}`) > parseFloat(`${endTimeHours}${endTimeMinutes}`)) {
    endDate = format(addDays(parse(date, "yyyy-MM-dd", 0), 1), "yyyy-MM-dd");
  } else {
    endDate = date;
  }

  // Get starttime
  const parsedStartDate = parse(`${date}${z(startTimeHours)}${z(startTimeMinutes)}`, "yyyy-MM-ddHHmm", 0);
  const parsedEndDate = parse(`${endDate}${z(endTimeHours)}${z(endTimeMinutes)}`, "yyyy-MM-ddHHmm", 0);

  // Get interval
  const interval = intervalToDuration({ start: parsedStartDate, end: parsedEndDate });
  const dropDownOptions = [];

  let totalValue;

  totalValue = interval.hours * 60 + interval.minutes;

  for (let i = 1; i <= totalValue / 15; i++) {
    let value = i * 0.25;
    dropDownOptions.push({
      value,
      name: getQuarterTitleFromHourValue(value),
    });
  }

  return dropDownOptions;
};
