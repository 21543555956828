import React from "react";

import InlineSpinner from "../ui/InlineSpinner";
import TableList from "../ui/TableList";
import { parseDate } from "../../utilities/parse-date";
import StatusBox from "../ui/StatusBox";
import { AlertDecagramIcon } from "mdi-react";
const UserPointLog = props => {
  let { pointlog, pointlogLoading: loading, pointlogError: error } = props;

  return (
    <>
      {loading && !error && <InlineSpinner style={{ marginTop: "2rem" }} />}
      {!loading && error && <StatusBox />}
      {!loading &&
        !error &&
        (pointlog.length === 0 ? (
          <StatusBox
            style={{ paddingTop: "2rem" }}
            title="Oops."
            content="You don't seem to have gotten any points yet, you can get them by interacting with certain elements around the app"
            icon={<AlertDecagramIcon />}
          />
        ) : (
          <TableList
            style={{ margin: "1rem auto" }}
            headerLeft="Date"
            headerCenter="Activity"
            headerRight="Points"
            values={[
              {
                left: "",
                center: <p style={{ textAlign: "right", marginBottom: "0.25rem" }}>Total:</p>,
                right: (
                  <p style={{ marginBottom: "0.25rem" }}>
                    {pointlog.length > 0 && pointlog.map(e => e.points).reduce((total, value) => total + value)}
                  </p>
                )
              },
              ...pointlog.map(value => ({
                left: <p className="meta">{parseDate(value.date)}</p>,
                center: value.description,
                right: <p>{value.points}</p>
              })),
              {
                left: "",
                center: <p style={{ fontWeight: 700, textAlign: "right", marginTop: "0.5rem" }}>Total:</p>,
                right: (
                  <p style={{ fontWeight: 700, textAlign: "right", marginTop: "0.5rem" }}>
                    {pointlog.length > 0 && pointlog.map(e => e.points).reduce((total, value) => total + value)}
                  </p>
                )
              }
            ]}
          />
        ))}
    </>
  );
};

export default UserPointLog;
