import React, { Component } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { notification } from "antd";

// Components
import { UploadIcon } from "mdi-react";
import InlineSpinner from "../ui/InlineSpinner";

// Utilities
import req from "../../utilities/request-utility";

// Styles
import colors from "../../style/colors";
import common from "../../style/common";

/** Used for handling a single image/file upload, please note that a POST request is sent to our api,
 *  with the required data, and you should get the response you need, from there. */
class ImageUploadSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: {},
    };
  }

  onFileSelect = (e) => {
    // TODO: Validate file size and type
    let file = e.target.files[0];

    // Check if file is selected
    if (!file) return; // No file is selected

    // Check file size
    if (file.size > 200000000) {
      return notification.error({
        duration: 7,
        message: "FAILED",
        description: "The file you are trying to upload is too big. Choose a smaller file",
      });
    } else if (file.size > 39999900) {
      return notification.error({
        duration: 7,
        message: "FAILED",
        description:
          "You're about to upload a rather large file. This might take some time. Consider finding some decent Wi-Fi.",
      });
    }

    // Construct formData with file
    let formData = new FormData();
    formData.append("file", file);

    // Start upload and set state
    this.uploadFile(formData);
    this.setState({
      image: { status: "uploading", progress: 0 },
    });

    // Reset input
    e.target.value = "";
  };

  uploadFile = (file) => {
    req()
      .post("/images/", file, { onUploadProgress: (e) => this.onUploadProgress(e) })
      .then(({ data }) => data)
      .then((data) => this.onFileUploadSuccess(data))
      .catch((err) => this.onFileUploadFailure(err));
  };

  deleteFile = (index) => {
    // let files = [...this.state.files];
    // files[index].status = "deleted";
    // this.setState({
    //   files: files
    // });
    // this.props.onFileUpdate(this.state.files.filter(f => f.status === "uploaded"));
  };

  onUploadProgress = (progress) => {
    let progressPercent = (progress.loaded / progress.total) * 100;
    this.setState({
      image: {
        ...this.state.image,
        progress: progressPercent,
      },
    });
  };

  onFileUploadSuccess = (res) => {
    this.setState({
      image: {
        status: "uploaded",
        baseURL: res.baseURL,
        image: res.image,
        deleteToken: res.deleteToken,
      },
    });
    this.props.onFile({ file: res.image, baseURL: res.baseURL, deleteToken: res.deleteToken });
  };

  onFileUploadFailure = (err, index) => {
    notification.error({
      duration: 7,
      message: "FAILED",
      description: "Could not upload your image. Try again or contact support@toecho.dk",
    });

    if (this.state.files[index]) {
      this.setState({
        image: {
          ...this.state.image,
          status: "error",
        },
      });
    }
  };

  render() {
    let { placeholder, primaryColor, style, boxPadding } = this.props;
    let { image } = this.state;

    return (
      <div className={componentStyle(primaryColor, boxPadding)} style={style}>
        <label>
          {/* Icon (upload or spinner) */}
          {image.status === "uploading" ? (
            <InlineSpinner
              size={20}
              style={{
                borderRight: `1px solid ${colors.midGrey}`,
                marginRight: "0.45rem",
                padding: "0.5rem 0.45rem 0.1rem 0.45rem",
              }}
            />
          ) : (
            <UploadIcon />
          )}

          {/* Text (Placeholder or progress) */}
          <p>
            {image.status === "uploading"
              ? image.progress < 100
                ? Math.round(image.progress) + "%"
                : "Arbejder med filen..."
              : placeholder}
          </p>

          {/* Input */}
          <input type="file" accept="image/*" onChange={this.onFileSelect} />

          {/* Progress bar */}
          {image.status === "uploading" && (
            <div className="progress-bar-container">
              <div style={{ flexGrow: image.progress || 0 }} className="progress-bar colored" />
              <div style={{ flexGrow: 100 - (image.progress || 0) }} className="progress-bar transparent" />
            </div>
          )}
        </label>
      </div>
    );
  }
}

const componentHeight = 40; //px

const componentStyle = (primaryColor, boxPadding) => css`
  font-size: 1rem;
  color: ${colors.black};

  /* Upload button/Input */
  label {
    display: flex;
    position: relative;
    align-items: center;
    height: ${componentHeight}px;
    background: ${colors.white};
    border: 1px ${colors.midGrey} solid;
    color: ${colors.black};
    font-family: ${common.fontStack};
    margin-bottom: ${boxPadding};
    border-radius: 3px;

    svg {
      position: relative;
      width: ${componentHeight}px;
      height: ${componentHeight}px;
      margin-right: 0.45rem;
      padding: 0.45rem;
      color: ${colors.darkGrey};
      border-right: 1px solid ${colors.midGrey};
    }

    /* Progress bar */
    .progress-bar-container {
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 3px;
      display: flex;
      justify-content: space-between;

      .progress-bar {
        height: 3px;
      }

      .progress-bar.colored {
        background-color: ${primaryColor};
      }
    }
  }

  input[type="file"] {
    display: none;
  }
`;
const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

ImageUploadSingle.propTypes = {
  /**  String value to be displayed while image is being uploaded*/
  placeholder: PropTypes.string,
  /** value used for providing a bit of padding in your own implementation */
  boxPadding: PropTypes.string,
  /** Used for overriding styles, on the specific implementation */
  style: PropTypes.string,
  /** The applications primary color */
  primaryColor: PropTypes.string,
};
export default connect(mapStateToProps, null)(ImageUploadSingle);
