import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// css
import { css } from "emotion";
import colors from "../../style/colors";
import { PlusIcon, DotsVerticalIcon, PencilIcon, TrashCanOutlineIcon } from "mdi-react";

// ui
import InlineSpinner from "../ui/InlineSpinner";

// actions
import { showContextMenu, showModalPage, showDialog, hideDialog } from "../../actions/uiActions";
import AbsenceRegistration from "./AbsenceRegistration";

function ListItem(props) {
  const { lang, registration, showModalPage, showContextMenu } = props;
  const [expanded, setExpanded] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    setDeleting(true);

    await props.deleteRegistration(registration);

    setDeleting(false);
  };

  return (
    <div className={`${componentStyles(props)} ${expanded ? "expanded" : "collapsed"}`}>
      <div className="item-header" onClick={() => setExpanded(!expanded)}>
        <div className="left">
          <p>
            {registration.startDate} - {registration.endDate} - {registration.type}
          </p>
        </div>
        <div className="right">
          <div style={{ marginRight: "0.25rem" }}>{registration.totalHours}</div>
          {deleting && <InlineSpinner size="14" style={{ display: "inline-block", margin: "5px 0.5rem 0rem 0rem" }} />}
          {!deleting && (
            <>
              <DotsVerticalIcon
                style={{ width: "1.35rem", height: "1.35rem", margin: "0 0.25rem " }}
                onClick={(e) => {
                  e.stopPropagation();
                  showContextMenu([
                    {
                      icon: <PencilIcon />,
                      title: lang.edit,
                      callback: () =>
                        showModalPage({
                          content: (
                            <AbsenceRegistration
                              title={lang.updateRegistration}
                              registration={registration}
                              addRegistration={props.updateRegistration}
                            />
                          ),
                        }),
                    },
                    {
                      icon: <TrashCanOutlineIcon />,
                      title: lang.delete,
                      callback: () =>
                        props.showDialog({
                          styleType: "warning",
                          title: "Delete record",
                          content: "You're about to delete this registration. Are you sure you want to delete it?",
                          primaryActionTitle: "Yes - Delete",
                          primaryAction: onDelete,
                        }),
                    },
                  ]);
                }}
              />
              <PlusIcon style={{ marginRight: "-0.05rem" }} className={expanded ? "rotate" : "rotate-neutral"} />
            </>
          )}
        </div>
      </div>
      <div className={`item-details`}>
        <div className={`${expanded ? "show" : "hide"}`}>
          <div style={{ borderTop: `1px solid ${colors.midGrey}` }}>
            <div className="details">
              <p>
                <span>{lang.startDate}: </span>
                {registration.startDate}
              </p>
              <p>
                <span>{lang.endDate}: </span>
                {registration.endDate}
              </p>
              <p>
                <span>{lang.hoursPerDay}: </span>
                {registration.hoursPerDay}
              </p>
              <p>
                <span>{lang.type}: </span>
                {registration.type}
              </p>
              <p>
                <span>{lang.note}: </span>
                {registration.note}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const componentStyles = (props) => css`
  background-color: ${colors.white};
  border-top: 1px ${colors.midGrey} solid;
  border-bottom: 1px ${colors.midGrey} solid;
  margin-bottom: -1px;

  &.collapsed {
    margin: 0;
    border-left: 3px solid transparent;
    transition: margin 240ms ease;
    margin-bottom: -1px;

    .hide {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 240ms ease, opacity 240ms ease, overflow 240ms ease;
    }

    .rotate-neutral {
      transform: rotate(0deg);
      transition: transform 240ms ease;
    }
  }

  &.expanded {
    margin: 0.65rem 0;
    border-left: 3px solid ${props.primaryColor};
    transition: margin 240ms ease, border-left 240ms ease;

    &:first-of-type {
      margin-top: 0px;
    }

    .item-header {
      color: ${props.primaryColor};

      .rotate {
        transform: rotate(-45deg);
        transition: transform 240ms ease;
      }
    }

    .item-details {
      .show {
        max-height: 1000px;
        opacity: 1;
        transition: max-height 240ms ease, opacity 240ms ease;
      }
    }
  }

  .item-header {
    display: flex;
    padding: 0.75rem;
    margin-bottom: -1px;
    color: ${colors.darkGrey};
    font-weight: bold;

    &:active {
      background-color: ${colors.ultraLightGrey};
      transition: background-color 80ms ease;
    }

    .right {
      display: flex;
      margin-left: auto;
      font-weight: bold;
    }

    .left {
      display: flex;
      overflow: hidden;

      p {
        padding-right: 0.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .item-details {
    .details {
      padding: 0.5rem 0.75rem 0.25rem 0.75rem;
      color: ${colors.darkGrey};

      span {
        font-weight: bold;
      }

      p {
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  lang: state.language.language,
  primaryColor: state.appConfig.primaryColor,
});

const mapDispatchToProps = (dispatch) => ({
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  hideDialog: bindActionCreators(hideDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
