import getAppName from "./get-app-name";

export default () => {
  try {
    const serializedState = localStorage.getItem(`${getAppName()}-state`);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
