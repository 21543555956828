import React, { useState, useEffect } from "react";
import { format, parse } from "date-fns";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { notification } from "antd";
import params from "jquery-param";

import req from "../../utilities/request-utility";
// css
import { css } from "emotion";
import colors from "../../style/colors";
import common from "../../style/common";

// actions
import { hideModalPage } from "../../actions/uiActions";
// ui-components
import ScrollView from "../ui/ScrollView";
import Page from "../ui/Page";
import TextInput from "../ui/TextInput";
import TextareaInput from "../ui/TextareaInput";
import DropDown from "../ui/DropDown";
import DatePicker from "../ui/DatePicker";
import Button from "../ui/Button";

function AbsenceRegistration(props) {
  const { addRegistration, hideModalPage, registration = {} } = props;

  const lang = useSelector((s) => s.language.language);
  const selectedUser = useSelector((s) => s.users.selectedUser);
  const selectedUserName = useSelector((s) =>
    s.users.lists.length !== 0 ? s.users.lists.find((d) => d.externalId === s.users.selectedUser).name : null
  );

  const [formData, setFormData] = useState({
    absenceCode: registration.absenceCode ? registration.absenceCode.id : undefined,
    startDate: registration.startDate || format(new Date(), "yyyy-MM-dd"),
    endDate: registration.endDate || format(new Date(), "yyyy-MM-dd"),
    note: registration.note || "",
    hoursPerDay: registration.hoursPerDay || "",
  });
  const [absenceCodes, setAbsenceCodes] = useState([]);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [isAbsenceCodeLoading, setIsAbsenceCodeLoading] = useState(false);

  useEffect(() => {
    getAbsenceCodes();
    // eslint-disable-next-line
  }, []);

  async function getAbsenceCodes() {
    setIsAbsenceCodeLoading(true);
    await req()(`semcotime/absence/absence-codes?${params({ externalId: selectedUser })}`) // dummy url
      .then(({ data }) => setAbsenceCodes(data))
      .catch((err) => {
        notification.error({
          duration: 7,
          message: "FAILED",
          description: `${lang.errorGeneral} ${lang.tryAgainOrContactSupport}`,
        });
      });
    setIsAbsenceCodeLoading(false);
  }

  function handleFormChange(e, name = null) {
    if (!name) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, [name]: e.target.value });
    }
  }

  function getStartAndEndTimeAndDate() {
    const startDate = format(parse(formData.startDate, "yyyy-MM-dd", 0), "yyyyMMdd");
    const endDate = format(parse(formData.endDate, "yyyy-MM-dd", 0), "yyyyMMdd");

    return { startDate, endDate };
  }

  function isFormValid() {
    if (formData.startDate && formData.endDate && formData.absenceCode && formData.hoursPerDay) {
      return true;
    } else {
      return false;
    }
  }

  function handleSave() {
    const { startDate, endDate } = getStartAndEndTimeAndDate();

    const registration = {
      startDate,
      endDate,
      hoursPerDay: formData.hoursPerDay,
      note: formData.note,
      absenceCode: formData.absenceCode,
    };

    if (!isFormValid())
      return notification.error({ duration: 7, message: "FAILED", description: lang.errorAbsencesRequiredFieldsContent });

    setSubmittingForm(true);

    addRegistration(registration)
      .then(() => {
        notification.success({ duration: 7, message: "SUCCESS", description: lang.savedRegistrationToastContent });
        hideModalPage();
      })
      .catch((err) => {
        let errorMessage = "Unspecified error";
        if (err && err.response && err.response.data) errorMessage = err.response.data.message;

        notification.error({
          duration: 7,
          message: "FAILED",
          description: `${lang.errorSavingRegistrationToastContent}. Fejlkode: ${errorMessage}`,
        });

        setSubmittingForm(false);
      });
  }

  return (
    <Page className={componentStyles()}>
      <ScrollView style={{ paddingTop: common.topBarHeight + "px" }}>
        <div className="content-wrapper">
          {selectedUserName ? (
            <div className="title-container">
              <h2>{lang.general}</h2>
              <span>{selectedUserName}</span>
            </div>
          ) : (
            <h2>{lang.general}</h2>
          )}

          <p>{lang.absenceType}</p>
          <DropDown
            name="absenceCode"
            onChange={handleFormChange}
            options={absenceCodes.map((d) => ({ label: `${d.id} - ${d.name}`, value: d.id }))}
            placeholder={lang.chooseAbsenceType}
            loading={isAbsenceCodeLoading}
            value={formData.absenceCode}
          />

          <div className="seperator"></div>

          <p>{lang.startDate}</p>
          <DatePicker
            name="startDate"
            defaultDate={formData.startDate}
            onDateChange={(date) => handleFormChange({ target: { value: date } }, "startDate")}
          />

          <p>{lang.endDate}</p>
          <DatePicker
            name="endDate"
            defaultDate={formData.endDate}
            onDateChange={(date) => handleFormChange({ target: { value: date } }, "endDate")}
          />

          <p>{lang.hoursPerDay}</p>
          <TextInput
            name="hoursPerDay"
            type="number"
            max="24"
            min="0"
            value={formData.hoursPerDay}
            placeholder={lang.hours}
            onChange={handleFormChange}
          />

          <div className="seperator"></div>

          <p>{lang.note}</p>
          <TextareaInput name="note" value={formData.note} placeholder={lang.enterNote} onChange={handleFormChange} />

          <Button active={submittingForm} style={{ marginTop: "2rem" }} onClick={handleSave}>
            {lang.save}
          </Button>
        </div>
      </ScrollView>
    </Page>
  );
}

const boxPadding = 0.75;

const componentStyles = () => css`
  background-color: white;
  padding-top: ${common.topBarHeight};

  .content-wrapper {
    max-width: 800px;
    margin: 0 auto;

    .title-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  .scroll-view {
    padding: ${boxPadding}rem;
  }

  .time-input {
    display: flex;
    align-items: center;
  }

  p {
    padding: ${boxPadding}rem 0 0.5rem 0;
  }

  h2 {
    padding: ${boxPadding}rem 0 0.5rem 0;
  }

  .seperator {
    width: calc(100% + ${boxPadding * 2}rem);
    margin: 1rem -${boxPadding}rem 0 -${boxPadding}rem;
    height: 1px;
    background-color: ${colors.lightGrey};
  }
`;

const mapDispatchToProps = (dispatch) => ({
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(AbsenceRegistration);
