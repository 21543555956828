import { USERS } from "../actions/actionTypes";

const initialState = {
  loading: false,
  lists: [],
  selectedUser: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case USERS.FETCH.REQUEST:
      return { ...state, loading: true };
    case USERS.FETCH.SUCCESS:
      return { ...state, lists: payload, loading: false };
    case USERS.FETCH.FAILURE:
      return { ...state, loading: false };
    case USERS.SETUSER:
      return { ...state, selectedUser: payload };
    default:
      return state;
  }
};
