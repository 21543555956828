import req from "../utilities/request-utility";

import { GET_PAGES, SET_TRANSITION_DIRECTION, GET_PAGES_SUCCESS, GET_PAGES_FAILURE } from "./actionTypes";

export function getPages() {
  return function (dispatch, getState) {
    dispatch({
      type: GET_PAGES,
    });

    req()
      .get("/pages")
      .then((res) => res.data)
      .then((pages) => {
        dispatch({
          type: GET_PAGES_SUCCESS,
          payload: pages,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_PAGES_FAILURE,
        });
      });
  };
}

export function setTransitionModeToForward() {
  return {
    type: SET_TRANSITION_DIRECTION,
    payload: "forward",
  };
}

export function setTransitionModeToBackward() {
  return {
    type: SET_TRANSITION_DIRECTION,
    payload: "backward",
  };
}
