import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, parse, subDays, addDays } from "date-fns";
import moment from "moment";

// css
import { css } from "emotion";
import colors from "../../style/colors";
import common from "../../style/common";
import { ArrowBackIcon, AlertDecagramIcon, CloudDownloadIcon } from "mdi-react";

// utilities
import pageNavigator from "../../utilities/page-navigator";
import getPageFromId from "../../utilities/get-page-from-id";
import getBackButtonUrl from "../../utilities/get-back-button-url";
import req from "../../utilities/request-utility";

// actions
import { showModalPage } from "../../actions/uiActions";

// ui-components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import StatusBox from "../ui/StatusBox";
import Button from "../ui/Button";
import ActionWrapper from "../ui/ActionWrapper";
import WorkTimeRegistrationDK from "./WorkTimeRegistrationDK2";

// components
// import WorkTimeRegistration from "./WorkTimeRegistration";
import WorkTimeListItemDK from "./WorkTimeListItemDK";
import WorkTimeAlertDK from "./WorkTimeAlertDK";

function WorkTimeOverviewDK(props) {
  const dispatch = useDispatch();
  const pages = useSelector((s) => s.pages.pages);
  const lang = useSelector((s) => s.language.language);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));
  const [backButtonURL] = useState(getBackButtonUrl(page));
  const [updatingUI, setUpdatingUI] = useState(false);
  const [registrations, setRegistrations] = useState({
    data: [],
    error: false,
    errMsg: false,
    loading: false,
    fetchToDate: format(new Date(), "yyyyMMdd"),
  });
  const [timeSchedInfo, setTimeSchedInfo] = useState({ data: [], loading: true });
  const [absencesThisWeek, setAbsencesThisWeek] = useState({ data: [], loading: true });

  // redux data
  const { user } = useSelector(({ auth }) => ({ user: auth.user }));

  useEffect(() => {
    getSchedInfo();
    getRegistrations();
    getAbsencesThisWeek();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function dates(current, formatted = false) {
    var week = new Array();
    var normalDate = new Array();
    current.setDate(current.getDate() - current.getDay() + 1);
    for (var i = 0; i < 7; i++) {
      if (formatted === true) {
        week.push(format(new Date(current), "yyyyMMdd"));
      } else {
        normalDate.push(moment(new Date(current)).format("YYYY-MM-DD"));
      }

      current.setDate(current.getDate() + 1);
    }
    return {
      week: week,
      normalDate: normalDate,
      fromDate: week[0],
      toDate: week[6],
    };
  }

  async function getSchedInfo() {
    try {
      const { data } = await req()(`semcotime/work-hours-dk/EmpSchedInfo?CompanyIDs=DK10&EmpNos=${user.externalId}`);
      setTimeSchedInfo({ data: data, loading: false });
    } catch (error) {
      console.log(error);
    }
  }

  function addRegistration(registration) {
    return new Promise(
      (resolve, reject) =>
        void req()
          .post(`semcotime/work-hours-dk/d`, registration)
          .then(() => {
            resolve();
            setUpdatingUI(true);
            refreshRegistrations();
          })
          .catch(reject)
    );
  }

  function updateRegistrationNew(registration) {
    return new Promise(
      (resolve, reject) =>
        void req()
          .put(`semcotime/work-hours-dk/d/${registration.id}`, registration)
          .then(() => {
            resolve();
            setUpdatingUI(true);
            refreshRegistrations();
          })
          .catch(reject)
    );
  }

  function updateRegistration(registration) {
    return new Promise(
      (resolve, reject) =>
        void req()
          .put(`semcotime/work-hours-dk/${registration.id}`, registration)
          .then(() => {
            resolve();
            setUpdatingUI(true);
            refreshRegistrations();
          })
          .catch(reject)
    );
  }

  function deleteRegistration(registration) {
    return new Promise(
      (resolve, reject) =>
        void req()
          .delete(`semcotime/work-hours-dk/${registration.id}`)
          .then(() => {
            resolve();
            setUpdatingUI(true);
            refreshRegistrations();
          })
          .catch(reject)
    );
  }

  async function refreshRegistrations() {
    try {
      const toDate = format(addDays(new Date(), 365), "yyyyMMdd");
      const fromDate = registrations.fetchToDate;
      const { data } = await req()(`semcotime/work-hours-dk/?fromDate=${fromDate}&toDate=${toDate}`);

      setRegistrations({
        ...registrations,
        data: data,
        fetchToDate: format(subDays(parse(fromDate, "yyyyMMdd", 0), 0), "yyyyMMdd"),
        loading: false,
      });
      setUpdatingUI(false);
    } catch (err) {
      setRegistrations({
        ...registrations,
        loading: false,
      });
    }
  }

  async function getAbsencesThisWeek() {
    try {
      const fromDate = dates(new Date(), true).fromDate;
      const toDate = dates(new Date(), true).toDate;

      const { data } = await req()(`semcotime/absence-dk/?fromDate=${fromDate}&toDate=${toDate}`);
      setAbsencesThisWeek({ data: data, loading: false });
    } catch (error) {
      console.log(error);
    }
  }

  async function getRegistrations() {
    try {
      setRegistrations({ ...registrations, loading: true });

      let toDate = registrations.fetchToDate;
      const fromDate = format(subDays(parse(toDate, "yyyyMMdd", 0), 7), "yyyyMMdd");

      if (Object.keys(registrations.data).length === 0) {
        toDate = format(addDays(parse(toDate, "yyyyMMdd", 0), 365), "yyyyMMdd");
      }

      const { data } = await req()(`semcotime/work-hours-dk/?fromDate=${fromDate}&toDate=${toDate}`);

      setRegistrations({
        ...registrations,
        data: [...registrations.data, ...data],
        errMsg: false,
        loading: false,
        fetchToDate: format(subDays(parse(fromDate, "yyyyMMdd", 0), 0), "yyyyMMdd"),
      });
    } catch (err) {
      setRegistrations({
        ...registrations,
        loading: false,
        errMsg: true,
      });
    }
  }

  const registeredTimeForWholeWeek = registrations.data.filter((reg) => {
    let date = reg.timeKey.split("__")[0];
    if (date.length !== 10) {
      date = date.substr(0, 11);
    }

    return dates(new Date(), false).normalDate.includes(date.trim());
  });

  return (
    <Page className={componentStyles()}>
      <TopBar
        title={page.title}
        actionLeft={
          <ActionWrapper onClick={() => pageNavigator(backButtonURL, "backward")}>
            <ArrowBackIcon />
          </ActionWrapper>
        }
        actionRight={
          <ActionWrapper
            style={{ lineHeight: `${common.topBarHeight}px`, padding: "0 0.75rem" }}
            onClick={() => {
              dispatch(
                showModalPage({
                  content: () => <WorkTimeRegistrationDK title={lang.registerWorkTime} addRegistration={addRegistration} />,
                })
              );
            }}
          >
            {lang.register}
          </ActionWrapper>
        }
      />
      <ScrollView style={{ padding: "1rem 0" }}>
        {/* Error */}
        {registrations.error && !registrations.loading && (
          <StatusBox
            style={{ marginTop: "2rem" }}
            icon={<AlertDecagramIcon />}
            title={lang.errorLoadingRegistrationToastTitle}
            content={lang.errorLoadingRegistrationToastContent}
          />
        )}

        {/* Show alert when time is not submitted */}
        {!registrations.loading && !registrations.errMsg && (
          <WorkTimeAlertDK
            absences={absencesThisWeek.data}
            time={registeredTimeForWholeWeek}
            schedInfo={timeSchedInfo.data}
            loading={registrations.loading || timeSchedInfo.loading || updatingUI}
          />
        )}

        {/* Load registrations   */}
        {!registrations.error &&
          registrations.data.map((d) => (
            <WorkTimeListItemDK
              key={d.id}
              title={d.isNewType ? moment(d.data.date).format("YYYY-MM-DD") : d.timeKey}
              loading={registrations.loading || timeSchedInfo.loading || updatingUI}
              registration={d}
              updateRegistration={updateRegistration}
              deleteRegistration={deleteRegistration}
              updateRegistrationNew={updateRegistrationNew}
            />
          ))}

        <>
          <Button
            active={registrations.loading}
            onClick={() => {
              getRegistrations();
            }}
            buttonType="secondary"
            fullWidth={false}
            style={{ margin: "2rem auto 1rem auto" }}
          >
            <CloudDownloadIcon style={{ width: "1.2rem", height: "1.2rem", margin: "0 0.2rem -0.2rem 0" }} />{" "}
            {lang.loadAnotherWeek}
          </Button>
          <p style={{ color: colors.darkGrey, textAlign: "center" }}>
            {lang.diplayingRegistrationsFrom} {format(parse(registrations.fetchToDate, "yyyyMMdd", 0), "yyyy-MM-dd")}{" "}
            {lang.untilToday}
          </p>
        </>
      </ScrollView>
    </Page>
  );
}

const componentStyles = () => css`
  .filter {
    padding: 0.75rem;
  }

  .apply-filters-modal {
    padding: 0, 75rem;
    background-color: ${colors.white};
  }

  .filters-button {
    background-color: ${colors.midGrey};
    color: ${colors.black};
    display: block;
    width: calc(100% - 1.5rem);
    border: 0px transparent none;
    border-radius: 3px;
    font-size: 0.95rem;
    padding: 0.75rem 1.25rem;
    margin: 0.75rem;
    text-align: left;
    position: relative;

    .icon-right {
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export default WorkTimeOverviewDK;
