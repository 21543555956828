import React, { useState } from "react";
import { useSelector } from "react-redux";

import TopBar from "../components/ui/TopBar";
import ListItem from "../components/ui/ListItem";
import { ArrowBackIcon, ChevronRightIcon } from "mdi-react";
import Page from "./ui/Page";
import ScrollView from "./ui/ScrollView";

import pageNavigator from "../utilities/page-navigator";
import linkPathCreator from "../utilities/get-link-path-from-page-object";
import getPageFromId from "../utilities/get-page-from-id";
import Notification from "./ui/Notification";
import getBackButtonUrl from "../utilities/get-back-button-url";
import ActionWrapper from "./ui/ActionWrapper";
import breakpoints from "../config/breakpoints";

function NavigationList(props) {
  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));

  return (
    <Page>
      <TopBar
        title={page.title}
        actionLeft={
          <ActionWrapper onClick={() => pageNavigator(getBackButtonUrl(page, pages), "backward")}>
            <ArrowBackIcon />
          </ActionWrapper>
        }
      />
      <ScrollView style={{ padding: "1rem 0" }}>
        {page.pages.map((subPage) => (
          <ListItem
            maxWidth={breakpoints.lg}
            clickable={true}
            onClick={() => pageNavigator(linkPathCreator(subPage, "absolute"), "forward")}
            title={subPage.title}
            notificationsRight={subPage.notifications > 0 && <Notification notifications={subPage.notifications} />}
            iconRight={<ChevronRightIcon />}
            key={subPage.id}
          />
        ))}
      </ScrollView>
    </Page>
  );
}

export default NavigationList;
