import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format, parse, subDays, addDays } from "date-fns";
import { notification } from "antd";
import params from "jquery-param";

// css
import { css } from "emotion";
import colors from "../../style/colors";
import common from "../../style/common";
import { ArrowBackIcon, AlertDecagramIcon, CloudDownloadIcon, ThumbDownOutlineIcon } from "mdi-react";

// utilities
import pageNavigator from "../../utilities/page-navigator";
import getPageFromId from "../../utilities/get-page-from-id";
import getBackButtonUrl from "../../utilities/get-back-button-url";
import req from "../../utilities/request-utility";

// actions
import { showModalPage } from "../../actions/uiActions";

// ui-components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import StatusBox from "../ui/StatusBox";
import Button from "../ui/Button";
import ActionWrapper from "../ui/ActionWrapper";

// components
import WorkTimeRegistration from "./WorkTimeRegistration";
import WorkTimeListItem from "./WorkTimeListItem";

class WorkTimeOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {},
      // forms
      formData: [],
      validRegistrationForm: false,
      fetchToDate: format(new Date(), "yyyyMMdd"),
      // registrations
      loading: false,
      error: false,
      registrations: {},
      loadingDateKeys: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { pages, match } = this.props;

    this.setState({
      page: getPageFromId(pages, match.params.pageId),
      backButtonUrl: getBackButtonUrl(getPageFromId(pages, match.params.pageId), pages),
    });

    this.getRegistrations();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getRegistrations = async () => {
    try {
      const { selectedUser } = this.props;

      this.setState({ loading: true });

      let toDate = this.state.fetchToDate;
      let fromDate = format(subDays(parse(toDate, "yyyyMMdd", 0), 7), "yyyyMMdd");

      if (Object.keys(this.state.registrations).length === 0) {
        toDate = format(addDays(parse(toDate, "yyyyMMdd", 0), 365), "yyyyMMdd");
      }
      let { data } = await req()(`semcotime/work-hours/v3/?${params({ externalId: selectedUser, fromDate, toDate })}`);

      // Add registrations to current ones:
      let mutRegistrations = Object.assign(this.state.registrations, {});
      Object.keys(data).forEach((key) => {
        mutRegistrations[key] = data[key];
      });

      this._isMounted === true && this.setState({ registrations: mutRegistrations });
      this.setState({ loading: false });

      // Update fromDate
      this.setState({ fetchToDate: format(subDays(parse(fromDate, "yyyyMMdd", 0), 0), "yyyyMMdd") });
    } catch (err) {
      this.setState({ loading: false });
      this.setState({ error: true });
    }
  };

  refreshRegistrations = async () => {
    try {
      this.setState({ loading: true });

      const { selectedUser } = this.props;

      const toDate = format(addDays(new Date(), 365), "yyyyMMdd");
      const fromDate = this.state.fetchToDate;
      const { data } = await req()(`semcotime/work-hours/v3/?${params({ externalId: selectedUser, fromDate, toDate })}`);

      this.setState({ loading: false, registrations: data });
    } catch {
      this.setState({ error: true, loading: false });
    }
  };

  addRegistration = async (registration) => {
    const { selectedUser } = this.props;

    return new Promise((resolve, reject) => {
      req()
        .post(`semcotime/work-hours/v3?${params({ externalId: selectedUser })}`, registration)
        .then(() => {
          setTimeout(() => {
            this.refreshRegistrations();
          }, 1000);
          resolve();
          console.log("then");
        })
        .catch(reject);
    });
  };

  updateRegistration = async (registration) => {
    const { selectedUser } = this.props;

    return new Promise((resolve, reject) => {
      req()
        .put(`semcotime/work-hours/v3?${params({ externalId: selectedUser })}`, registration)
        .then((res) => {
          resolve();
          setTimeout(() => {
            this.refreshRegistrations();
          }, 1000);
        })
        .catch(reject);
    });
  };

  deleteRegistration = async (registration) => {
    this.setState({ loading: true });

    const { selectedUser } = this.props;

    return new Promise((resolve, reject) => {
      const date = registration.registrations[0].date;

      req()
        .delete(
          `semcotime/work-hours/v3/${date}?${params({
            externalId: selectedUser,
            timeCardTransactionId: registration.timeCardTransactionId,
            incrementTransactionId: registration.incrementTransactionId,
          })}`
        )
        .then((res) => {
          resolve();
          setTimeout(() => {
            this.refreshRegistrations();
          }, 1000);
        })
        .catch((err) => {
          let errorMessage = "Unspecified error";
          if (err && err.response && err.response.data) errorMessage = err.response.data.message;
          notification.error({
            duration: 7,
            message: "FAILED",
            description: `Could not delete registration. Error: ${errorMessage}`,
          });
        });
    });
  };

  getShowingDisplay = () => {
    const { fetchToDate } = this.state;
    const { lang, selectedUserName } = this.props;

    if (selectedUserName)
      return `${lang.displayingRegistrationsFor} ${selectedUserName} ${lang.from} ${format(
        parse(fetchToDate, "yyyyMMdd", 0),
        "yyyy-MM-dd"
      )} ${lang.untilToday}`;

    return `${lang.diplayingRegistrationsFrom} ${format(parse(fetchToDate, "yyyyMMdd", 0), "yyyy-MM-dd")} ${
      lang.untilToday
    }`;
  };

  render() {
    const { page, backButtonUrl, registrations, loading, error } = this.state;
    const { showModalPage, lang } = this.props;

    return (
      <Page className={componentStyles()}>
        <TopBar
          title={page.title}
          actionLeft={
            <ActionWrapper onClick={() => pageNavigator(backButtonUrl, "backward")}>
              <ArrowBackIcon />
            </ActionWrapper>
          }
          actionRight={
            <ActionWrapper
              style={{ lineHeight: `${common.topBarHeight}px`, padding: "0 0.75rem" }}
              onClick={() => {
                showModalPage({
                  content: () => (
                    <WorkTimeRegistration title={lang.registerWorkTime} addRegistration={this.addRegistration} />
                  ),
                });
              }}
            >
              {lang.register}
            </ActionWrapper>
          }
        />
        <ScrollView style={{ padding: "1rem 0" }}>
          {/* Loading */}
          {/* {loading && !error && <InlineSpinner />} */}

          {/* Error */}
          {error && !loading && (
            <StatusBox
              style={{ marginTop: "2rem" }}
              icon={<AlertDecagramIcon />}
              title={lang.errorLoadingRegistrationToastTitle}
              content={lang.errorLoadingRegistrationToastContent}
            />
          )}

          {/* Load registrations   */}
          {!error &&
            Object.keys(registrations).map((key) => (
              <WorkTimeListItem
                key={key}
                title={key}
                registration={registrations[key]}
                updateRegistration={this.updateRegistration}
                deleteRegistration={this.deleteRegistration}
              />
            ))}

          <>
            <Button
              active={loading}
              onClick={this.getRegistrations}
              buttonType="secondary"
              fullWidth={false}
              style={{ margin: "2rem auto 1rem auto" }}
            >
              <CloudDownloadIcon style={{ width: "1.2rem", height: "1.2rem", margin: "0 0.2rem -0.2rem 0" }} />{" "}
              {lang.loadAnotherWeek}
            </Button>
            <p style={{ color: colors.darkGrey, textAlign: "center" }}>{this.getShowingDisplay()}</p>
          </>
        </ScrollView>
      </Page>
    );
  }
}

const componentStyles = () => css`
  .filter {
    padding: 0.75rem;
  }

  .apply-filters-modal {
    padding: 0, 75rem;
    background-color: ${colors.white};
  }

  .filters-button {
    background-color: ${colors.midGrey};
    color: ${colors.black};
    display: block;
    width: calc(100% - 1.5rem);
    border: 0px transparent none;
    border-radius: 3px;
    font-size: 0.95rem;
    padding: 0.75rem 1.25rem;
    margin: 0.75rem;
    text-align: left;
    position: relative;

    .icon-right {
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const mapStateToProps = (state) => ({
  pages: state.pages.pages,
  lang: state.language.language,
  selectedUser: state.users.selectedUser,
  selectedUserName:
    state.users.lists.length !== 0 ? state.users.lists.find((d) => d.externalId === state.users.selectedUser).name : null,
});

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkTimeOverview);
