import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";
import { keyBy } from "lodash";
import moment from "moment";

import colors from "../../style/colors";

import workHourState from "../../config/semcotimedk-workhour-states";

// actions
import { updateModalPage } from "../../actions/uiActions";

// ui-components
import ScrollView from "../ui/ScrollView";
import Page from "../ui/Page";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import common from "../../style/common";
import req from "../../utilities/request-utility";

// utilities
import getTotalHours from "../../utilities/get-total-hours";

//components
import TimeRegistrationList from "../semco-time/TimeRegistrationList";

function WorkTimeDetails(props) {
  const lang = useSelector((s) => s.language.language);
  const { registration } = props;

  // The first element in a the list of registrations for this day (i know.. It makes little sense)
  const registrationExtract = registration;
  const [detailsData, setDetailsData] = useState(null);

  const [metaData, setMetaData] = useState({ activity: null, project: null, subProject: null });
  const getAdditionalData = async (data) => {
    try {
      const [{ data: project }, { data: subProject }, { data: activity }, { data: dabTrade }] = await Promise.all([
        req()(`semcotime/projects/${data.project}`),
        req()(`semcotime/projects/${data.project}/sub-projects/${data.subProject}`),
        req()(
          `semcotime/projects/${data.project}/sub-projects/${data.subProject}/activities/${
            data.hasOwnProperty("timeRegistrations") ? data.activity : data.projectActivity
          }`
        ),
        req()(`semcotime/dab-trade-codes`),
      ]);
      const dabTradeObj = keyBy(dabTrade, "DabTradeId");

      const dataTrade = data.hasOwnProperty("timeRegistrations") ? data.trade : data.dabTrade;
      setMetaData({
        activity,
        project,
        subProject,
        dabTrade: dataTrade ? (dabTradeObj[dataTrade] ? dabTradeObj[dataTrade] : null) : null,
      });
    } catch (error) {
      console.log(error);
    }
  };

  /** As some data isn't available in the overview, this function gets what is missing from the api */
  const getDetailsData = async () => {
    try {
      const {
        data: { data },
      } = await req()(`semcotime/work-hours-dk/${registrationExtract.id}`);

      setDetailsData(data);
      getAdditionalData(data);
    } catch (err) {
      setDetailsData("error");
    }
  };

  function getParsedDateTime(dateString) {
    try {
      return new Date(dateString).toDateString().split(" ").slice(1).join(" ");
    } catch {
      return lang.noDate;
    }
  }

  function getTime(hours, minutes) {
    if (hours < 10) {
      return `0${hours}:${minutes}`;
    } else {
      return `${hours}:${minutes}`;
    }
  }

  useEffect(() => {
    getDetailsData();
    // eslint-disable-next-line
  }, []);

  console.log("registrationExtract: ", registrationExtract);
  console.log("metaData: ", metaData);
  console.log("detailsData: ", detailsData);
  return (
    <Page className={componentStyles()}>
      <ScrollView style={{ padding: "0.75rem" }}>
        {detailsData === null && <InlineSpinner style={{ margin: "1rem auto" }} />}
        {detailsData !== null && detailsData === "error" && <StatusBox title="Unable to get data" />}

        {detailsData !== null && detailsData !== "error" && registration.state === workHourState.deleted && (
          <div className="deleted">DELETED IN IFS</div>
        )}

        {detailsData !== null && detailsData !== "error" && (
          <>
            {/* general */}
            <h2>{lang.general}</h2>
            {registrationExtract.data.hasOwnProperty("timeRegistrations") ? (
              <>
                <div className="text-wrapper">
                  <p>{lang.date} </p>
                  <p>{moment(detailsData.date).format("DD/MM/YY dddd")}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.location} </p>
                  <p>{detailsData.location}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.project} </p>
                  <p>{metaData.project ? metaData.project.name : detailsData.project}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.subproject} </p>
                  <p>{metaData.subProject ? metaData.subProject.description : detailsData.subproject}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.activity} </p>
                  <p>
                    {metaData.activity
                      ? `${metaData.activity.id} - ${metaData.activity.description}`
                      : detailsData.projectActivity}
                  </p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.dabTrade}</p>
                  <p>{metaData.dabTrade ? `${metaData.dabTrade.DabTradeId} - ${metaData.dabTrade.DabTradeDesc}` : "N/A"}</p>
                </div>
                <div className="time-wrapper">
                  <p>Time Registrations: </p>
                  <TimeRegistrationList isView={true} value={registrationExtract.data.timeRegistrations} />
                </div>
              </>
            ) : (
              <>
                <div className="text-wrapper">
                  <p>{lang.project} </p>
                  <p>{metaData.project ? metaData.project.name : detailsData.project}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.subproject} </p>
                  <p>{metaData.subProject ? metaData.subProject.description : detailsData.subproject}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.activity} </p>
                  <p>
                    {metaData.activity
                      ? `${metaData.activity.id} - ${metaData.activity.description}`
                      : detailsData.projectActivity}
                  </p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.dabTrade}</p>
                  <p>{metaData.dabTrade ? `${metaData.dabTrade.DabTradeId} - ${metaData.dabTrade.DabTradeDesc}` : "N/A"}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.location} </p>
                  <p>{detailsData.dabLocation}</p>
                </div>

                <div className="text-wrapper">
                  <p>{lang.customerDetail} </p>
                  <p>{detailsData.customerDetail}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.customerNote}</p>
                  <p>{detailsData.customerNote}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.transport}</p>
                  <p>{detailsData.transport ? "Yes" : "No"}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.heliTime}</p>
                  <p>{detailsData.heliTime ? "Yes" : "No"}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.standby}</p>
                  <p>{detailsData.standby ? "Yes" : "No"}</p>
                </div>
                {/* Date and time */}
                <div className="text-wrapper">
                  <p>{lang.shift}</p>
                  <p>{detailsData.shift}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.changeShift}</p>
                  <p>{detailsData.changeShift ? "Yes" : "No"}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.date} </p>
                  <p>{getParsedDateTime(detailsData.date)}</p>
                </div>

                <div className="text-wrapper">
                  <p>{lang.startTime} </p>
                  <p>{getTime(detailsData.startTimeHours, detailsData.startTimeMinutes)}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.endTime} </p>
                  <p>{getTime(detailsData.endTimeHours, detailsData.endTimeMinutes)}</p>
                </div>
                <div className="text-wrapper">
                  <p>{lang.hours} </p>
                  <p>{getTotalHours(detailsData)}</p>
                </div>

                <br />
                {detailsData.activities.length !== 0 && (
                  <div className="details">
                    <p>{lang.sc_extra}</p>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {detailsData.activities.map((d) => (
                          <tr key={d.id}>
                            <td>
                              {d.id} - {d.name}
                            </td>
                            <td>{d.hours} hours</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </ScrollView>
    </Page>
  );
}

const componentStyles = () => css`
  background-color: white;
  padding-top: ${common.topBarHeight}px;

  .deleted {
    width: 100%;
    text-align: center;
    background-color: red;
    color: #fff;
    padding: 10px;
    margin-bottom: 15px;
  }

  .details {
    table {
      tr {
        th {
          text-align: left;
        }
        td {
          text-align: left;
          padding: 5px 20px 5px 0px;
        }
      }
    }
  }

  p {
    padding: 0.5rem 0 0 0;
  }

  p.description {
    padding: 0.25rem 0 0 0;
    color: ${colors.darkGrey};
  }

  div.seperator {
    background-color: ${colors.lightGrey};
    height: 1px;
    margin: 1.5rem -0.75rem 1rem -0.75rem;
  }

  div.text-wrapper {
    display: flex;

    p:first-child {
      padding-right: 0.5rem;
    }

    p:not(:first-child) {
      color: ${colors.darkGrey};
      overflow: hidden;
    }
  }
  div.time-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(WorkTimeDetails);
