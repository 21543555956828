import {
  SHOW_CONTEXT_MENU,
  HIDE_CONTEXT_MENU,
  SHOW_DIALOG,
  HIDE_DIALOG,
  SHOW_MODAL_PAGE,
  UPDATE_MODAL_PAGE_CONTENT,
  HIDE_MODAL_PAGE,
  SHOW_LIGHT_BOX,
  HIDE_LIGHT_BOX,
  CLEAR_CONTEXT_MENU_ACTIONS,
  CLEAR_MODAL_PAGE,
  CLEAR_DIALOG,
  TOGGLE_WORK_HOUR_MODAL,
} from "../actions/actionTypes";

const initialState = {
  contextMenu: {
    active: false,
    actions: [],
    defaultActionTitle: undefined,
    closeOnActionCallback: true,
  },
  Lightbox: {
    active: false,
    images: [],
  },
  modalPage: {
    active: false,
    title: null,
    content: null,
    closeCallback: null,
    actionRight: null,
  },
  dialog: {
    active: false,
    actions: [],
    title: null,
    content: null,
    icon: null,
    primaryActionTitle: null,
    secondaryActionTitle: null,
    primaryAction: null,
    styleType: null,
    disableSecondaryAction: false,
  },
  workHourModal: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Context menu
    case SHOW_CONTEXT_MENU:
      // Let you dispatch actions as an array if no options are needed
      if (Array.isArray(action.payload)) {
        action.payload.actions = action.payload;
      }

      return {
        ...state,
        contextMenu: {
          active: true,
          actions: action.payload.actions,
          closeOnActionCallback: action.payload.closeOnActionCallback,
          defaultActionTitle: action.payload.defaultActionTitle || undefined,
        },
      };

    case HIDE_CONTEXT_MENU:
      return {
        ...state,
        contextMenu: {
          ...state.contextMenu,
          active: false,
        },
      };

    case CLEAR_CONTEXT_MENU_ACTIONS:
      return {
        ...state,
        contextMenu: {
          ...state.contextMenu,
          actions: [],
        },
      };

    // Lightbox
    case SHOW_LIGHT_BOX:
      return {
        ...state,
        Lightbox: {
          active: true,
          images: action.payload.images,
        },
      };

    case HIDE_LIGHT_BOX:
      return {
        ...state,
        Lightbox: {
          ...state.Lightbox,
          active: false,
        },
      };

    // Modal page
    case SHOW_MODAL_PAGE:
      return {
        ...state,
        modalPage: {
          active: true,
          title: action.payload.title,
          actionRight: action.payload.actionRight,
          content: action.payload.content,
          closeCallback: action.payload.closeCallback,
        },
      };

    case UPDATE_MODAL_PAGE_CONTENT:
      return {
        ...state,
        modalPage: {
          ...state.modalPage,
          ...action.payload,
        },
      };

    case HIDE_MODAL_PAGE:
      return {
        ...state,
        modalPage: {
          ...state.modalPage,
          active: false,
        },
      };

    case CLEAR_MODAL_PAGE:
      return {
        ...state,
        modalPage: {
          active: false,
          actionRight: null,
          title: null,
          closeCallback: null,
          content: null,
        },
      };

    // Dialog
    case SHOW_DIALOG:
      return {
        ...state,
        dialog: {
          active: true,
          ...action.payload,
        },
      };
    case HIDE_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          active: false,
        },
      };
    case CLEAR_DIALOG:
      return {
        ...state,
        dialog: {
          active: false,
          actions: [],
          title: null,
          content: null,
          icon: null,
          primaryActionTitle: null,
          secondaryActionTitle: null,
          primaryAction: null,
          styleType: null,
          disableSecondaryAction: false,
        },
      };

    case TOGGLE_WORK_HOUR_MODAL:
      return {
        ...state,
        workHourModal: action.payload,
      };
    default:
      return state;
  }
}
