import React, { Component } from "react";
import { css } from "emotion";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import smoothScrollToTop from "../../utilities/smooth-scroll-to-top";

class ScrollView extends Component {
  constructor(props) {
    super(props);
    this.targetRef = React.createRef();
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    disableBodyScroll(this.targetRef.current);
  }

  componentWillUnmount() {
    enableBodyScroll(this.targetRef.current);
  }

  onScroll(e) {
    if (
      typeof this.props.onScrollEnd === "function" &&
      e.target.scrollTop + e.target.getBoundingClientRect().height + (200 || this.props.scrollThreshold) >
        e.target.scrollHeight
    ) {
      this.props.onScrollEnd();
    }

    if (typeof this.props.onScroll === "function") {
      // For legacy implementations where the scrollView's scroll is handled in parent component
      this.props.onScroll(e);
    }
  }

  scrollToTop() {
    smoothScrollToTop(this.targetRef.current, 100);
    this.props.setScrollTop(false);
  }

  render() {
    if (this.props.scrollTop) {
      this.scrollToTop();
    }

    return (
      <div
        id={this.props.id}
        ref={this.targetRef}
        className={`scroll-view ${componentStyle(this.props)} ${this.props.className || ""} ${
          this.props.maxWidth ? "use-max-width" : ""
        }`}
        style={this.props.style}
        onScroll={this.onScroll}
        data-test-id={this.props["data-test-id"]}
      >
        {this.props.children}
      </div>
    );
  }
}

const componentStyle = (props) => css`
  flex: 100% 1 1;
  overflow-x: ${props.allowHorizontalScroll === true ? "auto" : "hidden"};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.use-max-width {
    max-width: ${props.maxWidth}px;
    margin: 0 auto;
  }
`;

export default ScrollView;
