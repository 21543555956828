import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import { notification } from "antd";

import req from "../../utilities/request-utility";

// Actions
import { showContextMenu } from "../../actions/uiActions";
import { renewToken } from "../../actions/authActions";
import { getLanguage } from "../../actions/languageActions";

// Utilities
import pageNavigator from "../../utilities/page-navigator";
import getBackButtonURL from "../../utilities/get-back-button-url";

// Components
import UserProfile from "./UserProfile";
import UserPointlog from "./UserPointlog";
import UserSettings from "./UserSettings";
import TopBar from "../../components/ui/TopBar";
import TabBar from "../../components/ui/TabBar";
import TabView from "../../components/ui/TabView";
import Page from "../../components/ui/Page";
import { ArrowBackIcon, CogOutlineIcon, TrophyVariantOutlineIcon, AccountOutlineIcon } from "mdi-react";
import saveStateToLocalStorage from "../../utilities/save-state-to-local-storage";
import InlineSpinner from "../ui/InlineSpinner";

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Page and navigation
      enablePointLog: this.props.appConfig.enableHighscore || false,
      page: {},
      tabs: [],
      activeTabIndex: 0,
      backButtonUrl: getBackButtonURL(),
      profileEditModalActive: false,
      reloadingLanguage: false, // removes the entire page while reload happens

      // Profile
      userData: null,
      userDataLoading: true,
      userDataError: false,

      // Pointlog
      pointlog: null,
      pointlogLoading: true,
      pointlogError: false,

      // Settings
      settings: null,
      settingsLoading: true,
      settingsError: false,
      activeIdentifiers: [],
    };
  }

  onSettingToggle = (groupIndex, settingIndex) => {
    let settings = [...this.state.settings];
    settings[groupIndex].settings[settingIndex].value = !settings[groupIndex].settings[settingIndex].value;
    this.setState({ settings });
  };

  componentDidMount() {
    this._mounted = true;

    // Get tabId from queryParam
    let queryStrings = queryString.parse(window.location.search);
    if (queryStrings.tab) {
      this.setState({
        activeTabIndex: Number(queryStrings.tab),
      });
    }

    // Fetch data from api
    this.getUserData();
    this.getPointlog();
    this.getSettings();

    // Create tabs (This is not the most beautiful code)
    // The profile will always be the first tab
    let tabs = [
      <>
        <AccountOutlineIcon /> {this.props.lang.myProfile}
      </>,
    ];

    // If enablePointLog is set for this app add the pointlog
    if (this.state.enablePointLog)
      tabs.push(
        <>
          <TrophyVariantOutlineIcon /> {this.props.lang.pointlog}
        </>
      );

    // the settings tab will also always be present
    tabs.push(
      <>
        <CogOutlineIcon /> {this.props.lang.settings}
      </>
    );

    this.setState({
      tabs,
    });
  }

  async getUserData() {
    try {
      let { data: user } = await req().get(`/users/${this.props.match.params.userId}/`);
      this._mounted &&
        this.setState({
          userData: user,
          userDataLoading: false,
        });
    } catch (err) {
      this._mounted &&
        this.setState({
          userDataLoading: false,
          userDataError: true,
        });
    }
  }

  async getPointlog() {
    try {
      let { data: pointlog } = await req().get(`/pointlog/`);
      this._mounted &&
        this.setState({
          pointlog,
          pointlogLoading: false,
        });
    } catch (err) {
      this._mounted &&
        this.setState({
          pointlogLoading: false,
          pointlogError: true,
        });
    }
  }

  async getSettings() {
    try {
      let { data: settings } = await req()(`settings`);
      this._mounted &&
        this.setState({
          settings: settings,
          settingsLoading: false,
          settingsError: false,
        });
    } catch (err) {
      this._mounted &&
        this.setState({
          settingsLoading: false,
          settingsError: true,
        });
    }
  }

  updateSetting = async (setting) => {
    let { activeIdentifiers } = this.state;
    try {
      // Activeate spinner (after 300ms)
      let timeout = setTimeout(() => this.setState({ activeIdentifiers: [...activeIdentifiers, setting.identifier] }), 300);

      // Get datas
      let { data: settings } = await req().put(`/settings/${setting.identifier}`, { value: setting.value });

      // Cancel spinner (request finished in less than 300ms)
      clearTimeout(timeout);

      // Update data
      this._mounted &&
        this.setState({
          settings: settings,
        });
    } catch (err) {
      notification.error({
        duration: 7,
        message: "FAILED",
        description: "We were unable to update your settings, Try again later or contact support@toecho.dk",
      });
    } finally {
      // Remove spinner
      setTimeout(() => {
        let index = activeIdentifiers.indexOf(setting.identifier);
        this._mounted &&
          index &&
          this.setState({ activeIdentifiers: [...activeIdentifiers.slice(0, index), ...activeIdentifiers.slice(index, 0)] });
      }, 300);

      // If language id is the setting being updated update jwt token and refresh page
      if (setting.identifier === "languageId") {
        this.setState({
          reloadingLanguage: true,
        });

        this.props.renewToken(() => {
          this.props.getLanguage();
          setTimeout(() => {
            saveStateToLocalStorage(this.props.state);
            document.location.reload(true);
          }, 2000);
        });
      }
    }
  };

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    let {
      userData,
      userDataLoading,
      userDataError,
      pointlog,
      pointlogLoading,
      pointlogError,
      settings,
      settingsLoading,
      settingsError,
      activeTabIndex,
      tabs,
      backButtonUrl,
      activeIdentifiers,
    } = this.state;
    let { user, appConfig, match } = this.props;

    // This is sort of the same deal as with the tabs. Every app has profile and settings but only some have a pointlog
    // Start by creating an array with the profile as the first element
    let tabComponents = [
      <UserProfile
        userData={userData}
        userDataLoading={userDataLoading}
        userDataError={userDataError}
        allowUserToEdit={Number(match.params.userId) === user.id ? true : false}
      />,
    ];
    // If pointlog is enabled for this app, also add  this to the array
    if (this.state.enablePointLog) {
      tabComponents.push(
        <UserPointlog
          pointlog={pointlog}
          pointlogLoading={pointlogLoading}
          pointlogError={pointlogError}
          primaryColor={appConfig.primaryColor}
        />
      );
    }
    // Add the settings to the array too
    tabComponents.push(
      <UserSettings
        userData={userData}
        settings={settings}
        settingsLoading={settingsLoading}
        settingsError={settingsError}
        updateSetting={this.updateSetting}
        activeIdentifiers={activeIdentifiers}
      />
    );

    if (this.state.reloadingLanguage) {
      return (
        <Page>
          <TopBar title="..." />
          <TabBar tabs={[]} />
          <InlineSpinner title="Updating language..." style={{ marginTop: "3rem" }} />
        </Page>
      );
    }

    return (
      <Page>
        <TopBar
          title="Profile"
          actionLeft={
            <ArrowBackIcon
              onClick={() => {
                pageNavigator(backButtonUrl, "backward");
              }}
              data-test-id="arrow-back"
            />
          }
        />

        {Number(match.params.userId) === user.id && (
          <TabBar
            activeTabIndex={activeTabIndex}
            tabs={tabs.map((tab, tabIndex) => ({
              title: tab,
              onClick: () => {
                this.setState({ activeTabIndex: tabIndex });
              },
            }))}
          />
        )}
        <TabView activeTabIndex={activeTabIndex} tabs={tabComponents} useScrollView={true} />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  pages: state.pages.pages,
  appConfig: state.appConfig,
  user: state.auth.user,
  lang: state.language.language,
  state: state, // This is used to update localStorage when changing language
});
const mapDispatchToProps = (dispatch) => ({
  renewToken: bindActionCreators(renewToken, dispatch),
  getLanguage: bindActionCreators(getLanguage, dispatch),
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
