import React, { useState, useEffect } from "react";
import { format, isAfter, parse } from "date-fns";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { notification } from "antd";

import req from "../../utilities/request-utility";
// css
import { css } from "emotion";
import colors from "../../style/colors";
import common from "../../style/common";

import { ThumbDownOutlineIcon, InfoOutlineIcon, CheckCircleOutlineIcon } from "mdi-react";
// actions
import { hideModalPage } from "../../actions/uiActions";
// ui-components
import ScrollView from "../ui/ScrollView";
import Page from "../ui/Page";
import TextInput from "../ui/TextInput";
import TextareaInput from "../ui/TextareaInput";
import DropDown from "../ui/DropDown";
import DatePicker from "../ui/DatePicker";
import Button from "../ui/Button";
import CheckboxGroup from "../ui/CheckboxGroup";

function AbsenceRegistrationDK(props) {
  const { addRegistration, hideModalPage, registration = {} } = props;
  const lang = useSelector((s) => s.language.language);
  const [formData, setFormData] = useState({
    absenceCode: registration.data ? registration.data.absenceCode : undefined,
    startDate: registration.data
      ? format(parse(registration.data.startDate, "yyyyMMdd", 0), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    startTimeMinutes: registration.data ? registration.data.startTime.split(":")[1] : "",
    startTimeHours: registration.data ? registration.data.startTime.split(":")[0] : "",
    endDate: registration.data
      ? format(parse(registration.data.endDate, "yyyyMMdd", 0), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    endTimeMinutes: registration.data ? registration.data.endTime.split(":")[1] : "",
    endTimeHours: registration.data ? registration.data.endTime.split(":")[0] : "",
    note: registration.data ? registration.data.note : "",
    fullDay: registration.data ? registration.data.fullDay : false,
    foreman: registration.data ? registration.data.foreman : "",
  });
  const [absenceCodes, setAbsenceCodes] = useState([]);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [foremen, setForemen] = useState([]);

  useEffect(() => {
    getAbsenceCodes();
    getForemen();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formData.startDate !== "" && formData.endDate !== "") {
      if (isAfter(new Date(formData.startDate), new Date(formData.endDate)))
        setFormData({ ...formData, endDate: formData.startDate });
    }

    //TODO: Add validation for end time if it's less than the start time
    // if (
    //   formData.startDate !== "" &&
    //   formData.endDate !== "" &&
    //   formData.endTimeHours !== "" &&
    //   formData.endTimeMinutes !== "" &&
    //   formData.startTimeHours !== "" &&
    //   formData.startTimeMinutes !== ""
    // ) {
    //   if (
    //     isAfter(
    //       new Date(`${formData.startDate} ${formData.startTimeHours}:${formData.startTimeMinutes}`),
    //       new Date(`${formData.endDate} ${formData.endTimeHours}:${formData.endTimeMinutes}`)
    //     )
    //   )
    //     setFormData({ ...formData, endTimeHours: formData.startTimeHours, endTimeMinutes: formData.startTimeMinutes });
    // }
    // eslint-disable-next-line
  }, [
    formData.startDate,
    formData.endDate,
    formData.endTimeHours,
    formData.endTimeMinutes,
    formData.startTimeHours,
    formData.startTimeMinutes,
  ]);

  function getAbsenceCodes() {
    req()(`semcotime/absence-dk/absence-codes-dk`)
      .then(({ data }) => setAbsenceCodes(data))
      .catch((err) => {
        notification.error({
          duration: 7,
          message: "FAILED",
          description: `${lang.errorGeneral} ${lang.tryAgainOrContactSupport}`,
        });
      });
  }

  const getForemen = () => {
    try {
      req()(`semcotime/foremen/`).then(({ data }) => {
        const assignedIds = data.assigned.map((d) => d.id);

        setForemen([
          ...data.assigned,
          { disabled: true, id: "", name: "------------------" },
          ...data.all.filter((d) => !assignedIds.includes(d.id)),
        ]);
      });
    } catch (err) {
      console.log("err: ", err);
    }
  };

  function handleFormChange(e, name = null) {
    if (!name) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, [name]: e.target.value });
    }
  }

  function getStartAndEndTimeAndDate() {
    let startDate = format(parse(formData.startDate, "yyyy-MM-dd", 0), "yyyyMMdd");
    let endDate = format(parse(formData.endDate, "yyyy-MM-dd", 0), "yyyyMMdd");

    return { startDate, endDate };
  }

  function isFormValid() {
    if (formData.startDate && formData.endDate && formData.absenceCode && formData.foreman) {
      if (formData.fullDay) return true;
      else if (formData.endTimeHours && formData.endTimeMinutes && formData.startTimeHours && formData.startTimeMinutes)
        return true;
    } else {
      return false;
    }
  }

  const getTime = (date, hours, minutes) => {
    return !formData.fullDay && hours !== "" && minutes !== ""
      ? format(parse(`${date} ${hours}:${minutes}`, "yyyyMMdd HH:mm", 0), "yyyyMMdd HH:mm").split(" ")[1]
      : "";
  };

  function handleSave() {
    let { startDate, endDate } = getStartAndEndTimeAndDate();

    let registrationForm = {
      startDate,
      startTime: getTime(startDate, formData.startTimeHours, formData.startTimeMinutes),
      endDate,
      endTime: getTime(endDate, formData.endTimeHours, formData.endTimeMinutes),
      note: formData.note,
      absenceCode: formData.absenceCode,
      fullDay: formData.fullDay,
      foreman: formData.foreman,
      type: "absence",
    };

    if (registration.id) registrationForm.id = registration.id;

    if (!isFormValid())
      return notification.error({ duration: 7, message: "FAILED", description: lang.errorAbsencesRequiredFieldsContent });

    setSubmittingForm(true);

    addRegistration(registrationForm)
      .then(() => {
        notification.success({ duration: 7, message: "SUCCESS", description: lang.savedRegistrationToastContent });
        hideModalPage();
      })
      .catch((err) => {
        let errorMessage = "Unspecified error";
        if (err && err.response && err.response.data) errorMessage = err.response.data.message;

        notification.error({
          duration: 7,
          message: "FAILED",
          description: `${lang.errorSavingRegistrationToastContent}. Fejlkode: ${errorMessage}`,
        });

        setSubmittingForm(false);
      });
  }

  return (
    <Page className={componentStyles()}>
      <ScrollView style={{ paddingTop: common.topBarHeight + "px" }}>
        <div className="content-wrapper">
          <p>Absence Code</p>
          <DropDown
            name="absenceCode"
            onChange={handleFormChange}
            options={absenceCodes.map((d) => ({ label: `${d.id} - ${d.name}`, value: d.id }))}
            placeholder="Choose absence code"
            value={formData.absenceCode}
          />

          <div className="seperator"></div>

          <p>{lang.startDate}</p>
          <DatePicker
            name="startDate"
            defaultDate={formData.startDate}
            onDateChange={(date) => handleFormChange({ target: { value: date } }, "startDate")}
          />

          {!formData.fullDay && (
            <div className="time-container">
              <div className="time-input-wrapper">
                <p>Start Time Hours</p>
                <TextInput
                  min={1}
                  max={24}
                  name="startTimeHours"
                  onChange={handleFormChange}
                  type="number"
                  value={formData.startTimeHours}
                />
              </div>
              <div className="time-input-wrapper">
                <p>Start Time Minutes</p>
                <TextInput
                  min={0}
                  max={59}
                  name="startTimeMinutes"
                  onChange={handleFormChange}
                  type="number"
                  value={formData.startTimeMinutes}
                />
              </div>
            </div>
          )}

          <p>{lang.endDate}</p>
          <DatePicker
            name="endDate"
            defaultDate={formData.endDate}
            onDateChange={(date) => handleFormChange({ target: { value: date } }, "endDate")}
          />

          {!formData.fullDay && (
            <div className="time-container">
              <div className="time-input-wrapper">
                <p>End Time Hours</p>
                <TextInput
                  min={1}
                  max={24}
                  name="endTimeHours"
                  onChange={handleFormChange}
                  type="number"
                  value={formData.endTimeHours}
                />
              </div>
              <div className="time-input-wrapper">
                <p>End Time Minutes</p>
                <TextInput
                  min={0}
                  max={59}
                  name="endTimeMinutes"
                  onChange={handleFormChange}
                  type="number"
                  value={formData.endTimeMinutes}
                />
              </div>
            </div>
          )}

          <p>Foreman</p>
          <DropDown
            // loading={}
            name="foreman"
            onChange={handleFormChange}
            options={foremen.map((d) => ({ disabled: d.disabled, label: d.name, value: d.id }))}
            placeholder="Choose a foreman"
            value={formData.foreman}
          />

          <br />
          <div className="fullDayContainer">
            <CheckboxGroup
              onSelect={(e) => handleFormChange({ target: { value: e.length !== 0 ? true : false } }, "fullDay")}
              options={[{ id: "fullDay", title: "Full Day" }]}
              selected={formData.fullDay ? ["fullDay"] : []}
            />
          </div>

          <div className="seperator"></div>

          <p>{lang.note}</p>
          <TextareaInput name="note" value={formData.note} placeholder={lang.enterNote} onChange={handleFormChange} />

          <Button active={submittingForm} style={{ marginTop: "2rem" }} onClick={handleSave}>
            {lang.save}
          </Button>
        </div>
      </ScrollView>
    </Page>
  );
}

const boxPadding = 0.75;

const componentStyles = () => css`
  background-color: white;
  padding-top: ${common.topBarHeight};

  .content-wrapper {
    max-width: 800px;
    margin: 0 auto;
  }

  .scroll-view {
    padding: ${boxPadding}rem;
  }

  .time-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .time-input-wrapper {
    width: 49%;
  }

  p {
    padding: ${boxPadding}rem 0 0 0;
  }

  h2 {
    padding: ${boxPadding}rem 0 0.5rem 0;
  }

  .fullDayContainer {
    margin-top: 20px;
  }

  .seperator {
    display: block;
    width: calc(100% + ${boxPadding * 2}rem);
    margin: 1rem -${boxPadding}rem 0 -${boxPadding}rem;
    height: 1px;
    background-color: ${colors.lightGrey};
  }
`;

const mapDispatchToProps = (dispatch) => ({
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(AbsenceRegistrationDK);
