import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { format, parse } from "date-fns";
import params from "jquery-param";

// css
import { css } from "emotion";
import colors from "../../style/colors";
import req from "../../utilities/request-utility";

import { PlusIcon, CheckboxMarkedIcon, TimerSandEmptyIcon } from "mdi-react";

// actions
import { showContextMenu, showModalPage, showDialog, hideDialog } from "../../actions/uiActions";

// components
import Notification from "../ui/Notification";

function WorkTimeListItem(props) {
  const { registration, lang, title, selectedUser } = props;
  const [expanded, setExpanded] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  // If user interacts with registration set is as dismissed
  useEffect(() => {
    if (expanded === true) {
      setTimeout(() => setIsDismissed(true), 400);
      const date = format(parse(registration.registrations[0].date, "yyyy-MM-dd", 0), "yyyyMMdd");
      req().put(`semcotime/corrections/v3/dismiss/${date}?${params({ externalId: selectedUser })}`);
    }
    // eslint-disable-next-line
  }, [expanded]);

  return (
    <div className={`${componentStyles(props)} ${expanded ? "expanded" : "collapsed"}`}>
      {/* Header */}
      <div className="item-header" onClick={() => setExpanded(!expanded)}>
        <div className="left">{title}</div>

        <div className="right">
          <div style={{ marginRight: "0.25rem" }} className="workTime">
            {<Notification notifications={registration.notifications} hide={isDismissed} />}
          </div>
          <div style={{ marginRight: "0.25rem" }} className="workTime">
            {registration.totalHours}
          </div>

          <PlusIcon style={{ marginRight: "-0.05rem" }} className={expanded ? "rotate" : "rotate-neutral"} />
        </div>
      </div>

      {/* Details */}
      <div className={`item-details`}>
        <div className={`${expanded ? "show" : "hide"}`}>
          {registration.registrations
            .sort((a, b) => (a.reportCode > b.reportCode ? 1 : -1))
            .map((registration, index) => (
              <div key={index} style={{ borderTop: `1px solid ${colors.midGrey}` }}>
                <div className="details-header">
                  <div className="left">
                    {registration.confirmed === "Yes" && <CheckboxMarkedIcon style={{ color: colors.green }} />}
                    {registration.confirmed === "No" && <TimerSandEmptyIcon style={{ color: colors.grey }} />}
                    <p>{registration.reportCodeDescription}</p>
                  </div>
                </div>

                <div className="details">
                  <p>
                    {lang.activity}: {registration.activity}
                  </p>
                  <p>
                    {lang.reportCode}: {registration.reportCode}
                  </p>
                </div>

                <div className="details" style={{ marginBottom: "1rem" }}>
                  <p>
                    {lang.note}: {registration.note}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

const componentStyles = (props) => css`
  background-color: ${colors.white};
  border-top: 1px ${colors.midGrey} solid;
  border-bottom: 1px ${colors.midGrey} solid;
  margin-bottom: -1px;

  &.collapsed {
    margin: 0;
    border-left: 3px solid transparent;
    transition: margin 240ms ease;
    margin-bottom: -1px;

    .hide {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 240ms ease, opacity 240ms ease, overflow 240ms ease;
    }

    .rotate-neutral {
      transform: rotate(0deg);
      transition: transform 240ms ease;
    }
  }

  &.expanded {
    margin: 0.65rem 0;
    border-left: 3px solid ${props.primaryColor};
    transition: margin 240ms ease, border-left 240ms ease;

    &:first-of-type {
      margin-top: 0px;
    }

    .item-header {
      color: ${props.primaryColor};

      .rotate {
        transform: rotate(-45deg);
        transition: transform 240ms ease;
      }
    }

    .item-details {
      .show {
        max-height: 1500px;
        overflow: auto;
        opacity: 1;
        transition: max-height 240ms ease, opacity 240ms ease;
      }
    }
  }

  .item-header {
    display: flex;
    padding: 0.75rem;
    margin-bottom: -1px;
    color: ${colors.darkGrey};
    font-weight: bold;

    &:active {
      background-color: ${colors.ultraLightGrey};
      transition: background-color 80ms ease;
    }

    .right {
      display: flex;
      margin-left: auto;
      font-weight: bold;
    }

    .left {
      display: flex;
    }
  }

  .item-details {
    .details-header {
      display: flex;
      padding: 0.75rem 0.75rem 0 0.75rem;

      .left {
        display: flex;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 0.5rem;
          flex-shrink: 0;
        }
      }

      .right {
        display: flex;
        margin-left: auto;
        font-weight: bold;

        p.hours {
          color: ${colors.darkGrey};
          margin-right: 0.35rem;
        }
      }
    }

    .details {
      padding: 0.5rem 0.75rem 0rem 0.75rem;
      color: ${colors.darkGrey};

      span {
        font-weight: bold;
      }

      p {
        padding-right: 1rem;
        display: inline-block;
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  lang: state.language.language,
  primaryColor: state.appConfig.primaryColor,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = (dispatch) => ({
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  hideDialog: bindActionCreators(hideDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkTimeListItem);
