import React, { memo, useCallback } from "react";
import { css } from "emotion";
import { ContentCopyIcon, DeleteIcon } from "mdi-react";

import colors from "../../../style/colors";
import getTimerOptions from "../../../utilities/get-timer-options";

import DropDown from "../../ui/DropDown";
import TextInput from "../../ui/TextInput";

const ForemanSubRecordForm = ({ deleteSubRecord, duplicateRecord, enabled, form, handleChange, subRecords, lang }) => {
  const renderSubRecords = useCallback(() => {
    if (subRecords.length === 0) return null;

    const { date, endTimeHours, endTimeMinutes, hours, startTimeHours, startTimeMinutes } = form;

    let totalRemainingHours = parseFloat(hours);

    const timerOptions = getTimerOptions({
      startTimeHours,
      startTimeMinutes,
      endTimeHours,
      endTimeMinutes,
      date,
    });

    return subRecords.map((d) => {
      const options = timerOptions.filter((d) => d.value <= totalRemainingHours);

      totalRemainingHours -= parseFloat(d.hours);

      return (
        <div key={d.id} className={componentStyles()}>
          <div className="wrapper">
            {/* Customer details */}
            <div className="input-wrapper">
              <label htmlFor="">{lang.customerDetail}</label>
              <TextInput
                onChange={(e) => handleChange(e, d.id)}
                className="input"
                value={d[`customerDetail`]}
                name={`subRecords.customerDetail`}
              />
            </div>
            {/* Customer note */}
            <div className="input-wrapper">
              <label htmlFor="">{lang.customerNote}</label>
              <TextInput
                onChange={(e) => handleChange(e, d.id)}
                className="input"
                value={d[`customerNote`]}
                name={`subRecords.customerNote`}
              />
            </div>
            {/* Note */}
            <div className="input-wrapper">
              <label htmlFor="">{lang.note}</label>
              <TextInput
                onChange={(e) => handleChange(e, d.id)}
                className="input"
                value={d[`note`]}
                name={`subRecords.note`}
              />
            </div>
            {/* Hours */}
            <div className="input-wrapper">
              <label htmlFor="">{lang.hours}</label>
              <DropDown
                name="subRecords.hours"
                onChange={(e) => handleChange(e, d.id)}
                options={options.map((d) => ({
                  label: d.name,
                  value: d.value,
                }))}
                value={d[`hours`]}
              />
            </div>
          </div>
          {enabled && (
            <div className="footer">
              {d.hours !== "" && (
                <button className="duplicate" onClick={() => duplicateRecord(d)}>
                  <ContentCopyIcon size="1rem" />
                  <span>{lang.duplicate}</span>
                </button>
              )}
              <button className="delete" onClick={() => deleteSubRecord(d.id)}>
                <DeleteIcon size="1rem" />
                <span>{lang.delete}</span>
              </button>
            </div>
          )}
        </div>
      );
    });

    // eslint-disable-next-line
  }, [subRecords]);

  return renderSubRecords();
};

const componentStyles = () => css`
  background-color: ${colors.white};
  border-radius: 3px;
  border: 1px ${colors.midGrey} solid;
  margin-top: 0.65rem;

  .wrapper {
    padding: 0.65rem;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &:last-of-type  {
      margin-bottom: 0;
    }

    & > label {
      width: 30%;
      text-align: right;
      padding-right: 0.65rem;
      font-size: 0.9rem;
      color: ${colors.darkGrey};
    }

    .input {
      width: 100%;
    }

    .time-input {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  .duplicate {
    align-items: center;
    background-color: transparent;
    border: none;
    color: #103447;
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    padding: 0.3rem;
    width: 5.5rem;
  }

  .delete {
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${colors.red};
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    padding: 0.3rem;
    width: 4.2rem;
  }

  .footer {
    background-color: colors.ultraLightGrey;
    border-top: 1px ${colors.midGrey} solid;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
  }
`;

export default memo(ForemanSubRecordForm);
