import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format, parse, subDays } from "date-fns";
import { notification } from "antd";
import params from "jquery-param";

// css
import { css } from "emotion";
import colors from "../../style/colors";
import { ArrowBackIcon, AlertDecagramIcon, CloudDownloadIcon, ThumbDownOutlineIcon } from "mdi-react";

// utilities
import pageNavigator from "../../utilities/page-navigator";
import getPageFromId from "../../utilities/get-page-from-id";
import getBackButtonUrl from "../../utilities/get-back-button-url";

// actions
import { showModalPage, hideModalPage } from "../../actions/uiActions";

// ui-components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import StatusBox from "../ui/StatusBox";

// components
import AbsenceRegistration from "./AbsenceRegistration";
import AbsenceListItem from "./AbsenceListItem";

// dummy-data
import ActionWrapper from "../ui/ActionWrapper";
import common from "../../style/common";
import req from "../../utilities/request-utility";
import Button from "../ui/Button";
import { addDays } from "date-fns/esm";

class AbsenceOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {},
      // forms
      formData: [],
      validRegistrationForm: false,
      filterData: [],
      dropDownLists: {},
      fetchToDate: format(new Date(), "yyyyMMdd"),
      // registrations
      loading: false,
      error: false,
      isFirstFetch: true,
      absenceData: [],
      filteredRegistrations: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { pages, match } = this.props;

    this.setState({
      page: getPageFromId(pages, match.params.pageId),
      backButtonUrl: getBackButtonUrl(getPageFromId(pages, match.params.pageId), pages),
    });

    this.getRegistrations();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getRegistrations = async () => {
    try {
      this.setState({ loading: true, isFirstFetch: false });

      const { selectedUser } = this.props;

      let toDate = this.state.fetchToDate;
      const fromDate = format(subDays(parse(toDate, "yyyyMMdd", 0), 7), "yyyyMMdd");

      // If it is first fetch, fetch a year into the future as well as a week into the past
      // This is done to get future registrations as well
      if (this.state.absenceData.length === 0) toDate = format(addDays(parse(toDate, "yyyyMMdd", 0), 365), "yyyyMMdd");

      const { data } = await req()(`semcotime/absence/v3/?${params({ externalId: selectedUser, fromDate, toDate })}`);

      this._isMounted === true && this.setState({ absenceData: [...this.state.absenceData, ...data] });
      this.setState({ loading: false });

      // Update fromDate
      this.setState({ fetchToDate: format(subDays(parse(fromDate, "yyyyMMdd", 0), 0), "yyyyMMdd") });
    } catch (err) {
      this.setState({ error: true, loading: false });
    }
  };

  addRegistration = async (registration) => {
    const { selectedUser } = this.props;

    return new Promise((resolve, reject) => {
      req()
        .post(`semcotime/absence/v3/?${params({ externalId: selectedUser })}`, registration)
        .then(() => {
          resolve();
          setTimeout(() => {
            this.setState({ absenceData: [] });
            this.getRegistrations();
          }, 4000);
        })
        .catch(reject);
    });
  };

  updateRegistration = async (registration) => {
    const { selectedUser } = this.props;

    return new Promise((resolve, reject) => {
      req()
        .put(`semcotime/absence/v3/?${params({ externalId: selectedUser })}`, registration)
        .then(() => {
          resolve();
          setTimeout(() => {
            this.setState({ absenceData: [] });
            this.getRegistrations();
          }, 4000);
        })
        .catch(reject);
    });
  };

  deleteRegistration = async (registration) => {
    try {
      const { selectedUser } = this.props;

      await req().delete(
        `semcotime/absence/v3/${registration.transactionId}?${params({
          date: registration.accountDate,
          externalId: selectedUser,
        })}`
      );

      const updatedAbsenceData = this.state.absenceData.filter(
        (absence) => absence.transactionId !== registration.transactionId
      );

      this.setState({ absenceData: updatedAbsenceData });
    } catch (err) {
      let errorMessage = "Unspecified error";
      if (err && err.response && err.response.data) errorMessage = err.response.data.error;
      notification.error({
        duration: 7,
        message: "FAILED",
        description: `Could not delete absence. Error: ${errorMessage}`,
      });
    }
  };

  getShowingDisplay = () => {
    const { fetchToDate } = this.state;
    const { lang, selectedUserName } = this.props;

    if (selectedUserName)
      return `${lang.displayingRegistrationsFor} ${selectedUserName} ${lang.from} ${format(
        parse(fetchToDate, "yyyyMMdd", 0),
        "yyyy-MM-dd"
      )} ${lang.untilToday}`;

    return `${lang.diplayingRegistrationsFrom} ${format(parse(fetchToDate, "yyyyMMdd", 0), "yyyy-MM-dd")} ${
      lang.untilToday
    }`;
  };

  render() {
    const { page, backButtonUrl, absenceData, loading, error } = this.state;
    const { showModalPage, lang } = this.props;

    return (
      <Page className={componentStyles()}>
        <TopBar
          title={page.title}
          actionLeft={<ArrowBackIcon onClick={() => pageNavigator(backButtonUrl, "backward")} />}
          actionRight={
            <ActionWrapper
              style={{ lineHeight: `${common.topBarHeight}px`, padding: "0 0.75rem" }}
              onClick={() => {
                showModalPage({
                  content: () => <AbsenceRegistration title={lang.registerAbsence} addRegistration={this.addRegistration} />,
                });
              }}
            >
              {lang.register}
            </ActionWrapper>
          }
        />
        <ScrollView style={{ padding: "1rem 0" }}>
          {/* Error */}
          {error && !loading && (
            <StatusBox
              style={{ marginTop: "2rem" }}
              icon={<AlertDecagramIcon />}
              title={lang.errorLoadingRegistrationToastTitle}
              content={lang.errorLoadingRegistrationToastContent}
            />
          )}

          {/* Load absence registrations   */}
          {!error &&
            absenceData.map((absence) => (
              <AbsenceListItem
                key={`${absence.wageCode}${absence.wageCodeName}${absence.startTime}${absence.endTime}`}
                registration={absence}
                updateRegistration={this.updateRegistration}
                deleteRegistration={this.deleteRegistration}
              />
            ))}

          <>
            <Button
              active={loading}
              onClick={this.getRegistrations}
              buttonType="secondary"
              fullWidth={false}
              style={{ margin: "2rem auto 1rem auto" }}
            >
              <CloudDownloadIcon style={{ width: "1.2rem", height: "1.2rem", margin: "0 0.2rem -0.2rem 0" }} />{" "}
              {lang.loadAnotherWeek}
            </Button>
            <p style={{ color: colors.darkGrey, textAlign: "center" }}>{this.getShowingDisplay()}</p>
          </>
        </ScrollView>
      </Page>
    );
  }
}

const componentStyles = () => css`
  .filter {
    padding: 0.75rem;
  }

  .apply-filters-modal {
    padding: 0, 75rem;
    background-color: ${colors.white};
  }

  .filters-button {
    background-color: ${colors.midGrey};
    color: ${colors.black};
    display: block;
    width: calc(100% - 1.5rem);
    border: 0px transparent none;
    border-radius: 3px;
    font-size: 0.95rem;
    padding: 0.75rem 1.25rem;
    margin: 0.75rem;
    text-align: left;
    position: relative;

    .icon-right {
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const mapStateToProps = (state) => ({
  pages: state.pages.pages,
  lang: state.language.language,
  selectedUser: state.users.selectedUser,
  selectedUserName:
    state.users.lists.length !== 0 ? state.users.lists.find((d) => d.externalId === state.users.selectedUser).name : null,
});

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceOverview);
