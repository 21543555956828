// Libraries
import React, { Component } from "react";
import rollbar from "rollbar";
import { version } from "../../../package.json";
import getAppName from "../../utilities/get-app-name";

var rollbarConfig = {
  accessToken: "68c2f57aa740403580aac83affcabc49",
  captureUncaught: true,
  enabled: process.env.NODE_ENV === "production" ? true : false,
  autoInstrument: {
    network: true,
    log: true,
    dom: true,
    navigation: true,
    connectivity: true
  },
  payload: {
    environment: process.env.NODE_ENV,
    appVersion: version
  }
};

const Rollbar = new rollbar(rollbarConfig);

class ErrorCatcher extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    Rollbar.critical(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            width: "90%",
            maxWidth: "800px",
            margin: "50px auto",
            textAlign: "center",
            fontFamily: "'Open Sans', sans-serif"
          }}
        >
          <h1 style={{ marginBottom: "1rem", fontSize: "1.25rem" }}>Oh no! Something went wrong with the app</h1>
          <p style={{ marginBottom: "2rem", fontSize: "0.85rem" }}>
            The error has been logged and we will do our best to fix it
          </p>
          <a
            style={{
              padding: "0.5rem 1rem",
              border: "1px #ccc solid",
              borderRadius: "3px",
              color: "#3b71c7",
              fontSize: "0.85rem",
              fontWeight: 700
            }}
            href={window.location.origin + "/" + getAppName()}
          >
            Restart app
          </a>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorCatcher;
