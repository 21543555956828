import React from 'react';
import { css } from 'emotion';
import colors from '../../style/colors';

const Page = props => (
	<div
		data-test-id={props['data-test-id'] || 'page'}
		className={`page ${componentStyle(props)} ${props.className || ''}`}
		ref={props.hostRef}
		style={props.style}>
		{props.children}
	</div>
);

const componentStyle = props => css`
	background-color: ${props.backgroundColor || colors.lightGrey};
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow: hidden;
	position: fixed;
	will-change: auto;
`;

export default Page;
